import { Typography, Box, Container, Grid } from "@mui/material";
import partnersTogether from "../../Assets/Images/FinTech_Partners_Together.png";
const TextImageBanner = () => {
  return (
    <Box bgcolor="#FBFBFC" py={4} mb={2}>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} md={7}>
            <Box>
              <Typography variant="h3" color="primary.darker" gutterBottom>
                Where strength and innovation meet
              </Typography>
              <Typography color="#555" gutterBottom>
                The fast-changing payments landscape has everyone scrambling to
                find scalable ways to manage payment operations. That’s why
                we’re building a network of trusted partners whose solutions
                integrate with U.S. Bank APIs to support your goals. By
                combining the strength of U.S. Bank with the expertise of
                leading technology partners, we can help you find faster,
                simpler ways to improve your cash management operations.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs sm={6} md="auto">
            <Box display="flex" width="100%">
            <iframe style={{marginTop:'10px'}}  allowFullScreen="" allowTransparency = "true"
            allow=" fullscreen; picture-in-picture; camera; microphone; display-capture; clipboard-write; encrypted-media" src="https://players.brightcove.net/4924632599001/default_default/index.html?videoId=6337641755112&autoplay=false">
            </iframe>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default TextImageBanner;
