import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Grid, Button, Container, Avatar } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Link } from "react-router-dom";
import { logout } from "redux/actions/auth/authAction";
import { resetStatusList, resetHomeFilter, fetchPartners } from "redux/reducers/partners/partnersSlice";
import { cleanStorage } from "redux/reducers/auth/authSlice";
import { resetOnboarding } from "redux/reducers/onboarding/onboardingSlice";
import { resetPartnerListing }  from "redux/reducers/admin/partnerListingsSlice"
import Cookies from "universal-cookie";

import Logo from "../../Assets/Images/logo.svg";
import theme from "theme";

const Header = () => {
  const location = useLocation();
  const cookies = new Cookies(window.document.cookie);
  const [isUserLogin, setIsUserLogin] = useState(false);
  const { isLoggedIn, userData } = useSelector((state) => state.auth);
  const [auth, setAuth] = useState(true);
  const [user, setUser] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = cookies.get("@clientAccessToken");

  const handleCloseNavMenu = () => {
    setAnchorEl(null);
  };


  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const clearHandler = () => {
    const params = {
      applicationIds: [],
      businessSizeIds: [],
      erpSupportedIds: [],
      industryIds: [],
      rowCount: 6,
      pageNo: 1,
    };
    dispatch(fetchPartners(params));
  };

  useEffect(() => {
    if(!token) {
      dispatch(cleanStorage());
    }
    if (userData && Object.keys(userData).length > 0) {
      setUser(userData.sub.fullname);
    }

    // if (
    //   isLoggedIn &&
    //   userData?.sub?.userTypeId
    // ) {
    //   userData.sub.userTypeId === 3 ? navigate("/admin/partner-listings") 
    //   : navigate('/');
    // }
  }, [userData]);

  const handleSignOut = async () => {
    dispatch(logout()).then((res) => {
      dispatch(resetStatusList())
      dispatch(resetHomeFilter())
      dispatch(resetOnboarding());
      dispatch(resetPartnerListing())
      clearHandler();
      location.pathname.includes('/admin/') ? navigate("/admin/login") : navigate("/");
    });
    setAnchorEl(null);
  };

  const strAvater = (name) => {
    const nameSplit = name.split(" ");
    return nameSplit[0].charAt(0).toUpperCase() + (nameSplit.length > 1 ? nameSplit[nameSplit.length -1].charAt(0).toUpperCase() : '');
  }

  const stringAvatar = (name, bgcolor, color) => {
    return {
      sx: {
        bgcolor: bgcolor || "#fff",
        color: color || theme.palette.primary.dark,
        fontSize: 16,
        cursor: "pointer",
      },
      children: `${strAvater(name)}`,
    };
  };
  const setUrl = () => {
    return userData && userData?.sub?.userTypeId == 3 ? "/admin/partner-listings" : (!userData && location.pathname.includes('/admin/') ? "/admin/login" : '/');
  }

  return (
    <Box bgcolor="primary.darker" py={1.5}>
      <Container maxWidth="lg">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid item container spacing={2}>
              <Grid item>
                <Link to={setUrl()} className="alink-none">
                  <Box display="flex" alignItems="center">
                    <Box pr={1.5} display="flex">
                      <img
                        src={Logo}
                        alt="U.S. Bank Logo"
                        width="100"
                        height="auto"
                      />
                    </Box>
                    <Box
                      color="#fff"
                      sx={{
                        pl: 1.5,
                        borderLeft: "1px solid #fff",
                        fontWeight: 100,
                        fontSize: { xs: 14, sm: 18, md: 20 },
                      }}
                    >
                      Connected Partnership Network
                    </Box>
                  </Box>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          { userData &&
          <Grid item>
            {isLoggedIn && (
              <Avatar {...stringAvatar(user)} onClick={handleMenu} />
            )}
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleCloseNavMenu}
            >
              <Box display="flex" justifyContent="center">
                <Avatar
                  {...stringAvatar(user, theme.palette.primary.dark, "#fff")}
                />
              </Box>
              <MenuItem>{user}</MenuItem>
              { userData && userData?.sub?.userTypeId === 3 &&
              <MenuItem>
              <Link to='/admin/reset-password' className="alink-grey">
                   Reset password
              </Link>
              </MenuItem>
              }
              <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
            </Menu>
          </Grid> }
        </Grid>
      </Container>
    </Box>
  );
};
export default Header;
