import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Alert,
  Link
} from "@mui/material";
import { useForm } from 'react-hook-form'; 
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { emailRegExp } from "utils";
import TextFields from "Components/Common/TextFields";
import Loader from "Components/Common/Loader";
import { forgotAdminPassword } from 'redux/actions/auth/authAction';
import AlertDialog from "Components/Common/AlertDialog";
import SelectFields from "Components/Common/SelectFields";
import axios from "axios";
import config from "config";

// create schema validation
const validationSchema = Yup.object({
  email: Yup
    .string()
    .required("Email is required")
    .matches(emailRegExp, "Email is not valid"),
  questionId: Yup.string().required("Security Question is required"),
  answer: Yup.string().required("Security Answer is required").min(1),
});

const formOptions = { 
  defaultValues: {
    email: "",
    questionId:"",
    answer: ""
  },
  resolver: yupResolver(validationSchema) 
};

const AdminForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const { handleSubmit, formState, control} = useForm(formOptions);
  const { errors } = formState;
  const [apiError, setApiError] = useState();
  const [loading, setLoading] = useState();
  const [isPopUpOpen, setIsPopUpOpen] = useState();
  const [secQues, setSecQues] = useState();
  const [firstTimeLogin, setFirstTimeLogin] = useState();

  useEffect(() => {
    securityQues();
  }, []);

  const securityQues = async () => {
    try {
      await axios
        .get(`${config.apiBase}/profile/getAllSecurityQuestions`)
        .then((res) => {
          setSecQues(res.data?.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const submitForm = (data) => {
    setLoading(true)
    setFirstTimeLogin(false);
    dispatch(forgotAdminPassword(data)).then((res) => {
      setLoading(false);
      if (!res.payload.error || res.payload.message == "Valid details") {
        setApiError('');
        setFirstTimeLogin(false);
        navigate("/admin/change-password/"+res.payload.data?.id)
        setIsPopUpOpen(true);
      } else {
        if(res.payload.message.includes("first time")) {
          setFirstTimeLogin(true);
        }
        setApiError(res.payload.message);
      }
    })
  }
  return loading ? (
    <Loader />
  ) : (
    <Container maxWidth="lg">
      <Box noValidate component="form" onSubmit={handleSubmit(submitForm)}>
      <Grid container spacing={2} alignItems="center" py={3}>
        <Grid item xs={12}  md={6}>
        <Grid container spacing={2}>
            <Grid item xs={10}>
              <Typography variant="h3" component="h1" color="primary.darker">
                Security Check
              </Typography>
            </Grid>
            <Grid item xs={10}>
              { apiError && !firstTimeLogin ? (
                  <Grid item xs={12}>
                    <Alert variant="filled" severity="error">{apiError}</Alert>
                  </Grid>  
                ) : apiError && firstTimeLogin && (
                  <Grid item xs={12}>
                    <Alert variant="filled" severity="error">{apiError}
                    {" "}
                      <Link
                      aria-label="login"
                      color="#ffffff"
                      variant="body1"
                      onClick={() => {
                        navigate("/admin/login");
                      }}
                        component="button"
                      >
                        Login
                      </Link>
                    </Alert>
                  </Grid> 
                )}
            </Grid>
            <Grid item xs={12} sm={8} md={10}>
              {/* <TextField label="Username" variant="standard" fullWidth /> */}
              <TextFields errors={errors} control={control} label="Email" name="email" />
            </Grid>

            <Grid item xs={12} sm={8} md={10}>
                  <SelectFields
                    errors={errors}
                    control={control}
                    name="questionId"
                    label="Security Question"
                    list={secQues}
                    keyName="question"
                  />
                </Grid>

                <Grid item xs={12} sm={8} md={10}>
                  <TextFields
                    label="Security Question answer"
                    name="answer"
                    errors={errors}
                    control={control}
                  />
                </Grid>

            <Grid item xs={12}>
              <Grid item md={8} xs={8} lg={10} my={1}>
                <Button variant="contained" color="primary" type="submit" fullWidth>
                  Confirm details
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </Box>
    </Container>
  );
};
export default AdminForgotPassword;
