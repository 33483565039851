import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { nanoid } from "nanoid";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "none",
    color: "#2C2C2C",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F7F7FA",
  },
}));

const BasicTable = ({
  data,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  count,
  onSortHandler,
  sortOrder,
  sortColumn,
  orderBy,
}) => {
  let getListTable = data?.data?.lstAdmGetAllClientResponses;

  return (
    <TableContainer>
      <Table sx={{ minWidth: 700, border: "none" }}>
        <TableHead sx={{ border: "1px solid #CDCDD3" }}>
          <TableRow>
            <StyledTableCell>
              <TableSortLabel
                active={orderBy === "client_name"}
                direction={orderBy === "client_name" ? sortOrder : "asc"}
                onClick={() => onSortHandler("client_name", sortOrder)}
              >
                Company name
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell>
              Partner
            </StyledTableCell>
            <StyledTableCell>
               Name
              <Typography color="textSecondary" variant="body2">
               {" "}
               Company role 
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <TableSortLabel
                active={orderBy === "created_date"}
                direction={orderBy === "created_date" ? sortOrder : "asc"}
                onClick={() => onSortHandler("created_date", sortOrder)}
              >
                Date received{" "}
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell>
              <TableSortLabel
                active={orderBy === "last_updated"}
                direction={orderBy === "last_updated" ? sortOrder : "asc"}
                onClick={() => onSortHandler("last_updated", sortOrder)}
              >
                Last updated on{" "}
              </TableSortLabel>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ border: "1px solid #CDCDD3" }}>
          {getListTable &&
            getListTable?.map((row) => (
              <StyledTableRow key={nanoid(4)}>
                <StyledTableCell>
                  <Link
                    to={`lead-details/${row.clientname}`}
                    style={{ textDecoration: "none", color: "#0A41C5" }}
                  >
                    {row.clientname}{" "}
                  </Link>
                </StyledTableCell>
                <StyledTableCell>
                  {row.partnerName || ""}
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    variant="body2"
                    fontWeight="500"
                    color="textPrimary"
                  >
                   {row.name || ""}{" "}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    
                    {row.companyrole || ""}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>{row.daterecieved}</StyledTableCell>
                <StyledTableCell>{row.lastupdated}</StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              sx={{ borderBottom: "none" }}
              rowsPerPageOptions={[10, 20, 30, 50, 100]}
              colSpan={3}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;