import axios from "axios";
import config from "config";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAccessToken } from "helper/user";


// pass leadId
export const getNotes = createAsyncThunk(
  "notes/getNotes",
  async (id, { rejectWithValue }) => {
    try {
      const accessToken = await getAccessToken();
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          Pragma: "no-cache",
        },
      };
      const response = await axios.get(
        `${config.apiBase}/notes/getNotes?leadsId=${id}`,
        headers
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateNotes = createAsyncThunk(
  "notes/updateNote",
  async (payload, { rejectWithValue, dispatch }) => {
   
    try {
      const accessToken = await getAccessToken();
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          Pragma: "no-cache",
        },
      };
      const response = await axios.post(
        `${config.apiBase}/notes/updateNote`,
        {
          id: payload.noteId, // NotesID
          note: payload.updateValue, //string
        },
        headers
      );

       dispatch(getNotes(payload.viewId))
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// only same person can update who create that notes.
export const createNotes = createAsyncThunk(
  "notes/createNote",
  async (payload, { rejectWithValue }) => {
    try {
      const accessToken = await getAccessToken();
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          Pragma: "no-cache",
        },
      };
      const response = await axios.post(
        `${config.apiBase}/notes/createNote`,
        {
          parentId: null,
          leadsId: +payload.viewId, 
          note: payload.data.notesInput,
        },
        headers
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

