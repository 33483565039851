export const addErrorIntoField = (errors) => errors ? { error: true } : { error: false };
// export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const phoneRegExp = /^[\d\-!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]+$/
export const passwordRegExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
// export const webRegExp = /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
export const webRegExp = /^((http|https):\/\/)?(www\.)?[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?([\w\?&=%\/@-]+)*([^\/\w\?&=]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/
export const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const alphabetExp = /^[a-zA-Z\s!@#$%^&*()_+{}\[\]:;<>,.?/~`|-]+$/;;
export const alphabetsString =/^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_{|}~]+/
// export const alphaNumericExp = /^[a-zA-Z0-9\s]*$/;
export const alphaNumericExp = /^[\s\S]*$/