import axios from "axios";
import config from "config";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAccessToken } from "helper/user";

export const getAllClients = createAsyncThunk(
  "client/getClients",
  async ({ pageNumber, rowCount, sortColumn }, { rejectWithValue }) => {
    try {
      const accessToken = await getAccessToken();
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          Pragma: "no-cache",
        },
      };
      const response = await axios.post(
        `${config.apiBase}/admin/getAllClients`,
        {
          pageNumber,
          rowCount,
          sortColumn,
        },
        headers
      );

      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getClientById = createAsyncThunk(
  "client/getClientById",
  async (id, { rejectWithValue }) => {
    try {
      const accessToken = await getAccessToken();
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          Pragma: "no-cache",
        },
      };
      const response = await axios.get(
        `${config.apiBase}/admin/getClientDetails?leadId=${id}`,
        headers
      );

      return await response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
