import * as React from "react";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemIcon from "@mui/material/ListItemIcon";
import ErrorMessage from "Components/ErrorMessage";
import { addErrorIntoField } from "utils";
import { useState, useEffect } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectCheckmarks({
  list = [],
  label,
  onSelect,
  multiple = true,
  name,
  error,
  control,
  selectedList = [],
  disable
}) {
  const [itemList, setitemList] = React.useState(selectedList);
  const [isAllSelected, setIsAllSelected] = React.useState();

  useEffect(() => {
    setIsAllSelected((list && list.length > 0) && (itemList && itemList.length === list.length));
  },[])
  
  useEffect(() => { 
    setIsAllSelected(selectedList.length === list.length);
    if(selectedList && list && list.length && selectedList[0]){
      const indexArr = [];
      selectedList.forEach((item) => {
        indexArr.push(list.find((lst) => lst.name.toLowerCase() === item.toLowerCase())?.id);
      });
      onSelect && onSelect(indexArr);
    }
    setitemList(selectedList);
  },[selectedList]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === "All") {
      const selectedItem = itemList.length === list.length ? [] : list.map(item=> item.name);
      setitemList(selectedItem);
      setIsAllSelected(selectedItem.length === list.length);

      const indexArr = [];
      selectedItem.forEach((item) => {
        indexArr.push(list.find((lst) => lst.name === item)?.id);
      });

      onSelect && onSelect(indexArr);
    }
    else {
      const selectedItemArray =
      typeof value === "string" ? value.split(",") : value;

      setitemList(selectedItemArray);
      setIsAllSelected(selectedItemArray.length === list.length);
      const indexArr = [];
      selectedItemArray.forEach((item) => {
        indexArr.push(list.find((lst) => lst.name === item)?.id);
      });
      onSelect && onSelect(indexArr);
    }
  };
  
  return (
    <>
      <FormControl fullWidth variant="standard">
        <InputLabel id={`label-${label.substring(1, 4)}`}>{label}</InputLabel>
        <Select
          {...addErrorIntoField(error[name])}
          disabled={disable}
          control={control}
          name={name}
          labelId={`label-${label.substring(1, 4)}`}
          id="multiple-checkbox"
          multiple={multiple}
          value={itemList}
          onChange={handleChange}
          input={<Input label={label} />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        > 

        { multiple && 
          <MenuItem
          value="All"
          >
            <ListItemIcon>
            <Checkbox
              checked={isAllSelected}
              indeterminate={
                itemList.length > 0 && itemList.length < list.length
              }
            />
            </ListItemIcon>
            <ListItemText
              primary="Select All"
            />

          </MenuItem>
        }
          {list &&
            list?.map((item) => (
              <MenuItem key={item.id} value={item.name}>
                <Checkbox
                  size="small"
                  checked={itemList && itemList.length && itemList.indexOf(item.name) > -1}
                />
                <ListItemText primary={item.name} />
              </MenuItem> 
            ))}
        </Select>
        {error[name] ? <ErrorMessage message={error[name].message} /> : null}
      </FormControl>
    </>
  );
}