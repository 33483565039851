import { Grid, Typography, Button, Container, Box } from "@mui/material";
import theme from "theme";
import {  useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const ThankYou = () => {
  let  navigate = useNavigate()
  const { userData } = useSelector((state) => state.auth);
  const homeLink = () => {
    navigate('/');
  }
  return (
    <Container maxWidth="lg">
      <Box py={4} display="flex" alignItems="center" minHeight="68vh">
        <Grid container item xs={5} mx="auto" justifyContent="center" flexDirection="column">
          <Grid item xs={12}>
            <Typography variant="h1" fontWeight="400" color="textSecondary" align="center" gutterBottom>
              Thank You!
            </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <Button sx={{ fontSize:theme.typography.h6, color:theme.palette.primary.dark, fontWeight:300, textDecoration: "none", '&:hover':{ textDecoration:"none"}, paddingBottom:2}}>
              We have received your request and will connect with you soon.
            </Button>
          </Grid>
          <Grid item xs={12} align="center">
            <hr/>
            <Button onClick={homeLink} variant="outlined" sx={{mt:2}}> Home</Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ThankYou;
