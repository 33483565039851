import {createSlice} from '@reduxjs/toolkit';
import { stepOneSubmit, getPartner, stepTwoSubmit } from '../../actions/onboarding/onboardingAction';

const initialState = {
    loading:false,
    stepOne:null,
    stepTwo:null,
    error:null,
    isSubmitted: false,
    partnerData: null,
    partnerLoader: false
  }
  

const onboardingSlice = createSlice({
        name:'onboarding',
        initialState,
        reducers: {
            resetOnboarding(state, action) {
                state.partnerData = action.payload;
            },
        },
        extraReducers: {
            [stepOneSubmit.pending]:(state) => {
                state.loading = true
            },
            [stepOneSubmit.fulfilled]:(state, action) => {
                state.loading = false;
                state.stepOne = action.payload;
                state.isSubmitted = true;
            },
            [stepOneSubmit.rejected]:(state, action) => {
                state.loading = false;
                state.stepOne = null;
                state.error = action.payload
                state.isSubmitted = false;
            },
            [getPartner.pending]:(state) => {
                state.loading = true;
                state.partnerLoader = true;
            },
            [getPartner.fulfilled]:(state, action) => {
                state.loading = false;
                state.partnerData = action.payload.data;
                state.partnerLoader = false;
            },
            [getPartner.rejected]:(state, action) => {
                state.loading = false;
                state.partnerData = null;
                state.error = action.payload;
                state.partnerLoader = false;;
            },
            [stepTwoSubmit.pending]:(state) => {
                state.loading = true
            },
            [stepTwoSubmit.fulfilled]:(state, action) => {
                state.loading = false;
                state.stepTwo = action.payload;
                state.isSubmitted = true;
            },
            [stepTwoSubmit.rejected]:(state, action) => {
                state.loading = false;
                state.stepTwo = null;
                state.error = action.payload
                state.isSubmitted = false;
            },
        }
})
export const { resetOnboarding } = onboardingSlice.actions;
export default onboardingSlice.reducer