import { useState, useEffect } from "react";
import {Grid,Typography,Button, TextField, Box, CircularProgress,} from "@mui/material";
import TextFields from "Components/Common/TextFields";
import moment from "moment";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import nodeProfile from "Assets/Images/notes-profile-img.png";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getNotes, updateNotes, createNotes } from "redux/actions/notes";
import { useParams } from "react-router-dom";
import { setNote } from "redux/reducers/notes";
import jwtDecode from 'jwt-decode';
// create schema validation
const validationSchema = Yup.object({
  notesInput: Yup.string().required("Your notes is required"),
});

const formOptions = {
  defaultValues: {
    notesInput: "",
  },
  resolver: yupResolver(validationSchema),
};

const ClientListingNotes = () => {
  const { data } = useSelector((state) => state.client);

  const allClients =  data?.data?.lstAdmGetAllClientResponses;
  const params = useParams();
  // const allClients = JSON.parse(sessionStorage.getItem('allClients'));
  const clientId = JSON.parse(sessionStorage.getItem('clientId'));
  const viewId = allClients?.filter((partner)=> partner.clientname == params.viewId)[0]?.id || clientId;
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(null);
  const notesList = useSelector((state) => state.notes);
  const { loading } = useSelector((state) => state.client);
  const { handleSubmit, formState, control, reset } = useForm(formOptions);
  const { errors } = formState;
  const localData = localStorage.getItem("userToken");
  const tokenDecode = jwtDecode(localData)
  const tokenUseId = tokenDecode.sub.id

  const editHandler = (noteId) => {
    setIsEdit(noteId);
  };

  const updateHander = (e, noteId, index) => {
    if (noteId) {
      const inputValue = e.target.value;
      dispatch(setNote({ index, val: inputValue }));
    }
  };

  const saveHandler = (noteId, index) => {
    const payload = {
      noteId,
      viewId,
      updateValue: notesList.data.data[index].note,
    };
    dispatch(updateNotes(payload));
    //dispatch(dataUpdated());
    dispatch(getNotes(viewId));
    setIsEdit(false);
  };

  useEffect(() => {
    // dispatch(getNotes(viewId));
  }, [viewId]);

  const submitForm = (data) => {
    const payload = { data, viewId };
    dispatch(createNotes(payload)).then((res) => {
      if (!res.payload.error) {
        dispatch(getNotes(viewId));
      }
    });
    reset();
  };

  return (
    <Grid container>
      <Grid item xs={10.73}>
        <Grid container direction='row'>
          <Grid item xs={12}>
            <Grid item xs={12} md={12} px={2}>
              {loading ? (
                <Grid item xs={12} p={2} align='center'>
                  {" "}
                  <CircularProgress />{" "}
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Grid spacing={0} container direction='row'>
                    <Grid item xs={12}>
                      <Typography variant='h4' component='h2' sx={{ mt: 3 }}>
                        Offline communication log
                      </Typography>
                    </Grid>
                    {loading ? (
                      <Grid item xs={12} p={2} align='center'>
                        {" "}
                        <CircularProgress />{" "}
                      </Grid>
                    ) : (
                      <Box
                        noValidate
                        component='form'
                        onSubmit={handleSubmit(submitForm)}
                        width={1}>
                        <Grid item xs={12} md={10}>
                          <TextFields
                            multiline
                            rows={5}
                            fullWidth
                            errors={errors}
                            control={control}
                            label='Add your notes here'
                            name='notesInput'
                            placeholder='Add your notes here'
                            variant='outlined'
                          />
                        </Grid>
                        <Grid item xs={12} md={10} align='right'>
                          <Button variant='outlined' type='submit'>
                            Save
                          </Button>
                        </Grid>
                        {notesList && notesList.loading ? (
                          <Grid item xs={12} md={10} p={2} align='center'>
                            {" "}
                            <CircularProgress />{" "}
                          </Grid>
                        ) : (
                          notesList &&
                          notesList?.data?.data
                            ?.map((item, index) => (
                              <Grid
                                item
                                xs={12}
                                md={10}
                                mt={1}
                                key={`notes_A${index}`}>
                                <Grid
                                  item
                                  container
                                  xs={12}
                                  justifyContent='space-between'
                                  alignItems='end'>
                                  <Grid item xs={4}>
                                    <Box
                                      display='flex'
                                      gap={2}
                                      alignItems='end'>
                                      <img
                                        src={nodeProfile}
                                        width='34'
                                        alt='notes profile'
                                      />
                                      <Typography color='textSecondary'>
                                        {item?.createdByName}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={4} align='right'>
                                    <Typography color='textSecondary'>
                                      Updated{" "}
                                      {moment(item?.lastUpdatedDate).fromNow()}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    {isEdit === index ? (
                                      <TextField
                                        multiline
                                        rows={5}
                                        fullWidth
                                        value={item.note}
                                        onChange={(e) =>
                                          updateHander(e, item.id, index)
                                        }
                                        sx={{ my: 2 }}
                                      />
                                    ) : (
                                      <TextField
                                      multiline
                                      fullWidth
                                      color="primary"
                                      value={item.note}
                                      sx={{my: 2, background:'#f5f5f5'}}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                    )}
                                  </Grid>
                                  { item.createdById === tokenUseId ? (
                                     <Grid item xs={12} align='right'>
                                     {isEdit === index ? (
                                       <Box display='flex' gap={2} justifyContent='flex-end'>
                                         <Button variant='outlined' onClick={() => setIsEdit(false)}>
                                           Cancel
                                         </Button>
                                         <Button
                                           variant='contained'
                                           onClick={() => saveHandler(item.id, index)}
                                         >
                                           Save
                                         </Button>
                                       </Box>
                                     ) : (
                                       <Button
                                         variant='text'
                                         startIcon={<BorderColorIcon />}
                                         onClick={() => editHandler(index, item.id)}
                                       >
                                         Edit
                                       </Button>
                                     )}
                                   </Grid>
                                  ): null}
                                </Grid>
                              </Grid>
                            ))
                            
                        )}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ClientListingNotes;
