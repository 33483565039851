import { FormControl, MenuItem, TextField } from "@mui/material"
import { Controller } from "react-hook-form"
import { addErrorIntoField } from "utils";
import ErrorMessage from "Components/ErrorMessage";
import { nanoid } from "nanoid";
import { useState } from 'react';

const SelectFields = ({ label, name, control, errors, list, keyName}) => {

  return (
    <FormControl fullWidth sx={{ mb: '1rem' }}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField {...addErrorIntoField(errors[name])} {...field}  
          select label={label} variant="standard">
            {/* <MenuItem value=''><em>None</em></MenuItem> */}
            {list && list.map(item => (
              <MenuItem key={nanoid(3)} value={item.id}>{item.name}</MenuItem>
            ))}
          </TextField>
        )}
      />
      {errors[name] ? <ErrorMessage message={errors[name].message} /> : null}

    </FormControl>
  )
}

export default SelectFields