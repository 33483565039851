import { useEffect } from "react";
import { Box, Grid, Typography, Button, FormGroup, Checkbox, FormControlLabel, CircularProgress,} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchPartners } from "redux/reducers/partners/partnersSlice";
import { getHomeFilter } from "redux/actions/homeFilter";
import { nanoid } from "nanoid";

const Filter = ({ onChangeHandler, filterParams, clearHandler, pageScroll, boxDesign, setIsDrawerOpen}) => {
  const { data, loading } = useSelector((state) => state.homeFilter);
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHomeFilter());
  }, []);
  useEffect(()=>{
    if(filterParams){
      dispatch(fetchPartners(filterParams));
    }else{
      clearHandler()
    }
  },[filterParams])
  const filterHandler = (e, id, name) => {
    onChangeHandler(e, id, name)
    // if(filterParams){
    //   dispatch(fetchPartners(filterParams));
    // }
    // dispatch(fetchPartners());
    boxDesign && setIsDrawerOpen(false)
    pageScroll()
  };



  return (
    <Grid item container xs={12} sm={6} md={4} sx={{display: {xs:boxDesign ? 'block': 'none', sm:'flex'}}}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          mb: 3,
          border: boxDesign ? 'none' : "1px solid #CDCDD3",
          borderRadius: 1.5,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          p={2}
          alignItems="center"
        >
          <Typography mb={0} variant="h5" component="p" color="primary.darker">
            Filters
          </Typography>
          <Box>
            <Button variant="text" size="small" onClick={clearHandler}>
              Clear
            </Button>
            {/* <Button variant="contained" size="small" onClick={filterHandler}>
              {" "}
              Apply Filters
            </Button> */}
          </Box>
        </Box>
        <Box borderBottom="1px solid #ddd"></Box>
        <Box p={2} overflow="auto" maxHeight={{xs:'100%', sm:"1400px", md:"700px", lg:"690px"}}
        >
        {
          loading ?  <Box display="flex" justifyContent="center" alignItems="center" width={1}>
          <CircularProgress />
        </Box> : (
          <> 
              <Typography mt={0} color="primary.darker">
            Application categories
          </Typography>
          {data && data?.data?.applications?.map((item) => (
            <FormGroup key={nanoid(3)}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      filterParams && filterParams.applicationIds.includes(item.id)
                    }
                    name="applicationIds"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                    onChange={(e) => {filterHandler(e, item.id, item.name)}}
                  />
                }
                label={
                  <Typography color="#555" sx={{ fontSize: 14 }}>
                    {item.name}
                  </Typography>
                }
              />
            </FormGroup>
          ))}

          <Typography mt={2} color="primary.darker">
            Business size
          </Typography>
          {data && data?.data?.businessSize?.map((item) => (
            <FormGroup key={nanoid(10)}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      filterParams && filterParams.businessSizeIds.includes(item.id)
                    }
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                    name="businessSizeIds"
                    onChange={(e) => onChangeHandler(e, item.id, item.name)}
                  />
                }
                label={
                  <Typography color="#555" sx={{ fontSize: 14 }}>
                    {item.name}
                  </Typography>
                }
              />
            </FormGroup>
          ))}

          <Typography mt={2} color="primary.darker">
            Industry type
          </Typography>
          {data && data?.data?.industries?.map((item) => (
            <FormGroup key={nanoid(10)}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      filterParams && filterParams.industryIds.includes(item.id)
                    }
                    name="industryIds"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                    onChange={(e) => onChangeHandler(e, item.id, item.name)}
                  />
                }
                label={
                  <Typography color="#555" sx={{ fontSize: 14 }}>
                    {item.name}
                  </Typography>
                }
              />
            </FormGroup>
          ))}

          <Typography mt={2} color="primary.darker">
            ERPs supported
          </Typography>
          {data && data?.data?.erpSupported?.map((item) => (
            <FormGroup key={nanoid(10)}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                    checked={
                      filterParams && filterParams.erpSupportedIds.includes(item.id)
                    }
                    name="erpSupportedIds"
                    onChange={(e) => onChangeHandler(e, item.id, item.name)}
                  />
                }
                label={
                  <Typography color="#555" sx={{ fontSize: 14 }}>
                    {item.name}
                  </Typography>
                }
              />
            </FormGroup>
          ))}
          </>
        )
        }
        
        
        </Box>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button variant="text" size="small" onClick={clearHandler}>
            Clear
          </Button>
          {/* <Button variant="contained" size="small" onClick={filterHandler}>
            {" "}
            Apply Filters
          </Button> */}
        </Box>
      </Box>
    </Grid>
  );
};
export default Filter;
