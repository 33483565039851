import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography, Tabs, Tab, Box, Button, Container } from "@mui/material";
import { useParams } from "react-router-dom";
import DashboardLeftMenu from "Components/DashboardLeftMenu";
import { TabPanel, a11yProps } from "Components/Common/CustomTabs/index";
import PartnerContactInformation from "./ContactInformation";
import PartnerNotes from "./Notes";
import OnboardingInformation from "./OnboardingInformation";
import CustomBreadcrumbs from "Components/Common/CustomBreadcrumbs";
import AlertDialog from "Components/Common/AlertDialog";
import Popover from "@mui/material/Popover";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { changeStatus, getPartnerDetails, getStagesAndCount } from "redux/actions/admin/partnerListings";
import { getNotes } from 'redux/actions/notes';
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from '@mui/material/LinearProgress';

const PartnerDetails = () => {
  const params = useParams();
  const partnerListingFetch = useSelector(
    (state) => state.partnerListing?.data?.lstAdmGetAllParnerResponses
  );
  const dispatch = useDispatch();
  const contInformation = useSelector((state) => state.partnerListing?.partnerDetailsList?.contactInformation);
  const stages = useSelector((state) => state.partnerListing?.partnerDetailsList?.stages);
  const viewIdS = JSON.parse(sessionStorage.getItem('leadId'))
  const viewId = partnerListingFetch?.filter((partner)=> partner.partnername == params.viewId)[0]?.id ||  viewIdS
  const [tabValue, setTabValue] = useState(0);
  const [getStatusName, setGetStatusName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    dispatch(getNotes(viewId));
    dispatch(getPartnerDetails(viewId))
  }, [viewId]);
  useEffect(() => {
    getPartnerListingFilter();
    dispatch(getPartnerDetails(viewId))
  }, []);

  const getPartnerListingFilter = () => {
    dispatch(getStagesAndCount());
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  }

  const [nextItemAction, setNextItemAction] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e, nextItem) => {
    setAnchorEl(e.currentTarget);
    setNextItemAction(nextItem.nextStages);
    setGetStatusName(nextItem.currentStage?.stageName);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [getStatusId, setGetStatusId] = useState();
  const [statusAlert, setStatusAlert] = useState(false);

  const openStatusAlert = (event, nextItemAction) => {
    setStatusAlert(true);
    setGetStatusId(nextItemAction);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popOverId = open ? `simple-popover` : null;

  const closeStatusAlert = () => {
    setStatusAlert(false);
  };

  const payload = JSON.parse(sessionStorage.getItem('payload'))
  
  const handleStatusUpdate = () => {
    const statusPayload = {
      getCurrentId: viewId,
      getStatusId: { nextstageid: getStatusId.id },
      payload: payload || ''
    };
    setIsLoading(true)
    dispatch(changeStatus(statusPayload)).then((res) => {
    dispatch(getPartnerDetails(viewId))
      setIsLoading(false)
    });
    
    setStatusAlert(false);
    setAnchorEl(null);
  };


  return (
    <Container maxWidth="lg">
    <Grid container id="contactInformation">
      <Grid
        item
        xs={3}
        borderRight="2px solid #F2F5FA"
        minHeight="90vh"
        py={2}
      >
        <DashboardLeftMenu pageId={viewId} />
      </Grid>
      <Grid item xs={9}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              px={2}
            >
              <CustomBreadcrumbs 
              isHomeTrue={true}
                previousLink="Partner  listings"
                prevlink='/admin/partner-listings' currentLink={tabValue === 0 ? 'Contact Information' : tabValue === 1 ? 'Onboarding information' : tabValue === 2 ? 'Notes' : ''} />

            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              p={2}
            >
              <Typography variant="h3" component="h1" color="primary.darker">
                Partner details
              </Typography>
            </Grid>
            <Grid item xs={12} md={9} px={2} mb={3}>
                  <Box display="flex"
                    width="100%"
                    p={2}
                    bgcolor="#F7F7FA"
                  > 
                    { isLoading ? 
                        ( <Grid direction="row" justify="flex-end" container xs={12} alignItems="center" color="#0C2074"> 
                          <Box sx={{ width: '100%' }} >
                            <LinearProgress color="inherit"/>
                          </Box>
                          </Grid>
                        ) : (
                    <Box
                      width="90%"
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                    <Typography color="#0C2074">
                      {" "}
                      Status: {stages?.currentStage?.stageName}
                      {" "}
                    </Typography>
                    </Box> )}
                    <Box    width="10%"
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                            aria-describedby={popOverId}
                            id={`${viewId}`}
                            onClick={(e) => handleClick(e, stages)}
                            color="#828282"
                            className="pointer"
                          >
                            <MoreVertIcon />
                          </Box>
                  </Box>
              </Grid>
          </Grid>
          <Grid item xs={12} px={2}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="reports"
            >
              <Tab label="Contact information" {...a11yProps(0)} />
              <Tab
                label="Onboarding information"
                {...a11yProps(1)}
                disabled={
                  contInformation &&
                    contInformation.currentstageid <= 6
                    ? true
                    : false
                }
              />
              <Tab label="Notes" {...a11yProps(2)} />
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={tabValue} index={0}>
              <PartnerContactInformation />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <OnboardingInformation />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <PartnerNotes />
            </TabPanel>
          </Grid>

        </Grid>
      </Grid>
      <Popover
        id={popOverId}
        anchorEl={anchorEl}
        onClose={handleClose}
        //open={open}
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {nextItemAction &&
          nextItemAction?.map((status, index) => (
            <div
              className="link"
              key={index}
              id={status.id}
              onClick={(event) => openStatusAlert(event, status)}
            >
              {status.stageName}
            </div>
          ))}
      </Popover>

      {statusAlert && (
        <AlertDialog
          message={`Do you want to change the status from ${getStatusName} to ${getStatusId?.stageName}?`}
          open={statusAlert}
          onClose={closeStatusAlert}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleStatusUpdate}
          >
            Confirm
          </Button>
        </AlertDialog>
      )}
    </Grid>
    </Container>
  );
};

export default PartnerDetails;
