import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Alert,
  Link
} from "@mui/material";
import { useForm } from 'react-hook-form'; 
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { phoneRegExp, emailRegExp } from "utils";
import TextFields from "Components/Common/TextFields";
import Loader from "Components/Common/Loader";
import { resetAdminPassword, logout } from 'redux/actions/auth/authAction';
import SelectFields from "Components/Common/SelectFields";
import axios from "axios";
import config from "config";
import AlertDialog from "Components/Common/AlertDialog";
import { resetOnboarding } from "redux/reducers/onboarding/onboardingSlice";
import { resetPartnerListing }  from "redux/reducers/admin/partnerListingsSlice"

const oldPassMessage = "Old password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special";
const newPassMessage = "New password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special";
// create schema validation
const validationSchema = Yup.object({
  oldPassword: Yup.string().min(
    8,
    oldPassMessage
  )
  .required("Old password is required")
  .matches(/[0-9]/, oldPassMessage)
  .matches(/[a-z]/, oldPassMessage)
  .matches(/[A-Z]/, oldPassMessage)
  .matches(/[^\w]/, oldPassMessage),
  newPassword: Yup.string().min(
    8,
    newPassMessage
  )
  .required("New password is required")
  .matches(/[0-9]/, newPassMessage)
  .matches(/[a-z]/, newPassMessage)
  .matches(/[A-Z]/, newPassMessage)
  .matches(/[^\w]/, newPassMessage),
  confirmPassword: Yup
    .string()
    .oneOf([Yup.ref("newPassword"), null], "Password must match"),
  questionId: Yup.string().required("Security Question is required"),
  answer: Yup.string().required("Security Answer is required").min(1),
});

const formOptions = { 
  defaultValues: {
    oldPassword:"",
    password: "",
    confirmPassword:"",
    questionId:"",
    answer: ""
  },
  resolver: yupResolver(validationSchema) 
};

const AdminResetPassword = () => {
  const { userData } = useSelector((state) => state.auth)
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const { register, handleSubmit, formState, control, reset} = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const [secQues, setSecQues] = useState();
  const [apiError, setApiError] = useState();
  const [loading, setLoading] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  useEffect(() => {
    securityQues();
    if(userData && userData?.sub?.userTypeId !== 3){
      navigate('/')
    }
  }, []);

  const securityQues = async () => {
    try {
      await axios
        .get(`${config.apiBase}/profile/getAllSecurityQuestions`)
        .then((res) => {
          setSecQues(res.data?.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const submitForm = (data) => {
    setLoading(true);
    const payload = {
      password: data.oldPassword,
      newPassword: data.newPassword,
      securityQuestionId: data.questionId,
      securityAnswer: data.answer
    }
    dispatch(resetAdminPassword(payload)).then((res) => {
      setLoading(false);
      if (!res.payload.error) {
        setApiError('');
        dispatch(logout());
        dispatch(resetOnboarding());
        dispatch(resetPartnerListing());
        setIsPopUpOpen(true);
      } else {
        setApiError(res.payload.message);
      }
    })
  }
  return loading ? (
    <Loader />
  ) : (
    <Container maxWidth="lg">
      <Box noValidate component="form" onSubmit={handleSubmit(submitForm)}>
      <Grid container spacing={2} alignItems="center" py={3}>
        <Grid item md={6}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Typography variant="h3" component="h1" color="primary.darker">
                Change Password
              </Typography>
            </Grid>
            <Grid item xs={10}>
              { apiError && (
                  <Grid item xs={12}>
                    <Alert variant="filled" severity="error">{apiError}</Alert>
                  </Grid>  
                )}
            </Grid>
            <Grid item md={10}>
              <TextFields
                type="password"
                label="Old Password *"
                name="oldPassword"
                errors={errors}
                control={control}
              />
            </Grid>
            <Grid item md={10}>
              <TextFields
                type="password"
                label="New Password *"
                name="newPassword"
                errors={errors}
                control={control}
              />
            </Grid>
            <Grid item md={10}>
              <TextFields
                type="password"
                label="Confirm new password *"
                name="confirmPassword"
                errors={errors}
                control={control}
              />
            </Grid>

            <Grid item md={10}>
              <SelectFields
                errors={errors}
                control={control}
                name="questionId"
                label="Security Question *"
                list={secQues}
                keyName="question"
              />
            </Grid>

            <Grid item md={10}>
              <TextFields
                label="Security Question answer *"
                name="answer"
                errors={errors}
                control={control}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid item md={8} xs={8} lg={10} my={1}>
                <Button variant="contained" color="primary" type="submit" fullWidth>
                  Reset Password
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </Box>
      {isPopUpOpen && (
          <AlertDialog
            message="Your password has been successfully reset."
            open={isPopUpOpen}
            showCloseIcon={false}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/admin/login")}
            >
              Login
            </Button>
          </AlertDialog>
        )}
    </Container>
  );
};
export default AdminResetPassword;
