import { Box, Container, Grid, Typography } from "@mui/material";
import { useState } from 'react';
import { nanoid } from "nanoid";
import DOMPurify from "dompurify";
import ImagePopup from "../../Components/ImagePopup"
import './index.scss';

const ScreenshotSection = ({ data }) => {
  const [imgSrc, setImgSrc] = useState();
  const [status, setStatus] = useState(false);
  const storageFormData = JSON.parse(sessionStorage.getItem("formData"));

  const openImagePopup = (img) => {
    setImgSrc(img);
    setStatus(true);
  }
 
  return (
    data &&
    data?.map((item, i) => {
      return (
        <Box py={5} key={nanoid(4)}>
          <Box maxWidth="lg">
            <Grid
              justifyContent="space-between"
              alignItems="center"
              spacing={3}
              
            >
               <Grid item xs={12} md={12} style={{paddingLeft:'0px'}}>
                <Typography variant="h5" color="#2C2C2C" gutterBottom>
                  {item?.title}
                </Typography>
                {storageFormData && (
                  <Box color="#555"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(storageFormData?.description),
                    }}
                  ></Box>
                )}
                <Box color="#555"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(item?.description),
                    }}
                  ></Box>
              </Grid>
              <Grid item xs={12} md={12} sx={{pr:{lg:'70px'}}} style={{paddingLeft:'0px'}}>
                <img id={'img_'+i} onClick={()=>openImagePopup(item?.uri)} src={item?.uri} alt="screenshot" width="100%" height="auto" style={{cursor:'pointer'}} />
              </Grid>
             
            </Grid>
          </Box>
          {imgSrc && imgSrc === item?.uri && (
          <ImagePopup
            img={imgSrc}
            status={status}
            openStatus={(val)=>setStatus(val)}
            >
          </ImagePopup>
        )}
        </Box>
      );
    })
  );
};

export default ScreenshotSection;
