import { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Tabs,
  Tab,
  CircularProgress,
  Stack
} from "@mui/material";
import DOMPurify from "dompurify";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import CustomBreadcrumbs from "Components/Common/CustomBreadcrumbs";
import ScreenshotSection from "View/ScreenshotSection";
import { styled } from "@mui/material/styles";
import config from "config";
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import jwtDecode from "jwt-decode";
import { webRegExp } from "utils";

const NewTabs = styled(Tabs)({
  margin: 0,
});

const NewTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    padding: theme.spacing(0),
    marginRight: 24,
    maxWidth: "auto",
    minWidth: "auto",
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}



const Details = () => {
  const { userData, isLoggedIn } = useSelector((state) => state.auth)
  const [partnerData, setPartnerData] = useState([]);
  const [partnerVideoUrl, setPartnerVideoUrl] = useState('')
  const params = useParams();
  const encodedHash = encodeURIComponent(params.detailsId);
  const partnerListingFetch = useSelector(
    (state) => state.partnerListing?.data?.lstAdmGetAllParnerResponses
  );
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = useSelector((state) => state.partner);
  const partnerName = data.partners?.filter((partner)=> partner.companyName == params.detailsId)[0]?.companyName || sessionStorage.getItem('partnerName') || JSON.parse(sessionStorage.getItem('partnerData'))?.sub?.partnerName;
  if(partnerName != params?.detailsId){
    sessionStorage.removeItem('formData');
    navigate('/');
  }
  // const partnerLeadID = partnerListingFetch ? partnerListingFetch?.filter((partner)=> partner.partnername == params.detailsId)[0]?.id : data.partners?.filter((partner)=> partner.companyName == params.detailsId)[0]?.leadsId
  // const localData = localStorage.getItem("userToken");
  // const tokenDecode = jwtDecode(localData)
  // const partnerLeadIdLoc = tokenDecode?.sub?.leadId
  const partnerLeadIDSess = JSON.parse(sessionStorage.getItem('partnerData'))?.sub.leadId;
  const partnerLeadID = data.partners?.filter((partner)=> partner.companyName == params.detailsId)[0]?.leadsId || partnerListingFetch?.filter((partner)=> partner.partnername == params.detailsId)[0]?.id || partnerLeadIDSess
  
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const storageFormData = JSON.parse(sessionStorage.getItem('formData'));
  
  const serviceDescriptionLocal = storageFormData?.serviceDescription?.split("<ul")
  serviceDescriptionLocal?.splice(1,0,"<ul style=\"padding-left: 14px; list-style-type: '- '\"")
  const serviceDescriptionApi = partnerData?.overview?.serviceDescription?.split("<ul")
  serviceDescriptionApi?.splice(1,0,"<ul style=\"padding-left: 14px; list-style-type: '- '\"")
  const serviceDescription = serviceDescriptionLocal?.join('') || serviceDescriptionApi?.join('') 
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabActive = () => {
    setValue(1)
  }

  const adobeUtagForPartner = () => {
    const partName = params && params?.detailsId ? params.detailsId.replace(/[^a-zA-Z0-9]/g,' ').replace(/\s\s+/g, ' ') : null;
    const reportingData = {
      currentPage : "usb:partner directory:"+ partName.toLowerCase() +":contact us",
      siteSection : "form",
      subSiteSection : "partner directory",
      sitePlatform : "dotcom",
      language: "en-us",
      datasource : "pageView",
      transactionStatus : "form start",
      formName : "usbank partner:"+ partName.toLowerCase() +" contact us form"
    };
    if(window.publisherFW && partName){
      console.log(reportingData.currentPage +" ===>", reportingData);
      window.publisherFW.publishEvent("pageView", reportingData); //call this function after the data layer object
    }
  } 

  const adobeUtag = () => {
    const partName = params && params.detailsId ? params.detailsId.replace(/[^a-zA-Z0-9]/g,' ').replace(/\s\s+/g, ' ') : null;
    const reportingData = {
      currentPage : "usb:partner directory:"+ partName.toLowerCase(),
      siteSection : "information",
      subSiteSection : "partner directory",
      sitePlatform : "dotcom",
      language: "en-us",
      datasource : "pageView",
    };
    if(window.publisherFW && partName){
      console.log("Partner dtails ===>", reportingData);
      window.publisherFW.publishEvent("pageView", reportingData); //call this function after the data layer object
    }
    
  }

  useEffect(() => {
    adobeUtag();
    const match = location.pathname.match('/admin');
    if(!partnerLeadID) {
      match ? navigate('/admin/partner-listings') : navigate('/')
    }
    else {
      listingDetails();
    }
  }, []);

//   const getIframeSrc = (url) => {
//     if(url){
//     if (url?.includes("youtube.com")) {
//         // YouTube URL handling
//         const videoIdMatch = url.match(/v=([^&]+)/);
//         if (videoIdMatch && videoIdMatch[1]) {
//             return `https://www.youtube.com/embed/${videoIdMatch[1]}`;
//         }
//     } else if (url?.includes("vimeo.com")) {
//         // Vimeo URL handling
//         const videoIdMatch = url.match(/\/(\d+)/);
//         if (videoIdMatch && videoIdMatch[1]) {
//             return `https://player.vimeo.com/video/${videoIdMatch[1]}`;
//         }
//     } else if (url?.includes("dailymotion.com")) {
//         // Dailymotion URL handling
//         const videoIdMatch = url.match(/\/video\/(\w+)/);
//         if (videoIdMatch && videoIdMatch[1]) {
//             return `https://www.dailymotion.com/embed/video/${videoIdMatch[1]}`;
//         }
//     }
//   }

//     return ""; 
// }

const getIframeSrc = (url) => {
  try {
    if (url && new URL(url)) {
        if (url.includes("youtube.com")) {
            // YouTube URL handling
            const videoIdMatch = url.match(/(?:v=)([^\s&]+)/);
            if (videoIdMatch && videoIdMatch[1]) {
                return `https://www.youtube.com/embed/${videoIdMatch[1]}`;
            }
        } else if (url.includes("youtu.be")) {
            // Shortened YouTube URL handling
            const videoIdMatch = url.match(/(?:\.be\/)([^\s?]+)/);
            if (videoIdMatch && videoIdMatch[1]) {
                return `https://www.youtube.com/embed/${videoIdMatch[1]}`;
            }
        } else if (url.includes("vimeo.com")) {
            // Vimeo URL handling
            const videoIdMatch = url.match(/(?:\/)([0-9]+)/);
            if (videoIdMatch && videoIdMatch[1]) {
                return `https://player.vimeo.com/video/${videoIdMatch[1]}`;
            }
        } else if (url.includes("dailymotion.com")) {
            // Dailymotion URL handling
            const videoIdMatch = url.match(/(?:\/video\/)([^\s]+)/);
            if (videoIdMatch && videoIdMatch[1]) {
                return `https://www.dailymotion.com/embed/video/${videoIdMatch[1]}`;
            }
        } else if (url.includes("facebook.com")) {
            // Facebook URL handling
            const videoIdMatch = url.match(/(?:videos\/)([0-9]+)/);
            if (videoIdMatch && videoIdMatch[1]) {
                return `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}`;
            }
        } else if (url.includes("instagram.com")) {
            // Instagram URL handling
            const videoIdMatch = url.match(/(?:\/p\/)([^\s\/]+)/);
            if (videoIdMatch && videoIdMatch[1]) {
                return `https://www.instagram.com/p/${videoIdMatch[1]}/embed/`;
            }
        } else if (url.includes("twitter.com")) {
            // Twitter URL handling
            const videoIdMatch = url.match(/(?:\/status\/)([0-9]+)/);
            if (videoIdMatch && videoIdMatch[1]) {
                return `https://twitter.com/i/videos/${videoIdMatch[1]}?embed_source=clientlib&player_id=0&rpc_init=1`;
            }
        } else if (url.includes("tiktok.com")) {
            // TikTok URL handling
            const videoIdMatch = url.match(/(?:\/video\/)([0-9]+)/);
            if (videoIdMatch && videoIdMatch[1]) {
                return `https://www.tiktok.com/embed/v2/${videoIdMatch[1]}`;
            }
        } else if (url.includes("wistia.com")) {
            // Wistia URL handling
            const videoIdMatch = url.match(/(?:medias\/)([^\s]+)/);
            if (videoIdMatch && videoIdMatch[1]) {
                return `https://fast.wistia.net/embed/iframe/${videoIdMatch[1]}`;
            }
        } else if (url.includes("twitch.tv")) {
            // Twitch URL handling
            const videoIdMatch = url.match(/(?:\/videos\/)([^\s\/]+)/);
            if (videoIdMatch && videoIdMatch[1]) {
                return `https://player.twitch.tv/?autoplay=false&video=${videoIdMatch[1]}`;
            }
        } else if (url.includes("soundcloud.com")) {
            // SoundCloud URL handling
            const trackIdMatch = url.match(/(?:tracks\/)([0-9]+)/);
            if (trackIdMatch && trackIdMatch[1]) {
                return `https://w.soundcloud.com/player/?url=${encodeURIComponent(url)}&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`;
            }
        } else if (url.includes("vevo.com")) {
            // Vevo URL handling
            const videoIdMatch = url.match(/(?:videos\/)([^\s]+)/);
            if (videoIdMatch && videoIdMatch[1]) {
                return `https://embed.vevo.com?isrc=${videoIdMatch[1]}`;
            }
        } else if (url.includes("metacafe.com")) {
            // Metacafe URL handling
            const videoIdMatch = url.match(/(?:\/watch\/)([^\s\/]+)/);
            if (videoIdMatch && videoIdMatch[1]) {
                return `http://www.metacafe.com/embed/${videoIdMatch[1]}/`;
            }
        } else if (url.includes("vine.co")) {
            // Vine URL handling
            const videoIdMatch = url.match(/(?:\/v\/)([^\s\/]+)/);
            if (videoIdMatch && videoIdMatch[1]) {
                return `https://vine.co/v/${videoIdMatch[1]}/embed/simple`;
            }
        } else if (url.includes("veoh.com")) {
            // Veoh URL handling
            const videoIdMatch = url.match(/(?:\/watch\/)([^\s\/]+)/);
            if (videoIdMatch && videoIdMatch[1]) {
                return `https://www.veoh.com/swf/webplayer/WebPlayer.swf?version=AFrontend.5.7.0.1442&permalinkId=${videoIdMatch[1]}&player=videodetailsembedded&videoAutoPlay=0&id=anonymous`;
            }
        } else if (url.includes("flickr.com")) {
            // Flickr URL handling
            const videoIdMatch = url.match(/(?:\/videos\/)([^\s\/]+)/);
            if (videoIdMatch && videoIdMatch[1]) {
                return `https://www.flickr.com/photos/${videoIdMatch[1]}/player/`;
            }
        } else if (url.includes("brightcove.com")) {
            // Brightcove URL handling
            const videoIdMatch = url.match(/(?:\/videos\/)([^\s\/]+)/);
            if (videoIdMatch && videoIdMatch[1]) {
                return `https://players.brightcove.net/12345678901234567890/abcdefghijklmnopqrstuv/index.html?videoId=${videoIdMatch[1]}`;
            }
        } else if (url.includes("hulu.com")) {
            // Hulu URL handling
            const videoIdMatch = url.match(/(?:\/watch\/)([^\s\/]+)/);
            if (videoIdMatch && videoIdMatch[1]) {
                return `https://www.hulu.com/embed/${videoIdMatch[1]}`;
            }
        } else if (url.includes("break.com")) {
            const videoIdMatch = url.match(/(?:\/video\/)([^\s]+)/);
            if (videoIdMatch && videoIdMatch[1]) {
                return `https://www.break.com/embed/${videoIdMatch[1]}`;
            }
        } 
        return url;
    }
  }catch (error) {
    return "";
  }

  return "";
};


  const listingDetails = async () => {
    setIsError(false);
      setIsLoading(true);
    try {
      await axios
        .get(`${config.apiBase}/dashboard/partner/${partnerLeadID}`)
        .then((res) => {
          setPartnerData(res?.data?.data);
          const videoUrlLink = (storageFormData && partnerLeadID == storageFormData?.leadId) ? storageFormData?.videoUrl :  res.data?.data?.overview?.videoUrl
          setPartnerVideoUrl(getIframeSrc(videoUrlLink))
          window.gtag("event", res?.data?.data?.overview?.companyName, {
            event_category: "Partner Details",
            event_label: res?.data?.data?.overview?.companyName
          });
          window.analytics.track(res?.data?.data?.overview?.companyName);
          
        }, {
          headers: {
          "Content-type": "application/json"
        }}
        
        );
    } catch (error) {
      setIsError(true);
    }
    setIsLoading(false);
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener, noreferrer");
  };

  
  return (

    <>
     {isError && <Typography color="error">Something went wrong ...</Typography>}
     {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" width={1} height="80vh" > 
        <CircularProgress /> 
        </Box>
      ): (
      <>
      <Box bgcolor="#F7F7FA">
      <Container sx={{display: {xs:'none', sm:'block'}  }} maxWidth="lg">
        <CustomBreadcrumbs currentLink={partnerData?.overview?.companyName} />
      </Container>
      </Box>
      <Box className={styles.headerDiv}  py={2}>
        <Container maxWidth="lg">
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs={12} md={12} lg={8}>
              <Grid item xs={12} md={5} lg={10}>
              <Typography sx={{color:'#0c2074'}} variant="h4" gutterBottom>
                {partnerData?.overview?.companyName}
              </Typography>
              <Typography sx={{display: {xs: 'none', sm: 'none', lg: 'block'}}} variant="h5" gutterBottom>
                {partnerData?.overview?.productName}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                {partnerData?.overview?.shortDescription}
              </Typography>
              <Grid item container spacing={2} py={2}>
                <Grid item xs={12} sm={3} md={6} lg={3}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      adobeUtagForPartner();
                      navigate(`/connect-with-us/${params.detailsId}`)
                    }}
                  >
                    Contact us
                  </Button>
                </Grid>
                {partnerData?.overview?.website && (
                  <Grid item xs={12} sm={3.4} md={6} lg={3}>
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() =>
                        openInNewTab(
                          `https://${partnerData?.overview?.website.toString()}`
                        )
                      }
                    >
                      View website
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
            </Grid>
            <Grid item sx={{display: {xs: 'none', sm: 'none', md: 'none', lg: 'block'}}}  lg={3}>
              {partnerData ? 
                <img
                src={partnerData?.overview?.logoUri}
                alt={partnerData?.overview?.logoDescription}
                width="200"
                height="auto"
              /> :  null
              }
          </Grid>
          </Grid>
         
        </Container>
      </Box>
    
      <Box sx={{ width: "100%" }}>
          <Container >
          <Grid container spacing={5}>
            <Grid item xs={12} md={12} lg={8}>
            <Grid item xs={12} >
                <Typography
                  variant="h3"
                  fontWeight="500"
                  component="h1"
                  color="#2C2C2C"
                  sx={{ pt: 2 }}
                >
                  Overview
                </Typography>
                {
             storageFormData && partnerLeadID == storageFormData?.leadId && <div
             className="serviceDescriptionBox"
             dangerouslySetInnerHTML={{
               __html: DOMPurify.sanitize(
                serviceDescription
               ),
             }}
           ></div>
            }
           
           {
             !storageFormData && partnerLeadID == partnerData?.overview?.leadsId && <div color="#555" className="serviceDescriptionBox"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  serviceDescription
                ),
              }}
              // component="div"
            >
            </div>
            }
              <Grid sx={{mb:4, pr:{lg:'70px'}}} style={{paddingRight: "70px"}}>
                {partnerVideoUrl ? 
               <div style={{height:"400px", marginBottom:'10px'}} > {/* Set the parent container's height to 100vh (viewport height) */}
               <iframe
                   width='100%'
                   height='600px'
                   style={{ marginTop: '10px', marginLeft: '0px', marginBottom: '30px', height: '100%' }}
                   allowFullScreen=""
                   allowTransparency="true"
                   allow="fullscreen; picture-in-picture; camera; microphone; display-capture; clipboard-write; encrypted-media"
                   src={partnerVideoUrl}
               />
           </div>
            :  null}
              </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h3"
                  fontWeight="500"
                  component="h1"
                  color="#2C2C2C"
                >
                  Features & benefits
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {
              storageFormData && partnerLeadID == storageFormData?.leadId && <Box color="#555"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  storageFormData?.serviceKeyPoint
                ),
              }}
            ></Box>
              }
                {
              !storageFormData && partnerLeadID == partnerData?.overview?.leadsId && <Box color="#555"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(partnerData?.features),
                  }}
                ></Box>
                 }
                 {storageFormData && partnerLeadID == storageFormData?.leadId && 
            <ScreenshotSection data={JSON.parse(sessionStorage.getItem('formData'))?.screenshots} />
          }
          {!storageFormData && partnerLeadID == partnerData?.overview?.leadsId && 
            <ScreenshotSection data={partnerData?.overview?.screenshots} />
          }
          
              </Grid>
              <Grid item xs={12}>
                <Container maxWidth="lg" style={{paddingLeft:'0px', paddingTop: '0px', paddingRight:'2px'}}> 
                  <Typography variant="body1" fontWeight={600} align='left' gutterBottom style={{marginBottom:'10px', marginTop:"20px"}}> Disclosures </Typography>
                  
                  <Typography variant="body2" color="#555" align='left' gutterBottom>All trademarks, product names, logos, and brands are property of their respective owners and are used for identification purposes only. Use of these trademarks, names, logos, and brands or the listing of a company on this page does not imply endorsement of the company, or its products or services by U.S. Bank.  Customers should perform their own due diligence on the appropriateness of any company’s products and services for use by the customer.</Typography>
                  <br/>
                  <Typography variant="body2" color="#555" align='left' gutterBottom mb={3}>RTP® is a registered trademark of The Clearing House Payments Company L.L.C. Zelle and the Zelle related marks are wholly owned by Early Warning Services, LLC and are used herin under license.</Typography>

                </Container>
              </Grid>
            </Grid>
            <Grid item sx={{mb:{xs:'60px'}, pl:{xs: '40px'}, pl:{lg: '79px'}, pl:{sm: '79px'}}} xs={12}  lg={3}>
              <div className={styles.serviceDiv}>
              <Grid id="serivceLeft" item xs={12} sm={12} >
              <Typography
                  variant="h3"
                  fontWeight="500"
                  component="h1"
                  color="#2C2C2C"
                  sx={{ pt: 2 }}
                >
                  Service details
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight="500"
                  component="p"
                  color="#2C2C2C"
                >
                  APIs available
                </Typography>
                <ul>
                  {partnerData?.serviceDetails?.api?.map((ap, index) => (
                    <li key={index}>{ap?.name}</li>
                  ))}
                </ul>
                <Typography
                  variant="h6"
                  fontWeight="500"
                  component="p"
                  color="#2C2C2C"
                >
                  Industry type
                </Typography>
                <ul>
                  {partnerData?.serviceDetails?.industry?.map((item, index) => (
                    <li key={index}>{item?.name}</li>
                  ))}
                </ul>
              </Grid>
              <Grid className={styles.serivceRight} item xs={12} sm={12}  >
                <Typography
                  variant="h6"
                  fontWeight="500"
                  component="p"
                  color="#2C2C2C"
                >
                  Connectivity channel
                </Typography>
                <ul>
                  {partnerData?.serviceDetails?.connectivityChannel?.map(
                    (item, index) => (
                      <li key={index}>{item?.name}</li>
                    )
                  )}
                </ul>
                <Typography
                  variant="h6"
                  fontWeight="500"
                  component="p"
                  color="#2C2C2C"
                >
                  Application categories
                </Typography>
                <ul>
                  {partnerData?.serviceDetails?.application?.map(
                    (item, index) => (
                      <li key={index}>{item?.name}</li>
                    )
                  )}
                </ul>
                <Typography
                  variant="h6"
                  fontWeight="500"
                  component="p"
                  color="#2C2C2C"
                >
                  Business size
                </Typography>
                <ul>
                  {partnerData?.serviceDetails?.businessSize?.map(
                    (item, index) => (
                      <li key={index}>{item?.name}</li>
                    )
                  )}
                </ul>
                <Typography
                  variant="h6"
                  fontWeight="500"
                  component="p"
                  color="#2C2C2C"
                >
                  ERPs supported
                </Typography>
                <ul>
                  {partnerData?.serviceDetails?.erpSupported?.map(
                    (item, index) => (
                      <li key={index}>{item?.name}</li>
                    )
                  )}
                </ul>
              </Grid>
              </div>
            </Grid>
          </Grid>
          {userData && partnerLeadID == partnerData?.overview?.leadsId && ([6,11].includes(partnerData?.currentStageId) || userData?.sub?.userTypeId === 3) &&
          <Box m={3} display="flex" justifyContent="flex-end">
          <Stack direction="row" justifyContent="end">
            <Button
                      variant="contained"
                      fullWidth
                      onClick={() => navigate(userData?.sub?.userTypeId === 3 ? `/admin/step-one${encodedHash ? '/'+encodedHash : ''}` : `/step-one${encodedHash ? '/'+encodedHash : ''}`)}
                    >Edit</Button>
            </Stack>
          </Box>
      }
        </Container>
      </Box>
      <Box bgcolor='primary.darker' py={3}>
        <Container  maxWidth="lg"> 
          <Typography variant="body2" color='#fff' align='right' sx={{marginRight: {lg:'80px'}}}> © {new Date().getFullYear()} U.S. Bank</Typography>
        </Container>
      </Box>
     
      </>) 
      }
      
    </>
  );
};

export default Details;
