import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Link,
  Alert,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "redux/actions/auth/authAction";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { emailRegExp } from "utils";
import Loader from "Components/Common/Loader";
import TextFields from "Components/Common/TextFields";
import {
  getPartner,
} from "redux/actions/onboarding/onboardingAction";
import jwtDecode from "jwt-decode";


// create schema validation
const validationSchema = Yup.object({
  email: Yup.string()
    .required("Email is required")
    .matches(emailRegExp, "Email is not valid"),
  password: Yup.string()
    .min(
      8,
      "password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
    )
    .required("Password is required")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol"),
});

const formOptions = {
  defaultValues: {
    email: "",
    password: "",
  },
  resolver: yupResolver(validationSchema),
};

const Login = () => {
  const { userInfo, userData, error, isLoggedIn } = useSelector(
    (state) => state.auth
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { register, handleSubmit, formState, control, reset } =
    useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const [apiError, setApiError] = useState("");
  const [loader, setLoader] = useState(false);
  // redirect authenticated user to profile screen
  useEffect(() => {
    if (
      isLoggedIn &&
      userInfo &&
      Object.keys(userInfo).length > 0 &&
      userData?.sub?.userTypeId
    ) {
      sessionStorage.setItem("partnerData", JSON.stringify(userData))
      userData.sub.userTypeId === 3 ? navigate("/admin/partner-listings") 
      : userData?.sub?.currentStageId === 6 ? navigate(`/step-one/${userData?.sub?.partnerName}`) : navigate('/details/'+userData?.sub?.partnerName);
    }
  }, [userInfo]);

  useEffect(() => {
    adobeUtag();
  }, []);

  const adobeUtag = () => {
    const reportingData = {
      currentPage : "usb:partner directory:login",
      siteSection : "login",
      subSiteSection : "partner directory",
      sitePlatform : "dotcom",
      language: "en-us",
      datasource : "pageView",
    };
      
    if(window.publisherFW){
      console.log("Login ===>", reportingData);
      window.publisherFW.publishEvent("pageView", reportingData); //call this function after the data layer object
    }
  }

  const submitForm = (data) => {
    setApiError("");
    setLoader(true);
    dispatch(userLogin(data)).then((res) => {
      if (res.payload.error) {
        setApiError(res.payload.message);
      }
      else {
        const localData = localStorage.getItem("userToken");
        const tokenDecode = jwtDecode(localData)
        const partnerLeadIdLoc = tokenDecode?.sub?.leadId
        if(partnerLeadIdLoc) {
          dispatch(getPartner(partnerLeadIdLoc));
        }
      }
      
      setLoader(false);
    });
  };

  return loader ? (
    <Loader />
  ) : (
    <Container maxWidth="lg">
      <Box noValidate component="form" onSubmit={handleSubmit(submitForm)}>
        <Grid container spacing={0} alignItems="center" py={3}>
          <Grid item md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="h3" component="h1" color="primary.darker">
                Partner login
                </Typography>
              </Grid>
              {apiError && (
                <Grid item xs={12} md={8}>
                  <Alert variant="filled" severity="error">
                    {apiError}
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12} md={8}>
                <TextFields
                  errors={errors}
                  control={control}
                  label="Email"
                  name="email"
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <TextFields
                  errors={errors}
                  control={control}
                  label="Password"
                  name="password"
                  type="password"
                />
              </Grid>

              <Grid item xs={12} md={8} align="right">
                <Link aria-label="Forgot your password?" variant="body1" sx={'cursor: pointer'}
                onClick={() => {
                  navigate("/forgot-password");
                }}>
                  Forgot your password ?
                </Link>
              </Grid>
              <Grid item xs={12} md={8} my={3}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Login
                </Button>
              </Grid>

              <Grid item xs={12} md={8}>
                <Typography color="textSecondary">
                  Not a Partner yet ?{" "}
                  <Link
                    aria-label="Not a Partner yet ?  Sign up now !"
                    variant="body1"
                    onClick={() => {
                      navigate("/partner-with-us");
                    }}
                    component="button"
                  >
                    Partner with us
                  </Link>{" "}
                  now.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default Login;
