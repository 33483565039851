import { useState, useEffect } from "react";
import {
  Typography,
  List,
  IconButton,
  ListItemButton,
  Drawer,
  Box,
  Divider,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate, useLocation } from "react-router-dom";
import NavList from "./NavList";

const DashboardLeftMenu = ({ pageId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    tabsLinkActive();
  }, []);

  const [partnerTab, setPartnerTab] = useState(false);
  const [clientTab, setClientTab] = useState(false);
  const [manageFiterTab, setManageFiterTab] = useState(false);

  const pageChangeHandler = (id) => {
    if (id === 1) {
      navigate("/admin/partner-listings");
    } else if (id === 2) {
      navigate("/admin/leads");
    } else if (id === 3) {
      setIsDrawerOpen(true);
    } else {
      return false;
    }
  };

  const tabsLinkActive = () => {
    if (
      location.pathname === "/admin/partner-listings" ||
      location.pathname === `/admin/partner-listings/partner-details/${pageId}`
    ) {
      setPartnerTab(true);
      setClientTab(false);
    }
    if (
      location.pathname === "/admin/leads" ||
      location.pathname === `/admin/leads/lead-details/${pageId}`
    ) {
      setPartnerTab(false);
      setClientTab(true);
    }
    if (manageFiterTab) {
      setPartnerTab(false);
      setClientTab(false);
      setManageFiterTab(true);
    }
  };

  return (
    <>
      
        <Typography
          variant="body1"
          sx={{
            textTransform: "uppercase",
            fontSize: 14,
            marginBottom: 2,
            marginTop: "8px",
          }}
        >
          Fintech PARTNER Directory
        </Typography>
        <List component="nav" aria-label="nav menu" dense>
          <ListItemButton
            onClick={() => pageChangeHandler(1)}
            style={{
              borderLeft: partnerTab ? "3px solid #0A41C5" : "",
              color: partnerTab ? "#0A41C5" : "#000",
              marginBottom: 10,
              fontWeight: 500,
            }}
          >
            Partner
          </ListItemButton>

          <ListItemButton
            onClick={() => pageChangeHandler(2)}
            style={{
              borderLeft: clientTab ? "3px solid #0A41C5" : "",
              color: clientTab ? "#0A41C5" : "#000",
              marginBottom: 10,
              fontWeight: 500,
            }}
          >
            Leads
          </ListItemButton>
          <ListItemButton
            onClick={() => pageChangeHandler(3)}
            style={{
              borderLeft: manageFiterTab ? "3px solid #0A41C5" : "",
              color: manageFiterTab ? "#0A41C5" : "#000",
              marginBottom: 10,
              fontWeight: 500,
            }}
          >
            Manage filters
          </ListItemButton>
        </List>
      
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box width={355} role="presentation" pb={3}>
          <Typography
            fontSize={20}
            py={2}
            px={2}
            display="flex"
            justifyContent="space-between"
          >
            Manage Filters{" "}
            <IconButton title="Close" disableRipple onClick={() => setIsDrawerOpen(false)}>
              <CancelIcon
                color="primary"
                fontSize="small"
                sx={{ width: "16px" }}
              />
            </IconButton>
          </Typography>
          <Divider />

          <NavList />
        </Box>
      </Drawer>
    </>
  );
};

export default DashboardLeftMenu;
