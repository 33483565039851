import { createSlice } from "@reduxjs/toolkit";
import {
  getHomeFilter,
  getManageFilter,
  addManageFilter,
  editManageFilter,
  deleteManageFilter,
} from "redux/actions/homeFilter";

const initialState = {
  loading: false,
  error: null,
  message: null,
  data: [],
  isEditable: false,
  isDeletetable: false,
};

const homeFilterSlice = createSlice({
  name: "homeFilter",
  initialState,
  reducers: {
    setEditable(state) {
      state.isEditable = false;
    },
    setDeletable(state, action) {
      state.isDeletetable = false;
      state.isDeletetable = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHomeFilter.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getHomeFilter.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getHomeFilter.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });

    //is Ediable Filter In use
    // builder.addCase(isEditManageFilter.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(isEditManageFilter.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.isEditable = action.payload.data;
    //   state.error = "";
    // });
    // builder.addCase(isEditManageFilter.rejected, (state, action) => {
    //   state.loading = false;
    //   state.data = [];
    //   state.error = action.error.message;
    // });

    //get manage filter
    builder.addCase(getManageFilter.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getManageFilter.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getManageFilter.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });

    //edit manage filter
    builder.addCase(editManageFilter.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editManageFilter.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(editManageFilter.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });

    //add manage filter
    builder.addCase(addManageFilter.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addManageFilter.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(addManageFilter.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });

    //delete manage filter
    builder.addCase(deleteManageFilter.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteManageFilter.fulfilled, (state, action) => {
      state.loading = false;
      state.isDeletetable = action.payload.data;
      state.error = "";
    });
    builder.addCase(deleteManageFilter.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export const { setEditable, setDeletable } = homeFilterSlice.actions;
export default homeFilterSlice.reducer;