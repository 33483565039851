import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Box,
  Container,
} from "@mui/material";
import BasicTable from "Components/Common/BasicTable";
import DashboardLeftMenu from "Components/DashboardLeftMenu";
import CustomBreadcrumbs from "Components/Common/CustomBreadcrumbs";
import { getAllClients } from "redux/actions/clientListing";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ClientListing = ({ pageId }) => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.client);
  // let getListTable = data?.data?.lstAdmGetAllClientResponses;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [orderBy, setSetOrderBy] = useState("");

  const count = data?.data?.totalRecords;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const onSortHandler = (name, sortOrder) => {
    const order = sortOrder === "asc" ? "desc" : "asc";
    setSortColumn(`${name}_${order}`);
    setSetOrderBy(name);
    setSortOrder(order);
  };

  const paylaod = {
    pageNumber: page + 1,
    rowCount: rowsPerPage,
    sortColumn: sortColumn,
  };

  const navigate = useNavigate();
  const { userInfo, userData, error, isLoggedIn } = useSelector(
    (state) => state.auth
  );
  useEffect(() => {
    if (
      isLoggedIn &&
      userInfo &&
      Object.keys(userInfo).length > 0 &&
      userData?.sub?.userTypeId
    ) {
      userData.sub.userTypeId === 3 ? navigate("/admin/leads") 
      : navigate('/');
    }
  }, [userInfo]);

  useEffect(() => {
    dispatch(getAllClients(paylaod));
  }, [page, rowsPerPage, sortColumn]);

  useEffect(() => {
    setPage(0);
  }, [rowsPerPage]);


  return (
    <Container maxWidth="lg">
      <Grid container>
        <Grid
          item
          xs={3}
          borderRight="2px solid #F2F5FA"
          minHeight="90vh"
          py={2}
        >
          <DashboardLeftMenu />
        </Grid>
        <Grid item md={9}>
          <Grid container direction="row">
            <Grid item xs={12} px={2}>
              <Box visibility="hidden" mb={0} p={0}>
                <CustomBreadcrumbs />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                px={2}
              >
                <Typography variant="h3" component="h1" color="primary.darker">
                  Leads
                </Typography>
              </Grid>
            </Grid>
            {loading ? (
              <Grid item xs={12} p={2} align="center">
                {" "}
                <CircularProgress />{" "}
              </Grid>
            ) : (
              <Grid item xs={12} px={2} mb={2}>
                <BasicTable
                  data={data}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  count={count}
                  onSortHandler={onSortHandler}
                  sortOrder={sortOrder}
                  sortColumn={sortColumn}
                  orderBy={orderBy}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ClientListing;