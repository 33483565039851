import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";
import {
  Grid,
  Typography,
  Box,
  CircularProgress,
  Button,
  ListItemButton,
  List,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";

const OnboardingInformation = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const leftMenuList = [
    {
      id: 1,
      name: "Partner information",
    },
    {
      id: 2,
      name: "Type of services",
    },
    {
      id: 3,
      name: "Application screenshot",
    },
  ];

  const onBoardingInformation = useSelector(
    (state) => state.partnerListing?.partnerDetailsList
  );
  const apiItems = useSelector((state) => onBoardingInformation?.api);
  const connectivityChannelItems = useSelector(
    (state) => onBoardingInformation?.connectivityChannel
  );
  const applicationItems = useSelector(
    (state) => onBoardingInformation?.application
  );
  const industryItems = useSelector((state) => onBoardingInformation?.industry);
  const businessSizeItems = useSelector(
    (state) => onBoardingInformation?.businessSize
  );
  const erpSupportedItems = useSelector(
    (state) => onBoardingInformation?.erpSupported
  );
  const screenshotItems = useSelector(
    (state) => onBoardingInformation?.screenshot
  );

  const serviceDescription = useSelector(
    (state) => onBoardingInformation?.parterDetail?.serviceDescription
  );
  const serviceKeyPoint = useSelector(
    (state) => onBoardingInformation?.parterDetail?.serviceKeyPoint
  );

  const viewId = params.viewId;
  const encodedHash = encodeURIComponent(params.viewId);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(0);

  const pageActiveHandler = (index) => {
     setIsActive(index);
  }

  return (
    <Grid container id="contactInformation">
      <Grid container direction="row">
        {isLoading ? (
          <Grid item xs={9} px={2} my={6}>
            <Box display="flex" justifyContent="center">
              <CircularProgress color="primary" />
            </Box>
          </Grid>
        ) : (
          <Grid container xs={12}>
            <Grid item xs={12} md={9}>
            <Typography fontWeight="400" sx={{ fontSize: 28, mt: 3, px:2 }} gutterBottom >
              Fintech Partner onboarding information.
              </Typography>
              <Typography variant="h6" fontWeight="400" sx={{ mt: 3, px:2 }}>
                Partner information
              </Typography>

              <Grid item xs={12} md={12} px={2}>
                <Box display="flex" width={1} border="1px solid #828282">
                  <Box width="40%" p={2}>
                    <Typography color="textSecondary">
                      {" "}
                      Partner logo{" "}
                    </Typography>
                  </Box>
                  <Box
                    width="100%"
                    p={2}
                    bgcolor="#F7F7FA"
                    borderLeft="1px solid #828282"
                  >
                    <Typography color="textSecondary">
                      {" "}
                      <img
                        src={onBoardingInformation?.parterDetail?.logoUri}
                        alt={onBoardingInformation?.parterDetail?.companyName}
                        style={{ width: "10%" }}
                      />{" "}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  width={1}
                  border="1px solid #828282"
                  style={{ borderTop: "none" }}
                >
                  <Box width="40%" p={2}>
                    <Typography color="textSecondary">
                      {" "}
                      Company name{" "}
                    </Typography>
                  </Box>
                  <Box
                    width="100%"
                    p={2}
                    bgcolor="#F7F7FA"
                    borderLeft="1px solid #828282"
                  >
                    <Typography color="textSecondary">
                      {" "}
                      {onBoardingInformation?.parterDetail?.companyName ||
                        ""}{" "}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  width={1}
                  border="1px solid #828282"
                  style={{ borderTop: "none" }}
                >
                  <Box width="40%" p={2}>
                    <Typography color="textSecondary">
                      {" "}
                      Product name{" "}
                    </Typography>
                  </Box>
                  <Box
                    width="100%"
                    p={2}
                    bgcolor="#F7F7FA"
                    borderLeft="1px solid #828282"
                  >
                    <Typography color="textSecondary">
                      {" "}
                      {onBoardingInformation?.parterDetail?.productName ||
                        ""}{" "}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  width={1}
                  border="1px solid #828282"
                  style={{ borderTop: "none" }}
                >
                  <Box width="40%" p={2}>
                    <Typography color="textSecondary"> Website </Typography>
                  </Box>
                  <Box
                    width="100%"
                    p={2}
                    bgcolor="#F7F7FA"
                    borderLeft="1px solid #828282"
                  >
                    <Typography color="textSecondary">
                      {" "}
                      {onBoardingInformation?.parterDetail?.website || ""}{" "}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} px={2}>
                <Typography
                  variant="h6"
                  id="p-2"
                  fontWeight="400"
                  sx={{ mt: 3 }}
                >
                  Type of services
                </Typography>
              </Grid>

              <Grid item xs={12} md={12} px={2}>
                <Box display="flex" width={1} border="1px solid #828282">
                  <Box width="40%" p={2}>
                    <Typography color="textSecondary"> API's </Typography>
                  </Box>
                  <Box
                    width="100%"
                    p={2}
                    bgcolor="#F7F7FA"
                    borderLeft="1px solid #828282"
                  >
                    <Typography color="textSecondary">
                      {" "}
                      {apiItems &&
                        apiItems.length > 0 &&
                        apiItems.map((item) => item.name).join(', ')}{" "}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  width={1}
                  border="1px solid #828282"
                  style={{ borderTop: "none" }}
                >
                  <Box width="40%" p={2}>
                    <Typography color="textSecondary">
                      {" "}
                      Connectivity channel{" "}
                    </Typography>
                  </Box>
                  <Box
                    width="100%"
                    p={2}
                    bgcolor="#F7F7FA"
                    borderLeft="1px solid #828282"
                  >
                    <Typography color="textSecondary">
                      {" "}
                      {connectivityChannelItems &&
                        connectivityChannelItems.length > 0 &&
                        connectivityChannelItems.map(
                          (item) => item.channel).join(', ')}{" "}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  width={1}
                  border="1px solid #828282"
                  style={{ borderTop: "none" }}
                >
                  <Box width="40%" p={2}>
                    <Typography color="textSecondary">
                      {" "}
                      Application category{" "}
                    </Typography>
                  </Box>
                  <Box
                    width="100%"
                    p={2}
                    bgcolor="#F7F7FA"
                    borderLeft="1px solid #828282"
                  >
                    <Typography color="textSecondary">
                      {" "}
                      {applicationItems &&
                        applicationItems.length > 0 &&
                        applicationItems.map(
                          (item) => item.appName
                        ).join(', ')}{" "}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  width={1}
                  border="1px solid #828282"
                  style={{ borderTop: "none" }}
                >
                  <Box width="40%" p={2}>
                    <Typography color="textSecondary">
                      {" "}
                      Industry serviced
                    </Typography>
                  </Box>
                  <Box
                    width="100%"
                    p={2}
                    bgcolor="#F7F7FA"
                    borderLeft="1px solid #828282"
                  >
                    <Typography color="textSecondary">
                      {" "}
                      {industryItems &&
                        industryItems.length > 0 &&
                        industryItems.map((item) => item.industry).join(', ')}{" "}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  width={1}
                  border="1px solid #828282"
                  style={{ borderTop: "none" }}
                >
                  <Box width="40%" p={2}>
                    <Typography color="textSecondary">
                      {" "}
                      Business size
                    </Typography>
                  </Box>
                  <Box
                    width="100%"
                    p={2}
                    bgcolor="#F7F7FA"
                    borderLeft="1px solid #828282"
                  >
                    <Typography color="textSecondary">
                      {" "}
                      {businessSizeItems &&
                        businessSizeItems.length > 0 &&
                        businessSizeItems.map((item) => item.size).join(', ')}{" "}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  width={1}
                  border="1px solid #828282"
                  style={{ borderTop: "none" }}
                >
                  <Box width="40%" p={2}>
                    <Typography color="textSecondary">
                      {" "}
                      ERP's supported
                    </Typography>
                  </Box>
                  <Box
                    width="100%"
                    p={2}
                    bgcolor="#F7F7FA"
                    borderLeft="1px solid #828282"
                  >
                    <Typography color="textSecondary">
                      {" "}
                      {erpSupportedItems &&
                        erpSupportedItems.length > 0 &&
                        erpSupportedItems.map((item) => item.name).join(', ')}{" "}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              {serviceDescription && serviceDescription.length > 0 && (          
              <Grid item xs={12} md={12} p={2}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    fontWeight="600"
                    gutterBottom
                    sx={{ mb: 2, mt: 3 }}
                  >
                    {" "}
                    Service description{" "}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                  
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(serviceDescription),
                        }}
                      ></Typography>
              </Grid>
              )}

            {serviceKeyPoint && serviceKeyPoint.length > 0 && (          
              <Grid item xs={12} md={12} px={2}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    fontWeight="600"
                    gutterBottom
                  >
                    {" "}
                    Features & benefits{" "}
                  </Typography>
              <Typography
                    variant="body1"
                    gutterBottom
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(serviceKeyPoint),
                        }}
                      ></Typography>
              </Grid>
              )}

              <Grid item xs={12} md={12} p={2}>
                {screenshotItems && screenshotItems.length > 0 && (
                  <Typography
                    id="p-3"
                    color="textPrimary"
                    variant="h5"
                    fontWeight="600"
                    gutterBottom
                    sx={{ mb: 2, mt: 3 }}
                  >
                    {" "}
                    Application screenshot{" "}
                  </Typography>
                )}
                {screenshotItems &&
                  screenshotItems.length > 0 &&
                  screenshotItems.map((item) => (
                    <div key={item.id}>
                      <Typography
                        color="textPrimary"
                        fontWeight="600"
                        gutterBottom
                        sx={{ mb: 4, mt: 3 }}
                      >
                        {" "}
                        {item.title}
                      </Typography>
                      <Typography color="textSecondary">
                        {" "}
                        <img
                          src={item.uri}
                          alt={item.title}
                          style={{ width: "100%" }}
                        />{" "}
                      </Typography>

                      <Box
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(item?.description),
                        }}
                      ></Box>
                    </div>
                  ))}
              </Grid>
              <Grid item xs={12} md={12} p={2} align="right">
                <Button
                  variant="contained"
                  onClick={() => navigate(`/admin/step-one/${encodedHash}`)}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3} mt={-15}>
              <Typography
                variant="body1"
                sx={{
                  textTransform: "uppercase",
                  fontSize: 14,
                  marginBottom: 2,
                  fontWeight: 700,
                  marginTop: "8px",
                }}
              >
                On this page
              </Typography>
              <List component="nav" aria-label="nav menu" dense>
                {leftMenuList &&
                  leftMenuList.map((item, index) => (
                    <ListItemButton
                      href={`#p-${item.id}`}
                      key={nanoid(3)}
                      style={{
                        borderLeft: isActive === index ? "3px solid #0A41C5" : "",
                        color: isActive === index ? "#0A41C5" : "#555",
                        marginBottom: 10,
                      }}
                      onClick={() => pageActiveHandler(index)}
                    >
                      {item.name}
                    </ListItemButton>
                  ))}
              </List>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default OnboardingInformation;
