import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Link,
  Alert
} from "@mui/material";
import { useForm } from 'react-hook-form'; 
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { adminLogin } from 'redux/actions/auth/authAction';
import { resetData } from "redux/reducers/auth/authSlice";
import { useEffect, useState } from 'react';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { phoneRegExp, emailRegExp } from "utils";
import Loader from "Components/Common/Loader";
import TextFields from "Components/Common/TextFields";
import CheckboxFields from "Components/CheckboxFields";
import SelectFields from "Components/Common/SelectFields";
import {
  getPartner,
} from "redux/actions/onboarding/onboardingAction";

// create schema validation
const validationSchema = Yup.object({
  email: Yup
    .string()
    .required("Email is required")
    .matches(emailRegExp, "Email is not valid"),
  password: Yup.string()
  .min(8,'password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special')
  .required("Password is required")
  .matches(/[0-9]/, 'Password requires a number')
  .matches(/[a-z]/, 'Password requires a lowercase letter')
  .matches(/[A-Z]/, 'Password requires an uppercase letter')
  .matches(/[^\w]/, 'Password requires a symbol')
});

const formOptions = { 
  defaultValues: {
    email: "",
    password: "",
  },
  resolver: yupResolver(validationSchema) 
};

const AdminLogin = () => {
  const { userInfo, userData, error, isLoggedIn } = useSelector((state) => state.auth)
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const { register, handleSubmit, formState, control, reset} = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const [apiError, setApiError] = useState('');
  const [loader, setLoader] = useState(false);
  // redirect authenticated user to profile screen
  useEffect(() => {
    if (userData && userData?.sub?.userTypeId && !userData?.sub?.isFirstLogin) {
      userData.sub.userTypeId === 3 ? navigate('/admin/partner-listings') : navigate('/details/'+userData?.sub?.partnerName);
    }

    if(userData && userData.sub.userTypeId === 3 && userData?.sub?.isFirstLogin) {
      navigate('/admin/reset-password');
    }
  }, [userInfo])

  const submitForm = (data) => {
    setApiError("")
    setLoader(true);
    dispatch(adminLogin(data)).then((res) => {
      if(res.payload.error) {
        setApiError(res.payload.message)
      }
      setLoader(false);
    })
  }

  return loader ? (
    <Loader />
  ) : (
    <Container maxWidth="lg">
      <Box noValidate component="form" onSubmit={handleSubmit(submitForm)}>
        <Grid container spacing={2} alignItems="center" py={3}>
          <Grid item md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="h3" component='h1' color="primary.darker">
                  Admin login
                </Typography>
              </Grid>
              { apiError && (
                <Grid item xs={12} md={8}>
                  <Alert variant="filled" severity="error">{apiError}</Alert>
                </Grid>  
              )}
              <Grid item xs={12} md={8}>
                <TextFields errors={errors} control={control} label="Email *" name="email" />
              </Grid>
              <Grid item xs={12} md={8}>
              <TextFields errors={errors} control={control} label="Password *" name="password" type="password" />
              </Grid>

              <Grid item xs={12} md={8} align="right" justifyContent="space-between">
                <Link aria-label="Forgot your password?" variant="body1" sx={'cursor: pointer'}
                onClick={() => {
                  navigate("/admin/forgot-password");
                }}>
                  Forgot your password ?
                </Link>
              </Grid>
              <Grid item xs={12} md={8}>
                
                  <Button type="submit" variant="contained" color="primary" fullWidth>
                    Login
                  </Button>
                
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        </Box>
    </Container>
  );
};
export default AdminLogin;
