import { createSlice } from "@reduxjs/toolkit";
import {
  getAllClients,
  getClientById,
 } from "redux/actions/clientListing";


 const initialState = {
  loading: false,
  error: false,
  data:[],
  message: null,
};

const clientSlice = createSlice({
    name:'client',
    initialState,
    reducers: { },
    extraReducers: (builder) => {
      //Get Client
      builder.addCase(getAllClients.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getAllClients.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = "";
      });
      builder.addCase(getAllClients.rejected, (state, action) => {
        state.loading = false;
        state.data = [];
        state.error = action.error.message;
      });

      //Get Client by Id
      builder.addCase(getClientById.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getClientById.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = "";
      });
      builder.addCase(getClientById.rejected, (state, action) => {
        state.loading = false;
        state.data = [];
        state.error = action.error.message;
      });

    }
})


export default clientSlice.reducer;