import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Box} from "@mui/material";
import { useParams } from "react-router-dom";
import { getPartnerDetails } from 'redux/actions/admin/partnerListings';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import CircularProgress from "@mui/material/CircularProgress";
import config from "config";

const PartnerContactInformation = () => {
  const params = useParams();
  const textRef = useRef();
  const dispatch = useDispatch();
  const onBoardingInformation = useSelector((state) => state.partnerListing?.partnerDetailsList);
  const [isLoading, setIsLoading] = useState(false);
  const viewId = params.viewId;


  useEffect(() => {
    //getViewDetails();
   }, []);

  const getViewDetails = () => {
    if(viewId) {
      setIsLoading(true);
      dispatch(getPartnerDetails(viewId)).then(() => {
        setIsLoading(false);
      });
    }
  }

  const onCopyClick = () => {
    textRef.current.select();
    document.execCommand("copy");
  }

  return (
    <Grid container id="contactInformation">
        <Grid container direction="row">
              {isLoading ? 
              ( 
                <Grid item xs={9} px={2} my={6}>  
                <Box display="flex" justifyContent="center">
                  <CircularProgress color="primary" />
                </Box>
                </Grid>
              ) :          
              (
                <Grid item xs={9}>
                <Typography variant="h4" component="h2" sx={{ mt: 3, px:2 }}>
                  Contact information
                </Typography>

                <Grid item xs={12} md={12} px={2}>
                  <Box display="flex" width={1} border="1px solid #828282">
                    <Box width="40%" p={2}>
                      <Typography color="textSecondary"> Full name </Typography>
                    </Box>
                    <Box
                      width="100%"
                      p={2}
                      bgcolor="#F7F7FA"
                      borderLeft="1px solid #828282"
                    >
                      <Typography color="textSecondary"> {onBoardingInformation?.contactInformation?.fullname || ''} </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" width={1} border="1px solid #828282" style={{ borderTop: 'none' }}>
                    <Box width="40%" p={2}>
                      <Typography color="textSecondary"> Company name </Typography>
                    </Box>
                    <Box
                      width="100%"
                      p={2}
                      bgcolor="#F7F7FA"
                      borderLeft="1px solid #828282"
                    >
                      <Typography color="textSecondary"> {onBoardingInformation?.contactInformation?.company || ''} </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" width={1} border="1px solid #828282" style={{ borderTop: 'none' }}>
                    <Box width="40%" p={2}>
                      <Typography color="textSecondary"> Product name </Typography>
                    </Box>
                    <Box
                      width="100%"
                      p={2}
                      bgcolor="#F7F7FA"
                      borderLeft="1px solid #828282"
                    >
                      <Typography color="textSecondary"> {onBoardingInformation?.contactInformation?.productName || ''} </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" width={1} border="1px solid #828282" style={{ borderTop: 'none' }}>
                    <Box width="40%" p={2}>
                      <Typography color="textSecondary"> Website </Typography>
                    </Box>
                    <Box
                      width="100%"
                      p={2}
                      bgcolor="#F7F7FA"
                      borderLeft="1px solid #828282"
                    >
                      <Typography color="textSecondary"> {onBoardingInformation?.contactInformation?.website || ''} </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" width={1} border="1px solid #828282" style={{ borderTop: 'none' }}>
                    <Box width="40%" p={2}>
                      <Typography color="textSecondary"> Email </Typography>
                    </Box>
                    <Box
                      width="100%"
                      p={2}
                      bgcolor="#F7F7FA"
                      borderLeft="1px solid #828282"
                    >
                      <Typography color="textSecondary"> {onBoardingInformation?.contactInformation?.email || ''} </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" width={1} border="1px solid #828282" style={{ borderTop: 'none' }}>
                    <Box width="40%" p={2}>
                      <Typography color="textSecondary"> Phone </Typography>
                    </Box>
                    <Box
                      width="100%"
                      p={2}
                      bgcolor="#F7F7FA"
                      borderLeft="1px solid #828282"
                    >
                      <Typography color="textSecondary"> {onBoardingInformation?.contactInformation?.phone || ''} </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" width={1} border="1px solid #828282" style={{ borderTop: 'none' }}>
                    <Box width="40%" p={2}>
                      <Typography color="textSecondary"> Company role </Typography>
                    </Box>
                    <Box
                      width="100%"
                      p={2}
                      bgcolor="#F7F7FA"
                      borderLeft="1px solid #828282"
                    >
                      <Typography color="textSecondary"> {onBoardingInformation?.contactInformation?.companyRole || ''} </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" width={1} border="1px solid #828282" style={{ borderTop: 'none' }}>
                    <Box width="40%" p={2}>
                      <Typography color="textSecondary"> Headquarters location </Typography>
                    </Box>
                    <Box
                      width="100%"
                      p={2}
                      bgcolor="#F7F7FA"
                      borderLeft="1px solid #828282"
                    >
                      <Typography color="textSecondary"> {onBoardingInformation?.contactInformation?.city ? onBoardingInformation?.contactInformation?.city  + ', ' + onBoardingInformation?.contactInformation?.headquarterLocation : onBoardingInformation?.contactInformation?.headquarterLocation} </Typography>
                    </Box>
                  </Box>
                </Grid>
                { onBoardingInformation?.parterDetail?.shortDescription &&
                <Grid item xs={12} md={12} p={2}>
                  <Typography color="textPrimary" fontWeight='600' gutterBottom sx={{ mb: 2 }}> Partner description </Typography>
                  <Typography color="textPrimary" variant="body1" gutterBottom sx={{ mb: 2 }}> {onBoardingInformation?.parterDetail?.shortDescription}</Typography>

                </Grid>}
                {[3,5].includes(onBoardingInformation?.contactInformation?.currentstageid) && 
                <Grid item xs={12} md={12} p={2}>
                  <Typography color="primary.darker" variant="h5" fontWeight='400' gutterBottom sx={{ mb: 2 }}> Registration link of the partner </Typography>
                  <Box
                  display="flex"
                  alignItems="center"
                  >
                    <InputBase
                      inputRef={textRef}
                      style={{ width: "75%", color: "#008CE6" }}
                      sx={{ width: 1, color: "#008CE6", textDecoration:'underline' }}
                      value={`${config.baseURL}/partner-register/${onBoardingInformation?.contactInformation?.uniqueId}`}
                      inputProps={{ "aria-label": "resend link" }}
                    />

                    <IconButton
                      style={{ color: "#008CE6" }}
                      aria-label="search"
                      onClick={onCopyClick}
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Box>

                </Grid>
                }
              </Grid>
              )
            }
          </Grid>
        </Grid>
  );
};

export default PartnerContactInformation;
