// authActions.js
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import config from "config";
import Cookies from "universal-cookie";
import { getAccessToken } from "helper/user";
const cookies = new Cookies();
const accessToken = cookies.get("@clientAccessToken");

//const backendURL = "http://localhost:8083";
const header = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const refreshAccessAndRefreshTokens = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  const response = await axios.post(
    `${config.apiBase}/profile/token`,
    { refreshToken },
    header
  );

  return response;
};

export const validateUserDetails = createAsyncThunk(
  'reset/validateUserDetails',
  async (payload, { rejectWithValue }) => {
    try {
      const headerConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let cookies = new Cookies(window.document.cookie);
      // const accessToken = await getAccessToken();
      const { data } = await axios.post(
        `${config.apiBase}/profile/VerifyUserDetails`,
        payload,
        headerConfig
      );
        
      if(!data.data) {
        return rejectWithValue(data.message);
      }
      
      return data;
    } catch (error) {
      // return custom error message from backend if present
        if (error.response && error.response.data.message) {
          return rejectWithValue({status:error.response.status, data: error.response.data})
        } else {
          return rejectWithValue(error.message)
        }
      }
  }
)

export const validateAdminDetails = createAsyncThunk(
  'reset/validateAdminDetails',
  async (id, { rejectWithValue }) => {
    try {
      const headerConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.get(
        `${config.apiBase}/profile/verifyPasswordResetUrl/${id}`,
        headerConfig
      );
      
      return data;
    } catch (error) {
      // return custom error message from backend if present
        if (error.response && error.response.data.message) {
          return rejectWithValue({status:error.response.status, data: error.response.data})
        } else {
          return rejectWithValue(error.message)
        }
      }
  }
)

export const changePassword = createAsyncThunk(
  'reset/changePassword',
  async (payload, { rejectWithValue }) => {
    try {
      const headerConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let cookies = new Cookies(window.document.cookie);
      // const accessToken = await getAccessToken();
      const { data } = await axios.post(
        `${config.apiBase}/profile/changePassword`,
        payload,
        headerConfig
      );
        
      return data;
    } catch (error) {
      // return custom error message from backend if present
        if (error.response && error.response.data.message) {
          return rejectWithValue({status:error.response.status, data: error.response.data})
        } else {
          return rejectWithValue(error.message)
        }
      }
  }
)

export const forgotAdminPassword = createAsyncThunk(
  'reset/forgotAdminPassword',
  async (payload, { rejectWithValue }) => {
    try {
      const headerConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let cookies = new Cookies(window.document.cookie);
      // const accessToken = await getAccessToken();
      const { data } = await axios.post(
        `${config.apiBase}/profile/admin/verifyUserDetails`,
        payload,
        headerConfig
      );
        
      return data;
    } catch (error) {
      // return custom error message from backend if present
        if (error.response && error.response.data.message) {
          return rejectWithValue({status:error.response.status, data: error.response.data})
        } else {
          return rejectWithValue(error.message)
        }
      }
  }
)

export const resetAdminPassword = createAsyncThunk(
  'reset/resetAdminPassword',
  async (payload, { rejectWithValue }) => {
    try {
      const accessToken = await getAccessToken();
      const headerConfig = {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${accessToken}`,
        },
      };
      let cookies = new Cookies(window.document.cookie);
      // const accessToken = await getAccessToken();
      const { data } = await axios.post(
        `${config.apiBase}/profile/admin-resetPassword`,
        payload,
        headerConfig
      );
        
      return data;
    } catch (error) {
      // return custom error message from backend if present
        if (error.response && error.response.data.message) {
          return rejectWithValue({status:error.response.status, data: error.response.data})
        } else {
          return rejectWithValue(error.message)
        }
      }
  }
)

export const changeAdminPassword = createAsyncThunk(
  'reset/changeAdminPassword',
  async (payload, { rejectWithValue }) => {
    try {
      const headerConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let cookies = new Cookies(window.document.cookie);
      // const accessToken = await getAccessToken();
      const { data } = await axios.post(
        `${config.apiBase}/profile/change-password`,
        payload,
        headerConfig
      );
        
      if(!data.data) {
        return rejectWithValue(data.message);
      }
      
      return data;
    } catch (error) {
      // return custom error message from backend if present
        if (error.response && error.response.data.message) {
          return rejectWithValue({status:error.response.status, data: error.response.data})
        } else {
          return rejectWithValue(error.message)
        }
      }
  }
)



export const logout = createAsyncThunk(
  'user/logout',
  async (_, { rejectWithValue }) => {
    try {
      const accessToken = await getAccessToken();
      const header = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'pragma': 'no-cache',
        },
      }
      const { data } = await axios.post(
        `${config.apiBase}/profile/logout`,{},
        header
      )
      return data;
    } catch (error) {
    // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

  export const userLogin = createAsyncThunk(
    'user/login',
    async ({ email, password }, { rejectWithValue }) => {
      try {
        const headerConfig = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        let cookies = new Cookies(window.document.cookie);
        // const accessToken = await getAccessToken();
        const { data } = await axios.post(
          `${config.apiBase}/profile/login`,
          { email, password },
          headerConfig
        );
          
        if(!data.data) {
          return rejectWithValue(data);
        }
        else{
           // store user's token in local storage
          cookies.set("@clientAccessToken", data.data.accessToken, {
            path: `${config.baseName}/`,
          });
          cookies.set("@clientRefreshToken", data.data.refreshToken, {
            path: `${config.baseName}/`,
          });
          localStorage.setItem('userToken', data.data.accessToken);
          localStorage.setItem('refreshToken', data.data.refreshToken);
        }
  
        return data;
      } catch (error) {
        // return custom error message from backend if present
          if (error.response && error.response.data.message) {
            return rejectWithValue({status:error.response.status, data: error.response.data})
          } else {
            return rejectWithValue(error.message)
          }
        }
    }
  )

  export const adminLogin = createAsyncThunk(
    'user/login',
    async ({ email, password }, { rejectWithValue }) => {
      try {
        const headerConfig = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        let cookies = new Cookies(window.document.cookie);
        // const accessToken = await getAccessToken();
        const { data } = await axios.post(
          `${config.apiBase}/profile/admin-login`,
          { email, password },
          headerConfig
        );
          
        if(!data.data) {
          return rejectWithValue(data);
        }
        else{
           // store user's token in local storage
          cookies.set("@clientAccessToken", data.data.accessToken, {
            path: `${config.baseName}/`,
          });
          cookies.set("@clientRefreshToken", data.data.refreshToken, {
            path: `${config.baseName}/`,
          });
          localStorage.setItem('userToken', data.data.accessToken);
          localStorage.setItem('refreshToken', data.data.refreshToken);
        }
  
        return data;
      } catch (error) {
        // return custom error message from backend if present
          if (error.response && error.response.data.message) {
            return rejectWithValue({status:error.response.status, data: error.response.data})
          } else {
            return rejectWithValue(error.message)
          }
        }
    }
  )

  export const refreshTokens = createAsyncThunk(
    'auth/refreshTokens',
    async (_, { rejectWithValue }) => {
      try {
        const response = await refreshAccessAndRefreshTokens();
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data.message);
      }
    }
  );



export const registerUser = createAsyncThunk(
  "auth/register",
  async ({ password, questionId, answer, leadId }, { rejectWithValue }) => {
    try {
      const headerConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${config.apiBase}/profile/sign-up`,
        { password, questionId, answer, leadId },
        headerConfig
      );
      return data
    }
    
    catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);



