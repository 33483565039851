import axios from 'axios'
import Cookies from "universal-cookie";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAccessToken } from "helper/user";
import jwtDecode from 'jwt-decode';
import config from "config";

export const fetchPartnerListings = createAsyncThunk(
    'admin/getAllPartners',
    async (payload, { rejectWithValue, dispatch }) => {
      try {
        const accessToken = await getAccessToken();
        const headers = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        }
        const response = await axios.post(`${config.apiBase}/admin/getAllPartners`, payload, headers)
        return response.data.data
      } catch (error) {
      // return custom error message from backend if present
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )

  export const getStagesAndCount = createAsyncThunk(
    'admin/getStagesAndCount',
    async (_, { rejectWithValue }) => {
        
      try {
        const accessToken = await getAccessToken();
        const headers = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        }
        const response = await axios.get(`${config.apiBase}/admin/getStagesAndCount`, headers)
        return response.data.data
      } catch (error) {
      // return custom error message from backend if present
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )

  export const getPartnerDetails = createAsyncThunk(
    'admin/getPartnerDetails',
    async (viewId, { rejectWithValue }) => {
      try {
        const accessToken = await getAccessToken();
        const headers = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        }
        //const response = await axios.get(`${config.apiBase}/admin/getPartnerDetails?leadId=${viewId}`, headers)
        const response = await axios.get(`${config.apiBase}/admin/getPartnerDetails?leadId=${viewId}`, headers);
        return response.data.data
      } catch (error) {
      // return custom error message from backend if present
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )

  export const getStagesAndNextAction = createAsyncThunk(
    'admin/getStagesAndNextAction',
    async (_, { rejectWithValue }) => {
        
      try {
        const accessToken = await getAccessToken();
        const headers = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        }
        const response = await axios.get(`${config.apiBase}/admin/getStagesAndNextAction`, headers)
        return response.data.data
      } catch (error) {
      // return custom error message from backend if present
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )

  export const changeStatus = createAsyncThunk(
    'admin/changeStatus',
    async (statusPayload, { rejectWithValue, dispatch }) => {
      try {        
        const accessToken = await getAccessToken();
        const tokenData = jwtDecode(accessToken);
        const partnerId = statusPayload.getCurrentId;
        const status = statusPayload.getStatusId.nextstageid;
        const statusChangeBy = tokenData.sub.id;
        const headers = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        }
        const response = await axios.get(`${config.apiBase}/admin/changeStatus?partnerId=${partnerId}&status=${status}&statusChangeBy=${statusChangeBy}`, headers);
        if(statusPayload?.payload) {
          dispatch(fetchPartnerListings(statusPayload.payload))
          dispatch(getStagesAndCount())
        }
        else {
          dispatch(getPartnerDetails(partnerId));
        }
        
        return response.data.data
      } catch (error) {
      // return custom error message from backend if present
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )