import { Container, Typography, Box } from "@mui/material";
const PageNotFound = () => {
  return (
    <Container maxWidth="sm">
      <Box py={4} minHeight="68vh">
        <Typography fontWeight="400" fontSize="4rem" gutterBottom>
         Server Error
        </Typography>
        <Typography fontSize="24px" color="#666" fontFamily='Optima, Segoe, "Segoe UI", Candara, Calibri, Arial, sans-serif' fontWeight="400" gutterBottom>
        Sorry, something went terribly wrong!
        </Typography>
        <Typography variant="h3" gutterBottom py={3}>
         404- Page Not Found
        </Typography>
        <Typography variant="body1" color="#999" fontWeight="400" gutterBottom>
        For further details please review your <code style={{fontFamily:'Menlo, Monaco, Consolas, "Courier New", monospace', fontWeight:'bold'}}>logs/</code> folder, or enable displaying of errors in your system configuration.
        </Typography>
        
      </Box>
    </Container>
  );
};
export default PageNotFound;
