import { useEffect, useState, useRef } from "react";
import TextImageBanner from "Components/TextImageBanner";
import TextButtonBanner from "Components/TextButtonBanner";
import Banner from "Components/Banner";
import { PartnersCard } from "Components/PartnersCard";
import Filter from "Components/Filter";
import { fetchPartners, storeHomeFilter } from "redux/reducers/partners/partnersSlice";
import {
  Container,
  Grid,
  Typography,
  Pagination,
  Box,
  Button,
  Drawer,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

const Home = () => {
  const { data, homeFilter, loading } = useSelector((state) => state.partner);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  let dispatch = useDispatch();
  const [filterName, setFilterName] = useState([]);
  const [filterParams, setFilterParams] = useState({
    applicationIds: [],
    businessSizeIds: [],
    erpSupportedIds: [],
    industryIds: [],
    rowCount: 6,
    pageNo: 1,
  });
  useEffect(() => {
    const handlePageChange = () => {
      executeScroll()
    };
    handlePageChange();
  }, [homeFilter.pageNo]);

  const count = Math.ceil(data?.totalRecords / 6);
  const handlePageChange = (event, value) => {
    let par = { ...homeFilter, pageNo: value };
    // setFilterParams(par);
    dispatch(storeHomeFilter(par))
    dispatch(fetchPartners(par));
    // executeScroll();
  };

  const clearHandler = () => {
    const params = {
      applicationIds: [],
      businessSizeIds: [],
      erpSupportedIds: [],
      industryIds: [],
      rowCount: 6,
      pageNo: 1,
    };

    // setFilterParams(params);
    dispatch(storeHomeFilter(params))
    dispatch(fetchPartners(params));
    setFilterName([]);
  };

  useEffect(() => {
    // console.log(filterName);
    adobeFilterUtag();
  }, [filterName])

  const onChangeHandler = (e, id, filName) => {
    const { name, checked } = e.target;
    let params = { ...homeFilter };

    if(checked) {
      setFilterName(() => [...filterName || [], ...[filName]]);
      
    }
    
    switch (name) {
      case "applicationIds":
        if (checked) {
          params.applicationIds = [...params.applicationIds, ...[id]];
        } else {
          const index = params.applicationIds.indexOf(id);
          if (index > -1) {
            //params.applicationIds.splice(index, 1);
            params.applicationIds = params.applicationIds.filter(item => item !== id)
          }
        }
        break;

      case "businessSizeIds":
        if (checked) {
          //params.businessSizeIds.push(id);
          params.businessSizeIds = [...params.businessSizeIds, ...[id]];
        } else {
          const index = params.businessSizeIds.indexOf(id);
          if (index > -1) {
            //params.businessSizeIds.splice(index, 1);
            params.businessSizeIds = params.businessSizeIds.filter(item => item !== id)
          }
        }
        break;

      case "erpSupportedIds":
        if (checked) {
          //params.erpSupportedIds.push(id);
          params.erpSupportedIds = [...params.erpSupportedIds, ...[id]];
        } else {
          const index = params.erpSupportedIds.indexOf(id);
          if (index > -1) {
            //params.erpSupportedIds.splice(index, 1);
            params.erpSupportedIds = params.erpSupportedIds.filter(item => item !== id)
          }
        }
        break;

      case "industryIds":
        if (checked) {
          //params.industryIds.push(id);
          params.industryIds = [...params.industryIds, ...[id]];
        } else {
          const index = params.industryIds.indexOf(id);
          if (index > -1) {
            //params.industryIds.splice(index, 1);
            params.industryIds = params.industryIds.filter(item => item !== id)
          }
        }
        break;
      default:
        break;
    }
    //setFilterParams(params);
    params.pageNo = 1;
    dispatch(storeHomeFilter(params))
    // localStorage.setItem('homeFilter', JSON.stringify(params))
  };

  const adobeFilterUtag = () => {
    const reportingData = {
      onClickEvent : "usb:partner directory:home page:filter",
      siteSection : "home page",
      subSiteSection : "partner directory",
      sitePlatform : "dotcom",
      language: "en-us",
      datasource : "onClick",
      filterSelection : "partner directory:" + filterName?.join(',').replace(/[^a-zA-Z0-9]/g,' ').replace(/\s\s+/g, ' ').toLowerCase(),
    };
    if(window.publisherFW && filterName.length > 0){
      console.log("Filter ===>", reportingData);
      window.publisherFW.publishEvent("onClick", reportingData); //call this function after the data layer object
    }
    
  }

  const adobeUtag = () => {
    const reportingData = {
      currentPage : "usb:partner directory:home page",
      siteSection : "home page",
      subSiteSection : "partner directory",
      sitePlatform : "dotcom",
      language: "en-us",
      datasource : "pageView",
    };
    if(window.publisherFW){
      console.log("Home ===>", reportingData);
      window.publisherFW.publishEvent("pageView", reportingData); //call this function after the data layer object
    }
    
  }

  useEffect(() => {
    adobeUtag();
    const params = {
      applicationIds: [],
      businessSizeIds: [],
      erpSupportedIds: [],
      industryIds: [],
      rowCount: 6,
      pageNo: 1,
    };

    // setFilterParams(params);
    dispatch(storeHomeFilter(params))
    dispatch(fetchPartners(params));
  },[])

  return (
    <>
      <Banner />
      <Container maxWidth="lg">
        <Typography variant="h2" color="primary.darker" component="h1" my={2}>
          Find your partner.
        </Typography>
        <Typography mb={3} color="#555" ref={myRef}>
          Explore our growing list of integrated partners. Check back often –
          new partnerships are coming soon.
        </Typography>

        <Grid
          item
          container
          spacing={4}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={12} sx={{ display: { xs: "block", sm: "none" } }}>
            <Drawer
              anchor="left"
              open={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
            >
              <Box width={320} role="presentation" pb={3}>
                <Filter
                  filterParams={homeFilter}
                  onChangeHandler={onChangeHandler}
                  clearHandler={clearHandler}
                  boxDesign={true}
                  setIsDrawerOpen={setIsDrawerOpen}
                />
              </Box>
            </Drawer>
            <Button
              variant="contained"
              fullWidth
              onClick={() => setIsDrawerOpen(true)}
            >
              {" "}
              Filters
            </Button>
          </Grid>

          <Filter
            filterParams={homeFilter}
            onChangeHandler={onChangeHandler}
            clearHandler={clearHandler}
            pageScroll={executeScroll}
          />

          <Grid item container spacing={3} rowSpacing={1} xs={12} sm={6} md={8}>
            {data?.totalRecords > 0 ? (
              <PartnersCard
                filterParams={homeFilter}
                partnerListCard={data}
                loading={loading}
              />
            ) : (
              <Box
                py={2}
                mb={3}
                width={1}
                display="flex"
                justifyContent="center"
              >
                <Typography
                  mb={3}
                  align="center"
                  variant="h3"
                  color="primary.dark"
                >
                  Data not found
                </Typography>
              </Box>
            )}

            <Grid item xs={12}>
              <Box py={2} mb={3} display="flex" justifyContent="center">
                {data?.totalRecords > homeFilter.rowCount && !loading ? (
                  <Pagination
                    count={count}
                    page={homeFilter.pageNo}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                  />
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <TextImageBanner />
      <TextButtonBanner />
    </>
  );
};
export default Home;
