import * as React from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Box,
  IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

export default function AlertDialog({
  message,
  children,
  color,
  open,
  onClose,
  showCloseIcon = true,
}) {
 
  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        keepMounted
        maxWidth="xs"
        sx={{ textAlign: "center" }}
      >
        <DialogTitle
          color={color || "#555"}
          fontWeight="400"
          id="alert-dialog-title"
          position="relative"
          mb={0}
        >
          <Box pt={2}> 
          {message}
          </Box>
           <Box sx={{ position: "absolute", top: 0, right: 0 }}>
            {
              showCloseIcon ?  <IconButton disableRipple onClick={() => onClose()}>
              <CancelIcon
                color="primary"
                fontSize="small"
                sx={{ width: "16px" }}
              />
            </IconButton> :''
            }
           
          </Box>
        
        </DialogTitle>
        <DialogActions
          sx={{pb:2, display: "flex", justifyContent: "center", gap: 1 }}
        >
          {children}
        </DialogActions>
      </Dialog>
    </>
  );
}