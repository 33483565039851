import { Box, Typography } from "@mui/material"
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const ErrorMessage = ({message}) => {
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      mt: '6px'
    }}>
      <WarningAmberIcon color="error" sx={{width: '14px'}} />
      <Typography color='error.main' variant='body2' sx={{fontSize: '12px'}}>
        {message}
      </Typography>
    </Box>
  )
}

export default ErrorMessage