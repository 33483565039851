import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  MenuItem,
  Select,
  Box,
  InputLabel,
  ListItemText,
  TableSortLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CustomBreadcrumbs from "Components/Common/CustomBreadcrumbs";
import {
  fetchPartnerListings,
  getStagesAndCount,
  changeStatus,
} from "redux/actions/admin/partnerListings";
import { setStatusList, resetStatusList } from "redux/reducers/partners/partnersSlice";
import CircleNotificationsRoundedIcon from "@mui/icons-material/CircleNotificationsRounded";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import AlertDialog from "Components/Common/AlertDialog";
import { logout } from "redux/actions/auth/authAction";
import { styled } from "@mui/material/styles";
import { resetOnboarding } from "redux/reducers/onboarding/onboardingSlice";
import { resetPartnerListing }  from "redux/reducers/admin/partnerListingsSlice"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getcontentanchorel: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "none",
    color: "#2C2C2C",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F7F7FA",
  },
}));

const PartnerListing = () => {
  sessionStorage.removeItem("formData")
  const navigate = useNavigate();
  const partnerListingFetch = useSelector(
    (state) => state.partnerListing?.data?.lstAdmGetAllParnerResponses
  );
  const partnerListingLength = useSelector(
    (state) => state.partnerListing?.data?.totalRecords
  );
  const partnerListingFilter = useSelector(
    (state) => state.partnerListing?.partnerStages
  );
  const selectedStatusList = useSelector((state) => state.partner.statusList);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  //const [selectedStatusList, setSelectedStatusList] = useState([]);
  const [getCurrentId, setGetCurrentId] = useState("");
  const [currLeadId, setCurrLeadId] = useState('') 
  const [getStatusName, setGetStatusName] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("");
  const [orderBy, setOrderBy] = useState("");
  localStorage.removeItem("videoUrl")
  useEffect(() => {
    getPartnerListingFilter();
    sessionStorage.clear()
    setPayload({
      ...payload,
      statusId: '',
      pageNumber: 1,
    });
    dispatch(resetStatusList([]));
  }, []);

  const getPartnerListing = (payload) => {
    setIsLoading(true);
    dispatch(fetchPartnerListings(payload)).then(() => {
      setIsLoading(false);
    });
  };

  const getPartnerListingFilter = () => {
    dispatch(getStagesAndCount());
  };

  const [nextItemAction, setNextItemAction] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [payload, setPayload] = useState({
    pageNumber: page + 1,
    rowCount: rowsPerPage,
    sortColumn: sortColumn,
    statusId: selectedStatusList && selectedStatusList.length ? selectedStatusList.join(",") : '',
  });
  const handleClick = (e, nextItem) => {
    sessionStorage.setItem('leadId', nextItem?.id)
    setAnchorEl(e.currentTarget);
    setGetCurrentId(nextItem.partnername);
    setNextItemAction(nextItem.nextActions);
    setGetStatusName(nextItem.status);
    setCurrLeadId(nextItem.id)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const popOverId = open ? `simple-popover` : null;

  const handleNameClick = (event, id, item) => {
    const encodedHash = encodeURIComponent(id);
    sessionStorage.setItem("payload", JSON.stringify(payload))
    sessionStorage.setItem('leadId', item?.id)
    setGetCurrentId(id);
    navigate(`/admin/partner-listings/partner-details/${encodedHash}`);
  };

  const handleStatusCheckBox = ({ target }) => {
    const { value } = target;
    dispatch(setStatusList([...value]));
    // setSelectedStatusList(value);
    setPayload({
      ...payload,
      statusId: value.join(","),
      pageNumber: 1,
    });
  };



  const handleChangePage = (event, newpage) => {
    setPage(newpage);
    setPayload({ ...payload, pageNumber: newpage + 1 });
  };

  // useEffect(() => {
  //   setPayload({ ...payload, pageNumber: page + 1 });
  // }, [rowsPerPage]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPayload({
      ...payload,
      pageNumber: page + 1,
      rowCount: parseInt(event.target.value, 10),
    });
  };

  useEffect(() => {
    getPartnerListing(payload);
  }, [payload]);

  const [newPartner, setNewPartner] = useState(false);
  const onClose = () => {
    setNewPartner(false);
  };
  const addNewPartner = () => {
    setNewPartner(true);
  };

  const handleSignOut = async () => {
    dispatch(logout()).then((res) => {
      dispatch(resetStatusList([]));
      dispatch(resetOnboarding());
      dispatch(resetPartnerListing());
      logoutUser();
    });
  };

  const logoutUser = () => {
    navigate("/partner-with-us");
  };
  const [getStatusId, setGetStatusId] = useState();
  const [statusAlert, setStatusAlert] = useState(false);

  const closeStatusAlert = () => {
    setStatusAlert(false);
  };
  const openStatusAlert = (event, nextItemAction) => {
    setStatusAlert(true);
    setGetStatusId(nextItemAction);
    setAnchorEl(null);
  };

  const handleStatusUpdate = () => {
    const statusPayload = {
      getCurrentId : currLeadId,
      getStatusId,
      payload,
    };
    dispatch(changeStatus(statusPayload));
    setStatusAlert(false);
    setPayload({ ...payload });
    setAnchorEl(null);
  };

  const pushToViewDetails = (id) => {
    navigate(`/admin/partner-listings/partner-details/${getCurrentId}`);
  };

  const onSortHandler = (name, sortOrder) => {
    const order = sortOrder === "asc" ? "desc" : "asc";
    setOrderBy(name);
    setSortColumn(`${name}_${order}`);
    setSortOrder(order);
    const payload = {
      pageNumber: page + 1,
      rowCount: rowsPerPage,
      sortColumn: `${name}_${order}`,
      statusId: selectedStatusList.length ? selectedStatusList.join(",") : '',
    };
    setPayload((p) => ({...p, ...payload}));
    dispatch(fetchPartnerListings(payload));
  };

  return (
    <Grid container id="partnerListing">
      <Grid item md={12}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Box visibility="hidden" mb={0} p={0}>
              <CustomBreadcrumbs />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="h3" component="h1" color="primary.darker">
                Partner listings
              </Typography>
              <Button variant="contained" onClick={addNewPartner}>
                Add new partner
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography color="textSecondary" gutterBottom>
              Filter by status
            </Typography>

            <FormControl sx={{ minWidth: 360, my: 2 }}>
              <InputLabel id="mutiple-select-label">Select Status</InputLabel>
              <Select
                labelId="mutiple-select-label"
                multiple
                value={selectedStatusList || []}
                onChange={handleStatusCheckBox}
                renderValue={(selected) =>
                  `${selected.length} status options selected`
                }
                MenuProps={MenuProps}
              >
                {partnerListingFilter && Array.isArray(partnerListingFilter) ?
                  partnerListingFilter?.map(({ stageid, stagename }) => (
                    <MenuItem key={stageid} value={stageid}>
                      <Checkbox
                        size="small"
                        checked={selectedStatusList?.indexOf(stageid) > -1}
                      />

                      <ListItemText primary={stagename} />
                    </MenuItem>
                  )) : null}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} mb={2}>
            <TableContainer>
              <Table
                sx={{ minWidth: 700, border: "1px solid #CDCDD3" }}
                aria-label="table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "20px" }}></TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "partner_name"}
                        direction={
                          orderBy === "partner_name" ? sortOrder : "asc"
                        }
                        onClick={() => onSortHandler("partner_name", sortOrder)}
                      >
                        Partner name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      Name
                      <Typography color="textSecondary" variant="body2">
                        {" "}
                        Company role{" "}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "created_date"}
                        direction={
                          orderBy === "created_date" ? sortOrder : "asc"
                        }
                        onClick={() => onSortHandler("created_date", sortOrder)}
                      >
                        {" "}
                        Date Received{" "}
                      </TableSortLabel>{" "}
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "status"}
                        direction={orderBy === "status" ? sortOrder : "asc"}
                        onClick={() => onSortHandler("status", sortOrder)}
                      >
                        Status{" "}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "status_updated"}
                        direction={
                          orderBy === "status_updated" ? sortOrder : "asc"
                        }
                        onClick={() =>
                          onSortHandler("status_updated", sortOrder)
                        }
                      >
                        Status Updated On{" "}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <StyledTableRow>
                      <StyledTableCell colSpan={7}>
                        <Box display="flex" justifyContent="center">
                          <CircularProgress color="primary" />
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                    partnerListingFetch &&
                    partnerListingFetch?.map((item, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell>
                          {item.isNotify == "true" ? (
                            <CircleNotificationsRoundedIcon
                              color="#0C2074"
                              sx={{ color: "#0C2074" }}
                            />
                          ) : (
                            ""
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box
                            className="nameLink"
                            onClick={(event) => handleNameClick(event, item.partnername, item)}
                          >
                            {item.partnername}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.name}

                          <Box color="#828282">{item.companyrole}</Box>
                        </StyledTableCell>
                        <StyledTableCell>{item.daterecieved}</StyledTableCell>
                        <StyledTableCell>{item.status}</StyledTableCell>
                        <StyledTableCell>
                          {item.statusupdatedon}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box
                            aria-describedby={popOverId}
                            id={`${item.id}`}
                            onClick={(e) => handleClick(e, item)}
                            className="pointer"
                          >
                            <MoreVertIcon />
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              rowsPerPageOptions={[10, 20, 30, 50, 100]}
              count={partnerListingLength || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Grid>
      <Popover
        id={popOverId}
        anchorEl={anchorEl}
        onClose={handleClose}
        //open={open}
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {nextItemAction &&
          nextItemAction?.map((status, index) => (
            <div
              className="link"
              key={index}
              id={status.nextstageid}
              onClick={(event) => openStatusAlert(event, status)}
            >
              {status.nextstagename}
            </div>
          ))}

        <div className="link" onClick={pushToViewDetails}>
          View Details
        </div>
      </Popover>
      {statusAlert && (
        <AlertDialog
          message={`Do you want to change the status from ${getStatusName} to ${getStatusId?.nextstagename}?`}
          open={statusAlert}
          onClose={closeStatusAlert}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleStatusUpdate}
          >
            Confirm
          </Button>
        </AlertDialog>
      )}
      {newPartner && (
        <AlertDialog
          message="Do you want to log out of the Admin console?"
          open={newPartner}
          onClose={onClose}
        >
          <Button variant="contained" color="primary" onClick={handleSignOut}>
            Confirm
          </Button>
          <Button variant="outlined" color="primary" onClick={onClose}>
            Cancel
          </Button>
        </AlertDialog>
      )}
    </Grid>
  );
};

export default PartnerListing;
