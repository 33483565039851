import { useState, useEffect } from "react";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
  convertFromRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { convertToHTML } from "draft-convert";
import { Grid, Typography, TextField, Button, Box } from "@mui/material";

const HtmlEdior = ({
  onChnage,
  placeholder,
  isToolbar = true,
  defaultValue,
  MAX_LENGTH = 200,
  disable,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [maxlength, setMaxlength] = useState("");
  const [charlength, setCharlength] = useState("");
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    if (editorState.getLastChangeType() !== "split-block") {
      setCharlength(currentContentLength);
    }
    onChnage &&
      onChnage(
        currentContentLength
          ? convertToHTML(editorState.getCurrentContent())
          : ""
      );
  };

  useEffect(() => {
    if (defaultValue) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(defaultValue))
        )
      );
      const currentContent = EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(defaultValue))
      ).getCurrentContent();
      const currentContentLength = currentContent.getPlainText("").length;
      setCharlength(currentContentLength);
      if (currentContentLength > MAX_LENGTH) {
        setMaxlength(`you can type max ${MAX_LENGTH} characters`);
        console.log(`you can type max ${MAX_LENGTH} characters`);
      } else {
        setMaxlength("");
      }
    }
  }, [defaultValue]);

  const _handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    if (currentContentLength >= MAX_LENGTH) {
      setMaxlength(`you can type max ${MAX_LENGTH} characters`);
      console.log(`you can type max ${MAX_LENGTH} characters`);
      return "handled"; // Prevent further input
    } else {
      setMaxlength("");
    }
  };

  const _handlePastedText = (pastedText) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;

    if (currentContentLength + pastedText.length > MAX_LENGTH) {
      setMaxlength(`you can type max ${MAX_LENGTH} characters`);
      console.log("you can type max ten characters");

      return "handled";
    }else{
      setMaxlength("");
    }
  };

  //setFieldValue(draftToHtml(convertToRaw(editorState.getCurrentContent())));

  return (
    <>
      <Editor
        editorState={editorState}
        wrapperClassName="htmlEdiorWrap"
        editorClassName="HtmlEditorPad"
        onEditorStateChange={onEditorStateChange}
        handleBeforeInput={_handleBeforeInput}
        handlePastedText={_handlePastedText}
        placeholder={placeholder}
        readOnly={disable}
        toolbar={
          isToolbar
            ? {
                options: ["inline", "list"],
                inline: {
                  options: ["bold", "italic", "underline"],
                  bold: { className: "bordered-option-classname" },
                  italic: { className: "bordered-option-classname" },
                  underline: { className: "bordered-option-classname" },
                  strikethrough: { className: "bordered-option-classname" },
                },
                blockType: {
                  inDropdown: true,
                  options: ["Normal"],
                },
              }
            : { options: [] }
        }
      />
      <Typography color="textSecondary" variant="body2" align="right">
        {charlength}/{MAX_LENGTH}
      </Typography>
      {maxlength && (charlength >= MAX_LENGTH) && (
        <Box>
          <Typography color="error">{maxlength}</Typography>
        </Box>
      )}

      {/* <textarea
          disabled
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        />  */}
    </>
  );
};

export default HtmlEdior;
