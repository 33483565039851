import {createSlice} from '@reduxjs/toolkit'
import { 
    fetchPartnerListings, 
    getPartnerDetails, 
    getStagesAndCount,
    changeStatus
} from 'redux/actions/admin/partnerListings';

const initialState = {
    loading: false,
    error: false,
    data:[],
    partnerStages:[],
    partnerDetailsList:[],
    message: null,
  };

const partnerListingsSlice = createSlice({
    name:'partnerListing',
    initialState,
    reducers: {
      resetPartnerListing(state, action) {
        state.data = action.payload;
      },
     },
    extraReducers: (builder) => {
      //Get Partner List
      builder.addCase(fetchPartnerListings.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchPartnerListings.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = "";
      });
      builder.addCase(fetchPartnerListings.rejected, (state, action) => {
        state.loading = false;
        state.data = [];
        state.error = action.error.message;
      });

      //Get Partner by Id
      builder.addCase(getPartnerDetails.pending, (state) => {
        state.loading = true;
        state.partnerDetailsList = [];
      });
      builder.addCase(getPartnerDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.partnerDetailsList = action.payload;
        state.error = "";
      });
      builder.addCase(getPartnerDetails.rejected, (state, action) => {
        state.loading = false;
        state.partnerDetailsList = [];
        state.error = action.error.message;
      });

      //Get stages and count
      builder.addCase(getStagesAndCount.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getStagesAndCount.fulfilled, (state, action) => {
        state.loading = false;
        state.partnerStages = action.payload;
        state.error = "";
      });
      builder.addCase(getStagesAndCount.rejected, (state, action) => {
        state.loading = false;
        state.partnerStages = [];
        state.error = action.error.message;
      });

      //Change Status
      builder.addCase(changeStatus.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(changeStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = "";
      });
      builder.addCase(changeStatus.rejected, (state, action) => {
        state.loading = false;
        state.data = [];
        state.error = action.error.message;
      });

    }
})
export const { resetPartnerListing } = partnerListingsSlice.actions;
export default partnerListingsSlice.reducer