import { FormControl, TextField, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Controller } from "react-hook-form";
import { addErrorIntoField } from "utils";
import ErrorMessage from "Components/ErrorMessage";
import { useEffect, useState } from 'react';

const TextFields = ({ label, inputProps, control, helperText, name, errors, multiline, variant, rows, type, value, defaultValue, disable }) => {
  const [showPassword, setShowPassword] = useState(false);
  const isChromeOrEdge = navigator.userAgent.includes("Chrome") ; // Check if the browser is Chrome or Edge
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <FormControl fullWidth sx={{ mb: '1rem' }}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField 
            {...field} 
            {...addErrorIntoField(errors[name])}
            disabled={disable}
            defaultValue={defaultValue}
            type={(name === "password" || name === "confirmPassword" || name === "answer" || name === "oldPassword" || name === "newPassword") && !showPassword ? "password" : "text"}
            label={label}
            multiline={multiline || false}
            rows={rows || 5}
            variant={variant || "standard"}
            inputProps={inputProps} 
            helperText={helperText || ""}
            InputProps={{
              ...(isChromeOrEdge && {
                endAdornment: (name === "password" || name === "confirmPassword" || name === "answer" || name === "oldPassword" || name === "newPassword") && (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }),
            }}
          />
        )}
      />
      {errors[name] ? <ErrorMessage message={errors[name].message} /> : null}
    </FormControl>
  );
};

export default TextFields;
