import { createSlice } from '@reduxjs/toolkit'
import { registerUser, userLogin, logout, changePassword, 
  validateUserDetails, validateAdminDetails, adminLogin,
  forgotAdminPassword, changeAdminPassword, resetAdminPassword } from '../../actions/auth/authAction';
import jwtDecode from 'jwt-decode';
import Cookies from "universal-cookie";
import config from "../../../config";
const cookies = new Cookies();

// initialize userToken from local storage
// const userToken = localStorage.getItem('userToken')
//   ? localStorage.getItem('userToken')
//   : null

const initialState = {
  loading: false,
  userInfo: null,
  userData: null,
  error: null,
  success: false,
  isLoggedIn: false,
  isSignup: false,
  signupData: null,
  data: null
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetData: (state) => {
      //localStorage.removeItem('persist:root')
      state.loading = false
      state.userInfo = null
      state.userToken = null
      state.userData = null
      state.error = null
      state.isLoggedIn = null
      state.signupData = null
      state.data = null
    },
    setCredentials: (state, { payload }) => {
      state.userInfo = payload
    },
    cleanStorage: (state) => {
      cookies.remove('@clientAccessToken', { path: `${config.baseName}/` });
      cookies.remove('@clientRefreshToken', { path: `${config.baseName}/` });
      // localStorage.removeItem('persist:root');
      // sessionStorage.removeItem('formData');
      // localStorage.removeItem('accessToken');
      // localStorage.removeItem('refreshToken');
      localStorage.clear();
      sessionStorage.clear();
      state.userInfo = null
      state.userToken = null
      state.userData = null
    }
  },
  extraReducers: {
    // login user
    [userLogin.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.userInfo = payload
      state.userData = (!payload.data.error ? jwtDecode(payload.data?.accessToken) : payload.data)
      state.refreshToken = (!payload.data.error ? payload.data?.refreshToken : null)
      state.isLoggedIn = (!payload.data.error ? true : false)
      sessionStorage.removeItem('leadId');
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
      state.isLoggedIn = false
    },
    // admin login
    [adminLogin.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [adminLogin.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.userInfo = payload
      state.userData = (!payload.data.error ? jwtDecode(payload.data?.accessToken) : payload.data)
      state.refreshToken = (!payload.data.error ? payload.data?.refreshToken : null)
      state.isLoggedIn = (!payload.data.error ? true : false)
    },
    [adminLogin.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
      state.isLoggedIn = false
    },
    // register user
    [registerUser.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true // registration successful
      state.isSignup = payload.data.error ? false : true
      state.signupData = payload
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.loading = false
      state.isSignup = false
      state.error = payload
      state.signupData =null
    }, // validate user details
    [validateUserDetails.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [validateUserDetails.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true 
      state.data = payload.data
    },
    [validateUserDetails.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    }, //validate admin
    [validateAdminDetails.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [validateAdminDetails.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true 
      state.data = payload.data
    },
    [validateAdminDetails.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [forgotAdminPassword.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [forgotAdminPassword.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true 
      state.data = payload.data
    },
    [forgotAdminPassword.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [resetAdminPassword.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [resetAdminPassword.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true 
      state.data = payload.data
    },
    [resetAdminPassword.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [changePassword.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [changePassword.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true 
      state.data = payload.data
    },
    [changePassword.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [changeAdminPassword.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [changeAdminPassword.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true 
      state.data = payload.data
    },
    [changeAdminPassword.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [logout.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [logout.fulfilled]: (state, { payload }) => {
      cookies.remove('@clientAccessToken', { path: `${config.baseName}/` });
      cookies.remove('@clientRefreshToken', { path: `${config.baseName}/` });
      localStorage.removeItem('persist:root');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      sessionStorage.clear();
      state.userInfo = payload
      state.userData = null
      state.refreshToken = null
      state.isLoggedIn = false
      state.loading = false
      state.success = true 
    },
    [logout.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  },
 
})

export const { setCredentials, resetData, cleanStorage } = authSlice.actions

export default authSlice.reducer