import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import { combineReducers } from 'redux'
//import userReducer from './user/userSlice'
import partnerReducer from './reducers/partners/partnersSlice'

import authReducer from './reducers/auth/authSlice'
import onboardingReducer from './reducers/onboarding/onboardingSlice';
import partnerListingsReducer from './reducers/admin/partnerListingsSlice';
import jwtRefreshMiddleware from '../middleware/jwtRefreshMiddleware';
import homeFilterReducer from "./reducers/homeFilter";
import notesReducer from "./reducers/notes";
import clientReducer from './reducers/clientListing'

import storage from 'redux-persist/lib/storage';
//import storage from 'redux-persist/lib/storage/session';
import { persistReducer, persistStore, FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER } from 'redux-persist';
 import crosstabListener from '../utils/crosstabListner'
const persistConfig = {
    key: 'root',
    whitelist: ['auth','partner','partnerListing', 'getPartnerDetails', 'getStagesAndCount', 'client'],
    storage,
  }

  const combinedReducer = combineReducers({ 
    //user: userReducer,
    partner: partnerReducer,
    auth: authReducer,
    onboarding: onboardingReducer,
    homeFilter: homeFilterReducer,
    partnerListing: partnerListingsReducer,
    getPartnerDetails: partnerListingsReducer,
    getStagesAndCount: partnerListingsReducer,
    notes : notesReducer,
    client : clientReducer
  })
  
const persistedReducer = persistReducer(persistConfig, combinedReducer)
export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat([]),
    //middleware: (jwtRefreshMiddleware) => jwtRefreshMiddleware({}).concat([]),
})
// window.addEventListener('storage', crosstabListener(store, persistConfig));
export const persistor = persistStore(store);
