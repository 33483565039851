
import FromStepTwo from "View//FromStepTwo";
import {Grid} from "@mui/material";
import { useState, useEffect } from "react";
import OnboardMenu from "Components/OnboardMenu";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getPartner } from "redux/actions/onboarding/onboardingAction";
import Loader from "Components/Common/Loader";
import jwtDecode from "jwt-decode";

const StepTwo = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const partnerListingFetch = useSelector(
    (state) => state.partnerListing?.data?.lstAdmGetAllParnerResponses
  );
  const localData = localStorage.getItem("userToken");
  const tokenDecode = jwtDecode(localData)
  const partnerLeadIdLoc = tokenDecode?.sub?.leadId
  const partnerLeadIDSess = JSON.parse(sessionStorage.getItem('leadId'));
  const partnerDataSess = JSON.parse(sessionStorage.getItem('partnerData'));
  const partnerLeadID = partnerListingFetch?.filter((partner)=> partner.partnername == params.leadId)[0]?.id || partnerLeadIDSess ||partnerLeadIdLoc
  const { userData, isLoggedIn } = useSelector((state) => state.auth);
  const { partnerData } = useSelector((state) => state.onboarding);

  const getPartnerData = async () => {
    if(userData?.sub?.userTypeId) {
      await dispatch(getPartner(partnerLeadID));
    }
  }

  useEffect(() => { 
    // if(partnerData && ![6,11].includes(partnerData?.currentStageId) && userData?.sub?.userTypeId !== 3) {
    if((partnerData && ![6,11].includes(partnerData?.currentStageId) || partnerDataSess && ![6,11].includes(partnerDataSess?.sub?.currentStageId)) && userData?.sub?.userTypeId !== 3) {
      navigate('/details/'+partnerData?.overview?.leadsId)
    }
  }, [partnerData])

  useEffect(() => {
    getPartnerData();
  },[])

  return (
    <>
      {( partnerData && [4,5,6,7,8,9,11].includes(partnerData?.currentStageId) || userData?.sub?.userTypeId === 3) ? (
      <Grid container>
       <OnboardMenu/>
        <Grid item xs={12} md={10} lg={9}>
          <FromStepTwo partnerData={partnerData} />
        </Grid>
      </Grid>
      ) : (
        <Loader></Loader>
      )}
    </>
  );
};

export default StepTwo;
