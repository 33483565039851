import { Typography, Container, Grid, Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from 'react-redux';
import {useState, useEffect} from "react";

const TextButtonBanner = () => {
  const { isLoggedIn, userData } = useSelector((state) => state.auth)
  const [auth, setAuth] = useState(false);
  const cookies = new Cookies(window.document.cookie);
  const token = cookies.get("@clientAccessToken");

  useEffect(() => { 
    if(token) {
      setAuth(true);
    }
    else {
      setAuth(false);
    }
  }, [])

  useEffect(() => {
    if(userData && Object.keys(userData).length > 0){
      setAuth(true);
    }
    else {
      setAuth(false);
    }
    
  }, [userData])

  return (
    <Container maxWidth="lg">
      <Grid container justifyContent="space-between" mb={{xs:2, md:6}} mt={{xs:0, md:2, lg:4}} rowSpacing={{xs:3, md:1}}>
        <Grid item xs={12} md={5} display="flex" flexDirection="column" justifyContent="space-between" alignItems="baseline">
          <Box> 
          <Typography variant="h5"  component="p" gutterBottom>
            Interested in partnering with U.S. Bank?
          </Typography>
          <Typography gutterBottom mb={2} color="#555">
            If you’re a technology provider with software that makes sense for
            our clients, we’d like to hear from you.
          </Typography>
          </Box>
          <Button variant="contained" component={Link} to="/partner-with-us">
            Partner with us
          </Button>
        </Grid>

        <Grid item xs={12} md={5} display="flex" flexDirection="column" justifyContent="space-between" alignItems="baseline">
          <Box> 
          <Typography variant="h5" component="p" gutterBottom>
            Already a U.S. Bank partner?
          </Typography>
          <Typography gutterBottom mb={2} color="#555">
            Access your partner profile and service details.
          </Typography>
          </Box>
          <Button variant="outlined" component={Link} to={auth ? (userData?.sub?.userTypeId == 3 ? '/' : `/step-one/${userData?.sub?.partnerName}`) : '/login'}>
            Partner log in
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
export default TextButtonBanner;
