import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import { useForm } from 'react-hook-form'; 
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import TextFields from "Components/Common/TextFields";
import Loader from "Components/Common/Loader";
import { changePassword } from 'redux/actions/auth/authAction';
import AlertDialog from "Components/Common/AlertDialog";
import axios from "axios";
import config from "config";

// create schema validation
const validationSchema = Yup.object({
  password: Yup.string().min(
    8,
    'password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special'
  )
  .required("Password is required")
  .matches(/[0-9]/, 'Password requires a number')
  .matches(/[a-z]/, 'Password requires a lowercase letter')
  .matches(/[A-Z]/, 'Password requires an uppercase letter')
  .matches(/[^\w]/, 'Password requires a symbol'),
  confirmPassword: Yup
    .string()
    .oneOf([Yup.ref("password"), null], "Password must match"),
});

const formOptions = { 
  defaultValues: {
    password: "",
    confirmPassword:"",
  },
  resolver: yupResolver(validationSchema) 
};

const ChangePassword = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const { register, handleSubmit, formState, control, reset} = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const [secQues, setSecQues] = useState("");
  const [apiError, setApiError] = useState();
  const [loading, setLoading] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  useEffect(() => {
    securityQues();
  }, []);

  const securityQues = async () => {
    try {
      await axios
        .get(`${config.apiBase}/profile/getAllSecurityQuestions`)
        .then((res) => {
          setSecQues(res.data?.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const submitForm = (data) => {
    setLoading(true)
    const payload = {
      password: data.password,
      id: params.id
    };
    dispatch(changePassword(payload)).then((res) => {
      setLoading(false);
      console.log(res);
      if (!res.payload.error) {
        setApiError('')
        setIsPopUpOpen(true);
      } else {
        setApiError(res.payload.message);
      }
    })
  }
  return loading ? (
    <Loader />
  ) : (
    <Container maxWidth="lg">
      <Box noValidate component="form" onSubmit={handleSubmit(submitForm)}>
      <Grid container spacing={2} alignItems="center" py={3}>
        <Grid item md={6}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Typography variant="h3" component="h1" color="primary.darker">
                Change Password
              </Typography>
            </Grid>
            <Grid item xs={10}>
              { apiError && (
                  <Grid item xs={12}>
                    <Alert variant="filled" severity="error">{apiError}</Alert>
                  </Grid>  
                )}
            </Grid>
            <Grid item md={10}>
                  <TextFields
                    type="password"
                    label="Password *"
                    name="password"
                    errors={errors}
                    control={control}
                  />
                </Grid>
                <Grid item md={10}>
                  <TextFields
                    type="password"
                    label="Confirm password *"
                    name="confirmPassword"
                    errors={errors}
                    control={control}
                  />
                </Grid>

            <Grid item xs={12}>
              <Grid item md={8} xs={8} lg={10} my={1}>
                <Button variant="contained" color="primary" type="submit" fullWidth>
                  Change Password
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </Box>
      {isPopUpOpen && (
          <AlertDialog
            message="Your password has been successfully changed."
            open={isPopUpOpen}
            showCloseIcon={false}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          </AlertDialog>
        )}
    </Container>
  );
};
export default ChangePassword;
