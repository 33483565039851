import { useEffect } from "react";
import { Grid, Typography, Box, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
//import { getClientById } from "redux/actions/clientListing";

const ClientContactInformation = () => {
  const params = useParams();
  const viewId = params.viewId;
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.client);
  const clientData = data?.data;

  // useEffect(() => {
  //   dispatch(getClientById(viewId));
  // }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Grid item xs={12} md={9} px={2}>
              <Typography variant="h4" component="h2" sx={{ mt: 3 }}>
                Contact information
              </Typography>
              {loading ? (
                <Grid item xs={12} p={2} align="center">
                  {" "}
                  <CircularProgress />{" "}
                </Grid>
              ) : (
                <Grid item xs={12} md={12}>
                  <Box display="flex" width={1} border="1px solid #828282">
                    <Box width="40%" p={2}>
                      <Typography color="textSecondary"> Full name </Typography>
                    </Box>
                    <Box
                      width="100%"
                      p={2}
                      bgcolor="#F7F7FA"
                      borderLeft="1px solid #828282"
                    >
                      <Typography color="textSecondary">
                        {" "}
                        {clientData?.[0]?.fullname || ""}{" "}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    width={1}
                    border="1px solid #828282"
                    style={{ borderTop: "none" }}
                  >
                    <Box width="40%" p={2}>
                      <Typography color="textSecondary">
                        {" "}
                        Company name{" "}
                      </Typography>
                    </Box>
                    <Box
                      width="100%"
                      p={2}
                      bgcolor="#F7F7FA"
                      borderLeft="1px solid #828282"
                    >
                      <Typography color="textSecondary">
                        {" "}
                        {clientData?.[0]?.company || ""}{" "}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    width={1}
                    border="1px solid #828282"
                    style={{ borderTop: "none" }}
                  >
                    <Box width="40%" p={2}>
                      <Typography color="textSecondary"> Website </Typography>
                    </Box>
                    <Box
                      width="100%"
                      p={2}
                      bgcolor="#F7F7FA"
                      borderLeft="1px solid #828282"
                    >
                      <Typography color="textSecondary">
                        {" "}
                        {clientData?.[0]?.website || ""}{" "}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    width={1}
                    border="1px solid #828282"
                    style={{ borderTop: "none" }}
                  >
                    <Box width="40%" p={2}>
                      <Typography color="textSecondary"> Email </Typography>
                    </Box>
                    <Box
                      width="100%"
                      p={2}
                      bgcolor="#F7F7FA"
                      borderLeft="1px solid #828282"
                    >
                      <Typography color="textSecondary">
                        {" "}
                        {clientData?.[0]?.email || ""}{" "}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    width={1}
                    border="1px solid #828282"
                    style={{ borderTop: "none" }}
                  >
                    <Box width="40%" p={2}>
                      <Typography color="textSecondary"> Phone </Typography>
                    </Box>
                    <Box
                      width="100%"
                      p={2}
                      bgcolor="#F7F7FA"
                      borderLeft="1px solid #828282"
                    >
                      <Typography color="textSecondary">
                        {" "}
                        {clientData?.[0]?.phone || ""}{" "}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    width={1}
                    border="1px solid #828282"
                    style={{ borderTop: "none" }}
                  >
                    <Box width="40%" p={2}>
                      <Typography color="textSecondary">
                        {" "}
                        Company role{" "}
                      </Typography>
                    </Box>
                    <Box
                      width="100%"
                      p={2}
                      bgcolor="#F7F7FA"
                      borderLeft="1px solid #828282"
                    >
                      <Typography color="textSecondary">
                        {clientData?.[0]?.companyRole || ""}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    width={1}
                    border="1px solid #828282"
                    style={{ borderTop: "none" }}
                  >
                    <Box width="40%" p={2}>
                      <Typography color="textSecondary">
                        Headquarters location
                      </Typography>
                    </Box>
                    <Box
                      width="100%"
                      p={2}
                      bgcolor="#F7F7FA"
                      borderLeft="1px solid #828282"
                    >
                      <Typography color="textSecondary">
                        {clientData?.[0]?.city !== null ? clientData?.[0]?.headquaterLocation + ', ' + clientData?.[0]?.city || "" : clientData?.[0]?.headquaterLocation }
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    width={1}
                    border="1px solid #828282"
                    style={{ borderTop: "none" }}
                  >
                    <Box width="40%" p={2}>
                      <Typography color="textSecondary">ERP Used</Typography>
                    </Box>
                    <Box
                      width="100%"
                      p={2}
                      bgcolor="#F7F7FA"
                      borderLeft="1px solid #828282"
                    >
                      <Typography color="textSecondary">
                        {clientData?.[0]?.erpName || ""}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              )}
              { clientData?.[0]?.additionalInformation && 
              <Grid item xs={12} md={12} pt={4}>
                <Typography
                  color="textPrimary"
                  fontWeight="600"
                  gutterBottom
                  sx={{ mb: 2 }}
                >
                  {" "}
                  Client description{" "}
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="body1"
                  gutterBottom
                  sx={{ mb: 2 }}
                >
                  {" "}
                  {clientData?.[0]?.additionalInformation}
                </Typography>
              </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ClientContactInformation;
