import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import { useForm } from 'react-hook-form'; 
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { phoneRegExp, emailRegExp } from "utils";
import TextFields from "Components/Common/TextFields";
import Loader from "Components/Common/Loader";
import { validateUserDetails } from 'redux/actions/auth/authAction';
import SelectFields from "Components/Common/SelectFields";
import axios from "axios";
import config from "config";

// create schema validation
const validationSchema = Yup.object({
  email: Yup
    .string()
    .required("Email is required")
    .matches(emailRegExp, "Email is not valid"),
  questionId: Yup.string().required("Security Question is required"),
  answer: Yup.string().required("Security Answer is required").min(1),
});

const formOptions = { 
  defaultValues: {
    email: "",
    questionId:"",
    answer: ""
  },
  resolver: yupResolver(validationSchema) 
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const { register, handleSubmit, formState, control, reset} = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const [secQues, setSecQues] = useState("");
  const [apiError, setApiError] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    securityQues();
  }, []);

  const securityQues = async () => {
    try {
      await axios
        .get(`${config.apiBase}/profile/getAllSecurityQuestions`)
        .then((res) => {
          setSecQues(res.data?.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const submitForm = (data) => {
    setLoading(true)
    dispatch(validateUserDetails(data)).then((res) => {
      setLoading(false);
      console.log(res);
      if (!res.payload.error) {
        navigate(`/forgot-password/${res.payload.data.id}`);
      } else {
        setApiError(res.payload.message);
      }
    })
  }
  return loading ? (
    <Loader />
  ) : (
    <Container maxWidth="lg">
      <Box noValidate component="form" onSubmit={handleSubmit(submitForm)}>
      <Grid container spacing={2} alignItems="center" py={3}>
        <Grid item md={6}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Typography variant="h3" component="h1" color="primary.darker">
                Security Check
              </Typography>
            </Grid>
            <Grid item xs={10}>
              { apiError && (
                  <Grid item xs={12}>
                    <Alert variant="filled" severity="error">{apiError}</Alert>
                  </Grid>  
                )}
            </Grid>
            <Grid item xs={12} sm={9} md={10}>
              {/* <TextField label="Username" variant="standard" fullWidth /> */}
              <TextFields errors={errors} control={control} label="Email" name="email" />
            </Grid>

            <Grid item xs={12} sm={9} md={10}>
                  <SelectFields
                    errors={errors}
                    control={control}
                    name="questionId"
                    label="Security Question"
                    list={secQues}
                    keyName="question"
                  />
                </Grid>

                <Grid item xs={12} sm={9} md={10}>
                  <TextFields
                    label="Security Question answer *"
                    name="answer"
                    errors={errors}
                    control={control}
                  />
                </Grid>

            <Grid item xs={12}>
              <Grid item xs={5} md={8} lg={10} my={1}>
                <Button variant="contained" color="primary" type="submit" fullWidth>
                  Confirm details
                </Button>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} xs={12} sm={9} md={10}>
              <Grid item md={4} xs={6} lg={10} my={1}>
                <Typography color="textSecondary">
                  Not a Partner yet ?{" "}
                  <Link
                    aria-label="Not a Partner yet ?  Sign up now !"
                    variant="body1"
                    onClick={() => {
                      navigate("/partner-with-us");
                    }}
                    component="button"
                  >
                    Partner with us
                  </Link>{" "}
                  now !
                </Typography>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
      </Box>
    </Container>
  );
};
export default ForgotPassword;
