import axios from 'axios'
import Cookies from "universal-cookie";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAccessToken } from "helper/user";
import config from "config";

let cookies = new Cookies(window.document.cookie);

export const stepOneSubmit = createAsyncThunk(
    'partner/step-1',
    async (reqPayload, { rejectWithValue }) => {
        
      try {
        const accessToken = await getAccessToken();
        const headers = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
                Pragma: 'no-cache'
            },
        }
        const response = await axios.post(`${config.apiBase}/partner/step-1`, reqPayload, headers);
        return await response.data;
      } catch (error) {
      // return custom error message from backend if present
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )

  export const stepTwoSubmit = createAsyncThunk(
    'partner/step-2',
    async (reqPayload, { rejectWithValue }) => {
        
      try {
        const accessToken = await getAccessToken();
        const headers = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
                Pragma: 'no-cache'
            },
        }
        const response = await axios.post(`${config.apiBase}/partner/step-2`, reqPayload, headers);
        return await response.data;
      } catch (error) {
      // return custom error message from backend if present
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )


  export const getPartner = createAsyncThunk(
    'dashboard/partner',
    async (reqPayload, { rejectWithValue }) => {
        
      try {
        const accessToken = await getAccessToken();
        const headers = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
                Pragma: 'no-cache'
            },
        }
        const response = await axios.get(`${config.apiBase}/dashboard/partner/${reqPayload}`, headers)
        return response.data
      } catch (error) {
      // return custom error message from backend if present
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )