import { createSlice } from "@reduxjs/toolkit";
import {
  getNotes,
  updateNotes,
  createNotes,
 } from "redux/actions/notes";


 const initialState = {
  loading: false,
  error: false,
  data:[],
  message: null,
};

const notesSlice = createSlice({
    name:'notes',
    initialState,
    reducers: { 
      setNote(state, action) {
        const {index, val} = action.payload;
        state.data.data[index].note = val;
      },
    },
    extraReducers: (builder) => {
      //Get Notes
      builder.addCase(getNotes.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getNotes.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = "";
      });
      builder.addCase(getNotes.rejected, (state, action) => {
        state.loading = false;
        state.data = [];
        state.error = action.error.message;
      });

      // Create Notes
      // builder.addCase('CREATE_NOTES', (state, action) => {
      //   state.push(action.payload);
      // })
      
      builder.addCase(createNotes.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(createNotes.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = "";
      });
      builder.addCase(createNotes.rejected, (state, action) => {
        state.loading = false;
        state.data = [];
        state.error = action.error.message;
      });

      // update Note
      builder.addCase(updateNotes.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(updateNotes.fulfilled, (state, action) => {
        state.loading = false;
        state.update = action.payload;
        state.error = "";
      });
      builder.addCase(updateNotes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
     
    }
})

export const {setNote} = notesSlice.actions;
export default notesSlice.reducer;