import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from "config";

const initialState = {
  loading: false,
  data: [],
  errors: false,
  message: null,
  stepOneLogoUrl: "",
  stepTwoScreenShotUrl: "",
  homeFilter: {
    applicationIds: [],
    businessSizeIds: [],
    erpSupportedIds: [],
    industryIds: [],
    rowCount: 6,
    pageNo: 1,
  },
  statusList: []
};

const removeEmptyValues = (object) => {
  for (let key in object) {
    if (object.hasOwnProperty(key)) {
      let value = object[key];
      if (value === null || value === undefined || value === "") {
        delete object[key];
      }
    }
  }
};

export const fetchPartners = createAsyncThunk(
  "fetchPartners",
  async (params, { rejectWithValue }) => {
    try {
      removeEmptyValues(params);
      let queryString;
      if(params) {
        queryString = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      }
      
      const response = await axios.get(
        `${config.apiBase}/dashboard/partners?${queryString}`
      );

      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const partnersSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {
    storeLogoUrl(state, action) {
      state.stepOneLogoUrl = action.payload;
    },
    storeScreenShotUrl(state, action) {
      state.stepTwoScreenShotUrl = action.payload;
    },
    storeHomeFilter(state, action) {
      state.homeFilter = action.payload;
    },
    resetHomeFilter(state, action) {
      state.homeFilter = {
        applicationIds: [],
        businessSizeIds: [],
        erpSupportedIds: [],
        industryIds: [],
        rowCount: 6,
        pageNo: 1,
      }
    },
    setStatusList(state, action) {
      state.statusList = action.payload;
    },
    resetStatusList(state, action) {
      state.statusList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPartners.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchPartners.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.errors = "";
    });
    builder.addCase(fetchPartners.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.errors = action.error.message;
    });
  },
});

export const { storeLogoUrl, storeScreenShotUrl, storeHomeFilter, resetHomeFilter, setStatusList, resetStatusList } = partnersSlice.actions;
export default partnersSlice.reducer;