import { useEffect, useState } from "react";
import { Typography, Container, Grid, Box, Button,Alert, FormControl, MenuItem, TextField } from "@mui/material";
import CustomBreadcrumbs from "Components/Common/CustomBreadcrumbs";
import Loader from "Components/Common/Loader";
import axios from "axios";
import config from "config";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getHomeFilter } from "redux/actions/homeFilter";
import TextFields from "Components/Common/TextFields";
import CheckboxFields from "Components/CheckboxFields";
import CustomSelectFields from "Components/Common/CustomSelectFields";
import SelectFields from "Components/Common/SelectFields";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { phoneRegExp, webRegExp, emailRegExp, alphabetExp, alphaNumericExp } from "utils";
import { addErrorIntoField } from "utils";
import ErrorMessage from "Components/ErrorMessage";
import { nanoid } from "nanoid";
import jwtDecode from "jwt-decode";


// create schema validation
const schema = yup.object({
  fullName: yup.string().required("Full Name is required")
    .matches(alphabetExp, 'Full name is not valid.'),
  email: yup
    .string()
    .required("Email address is required")
    .matches(emailRegExp, "Email address is not valid")
    .min(3, "must be at least 3 characters long"),
  companyName: yup.string().required("Company name is required"),
    // .matches(alphabetExp, 'Company name is not valid.'),
  website: yup.string().nullable().notRequired()
      .test(
        'validWebsite',
        'Website is not valid.',
        (value)=> {
          return !value ? true : webRegExp.test(value) }
      ),
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Phone number must be at least 10 characters")
    .max(20, "Phone number must be at least 10 characters"),
  companyRole: yup.string(),
  headquarter: yup.string(),
  message: yup.string()
   .matches(alphaNumericExp, 'Message is not valid.'),
  erpUse: yup.string(),
  //privacy: yup.bool().oneOf([true], "Field must be checked"),
});

const ConnectWithUs = () => {
  const {
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
      companyName: "",
      phoneNumber: "",
      website: "",
      companyRole: "",
      headquarter: "",
      message: "",
      erpUse: "",
      //privacy: false,
    },
    resolver: yupResolver(schema),
  });
  const params = useParams();
  const partnerListingFetch = useSelector(
    (state) => state.partnerListing?.data?.lstAdmGetAllParnerResponses
  );
  const { data } = useSelector((state) => state.partner);
  // const partnerLeadID = partnerListingFetch ? partnerListingFetch?.filter((partner)=> partner.partnername == params.detailsId)[0]?.id : data.partners?.filter((partner)=> partner.companyName == params.detailsId)[0]?.leadsId
  // const localData = localStorage.getItem("userToken");
  // const tokenDecode = jwtDecode(localData)
  // const partnerLeadIdLoc = tokenDecode?.sub?.leadId
  const partnerLeadIDSess = JSON.parse(sessionStorage.getItem('partnerData'))?.sub.leadId;
  const partnerLeadID = data.partners?.filter((partner)=> partner.companyName == params?.pId)[0]?.leadsId || partnerListingFetch?.filter((partner)=> partner.partnername == params.detailsId)[0]?.id || partnerLeadIDSess
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listHeadquarter, setListHeadquarter] = useState([]);
  const [companyRole, setCompanyRole] = useState([]);
  const [apiError, setApiError] = useState("");
  const [eprFilter, setEprFilter] = useState([]);
  const [isHasValue, setIsHasValue] = useState(false);
  const [cities, setCities] = useState(null);
  const [stateCityName, setStateCityName] =useState(null);
  const [stateName, setSelectedStateName] =useState(null);
  const headquarterList = listHeadquarter.map((item) => item).sort();
  const companyRoleList = companyRole.map((item) => item).sort();
  const listItemCities = cities?.map((item) => item).sort();

  const filterErpData = useSelector(
    (state) => state.homeFilter?.data?.data?.erpSupported
  );

  const getEprFilter = () => {
    dispatch(getHomeFilter());
  };

  const adobeUtag = () => {
    const partName = params?.pId ? params.pId.replace(/[^a-zA-Z0-9]/g,' ').replace(/\s\s+/g, ' ') : null;

    const reportingData = {
      currentPage : partName ? "usb:partner directory:"+ partName.toLowerCase() +":contact us:thank you" : "usb:partner directory:contact us:thank you",
      siteSection : "form",
      subSiteSection : "partner directory",
      sitePlatform : "dotcom",
      language: "en-us",
      datasource : "pageView",
      transactionStatus : "form submit",
      formName : partName ? "usbank partner:"+ partName.toLowerCase() +" contact us form" : "usbank partner directory contact us form"
    };
    
    if(window.publisherFW){
      console.log("ContactUs Submit ==>", reportingData);
      window.publisherFW.publishEvent("pageView", reportingData); //call this function after the data layer object
    }
    
  }

  useEffect(() => {
    getCompanyRole();
    getListHeadquarter();
    getEprFilter();
  }, []);

  useEffect(() => {
    setEprFilter(filterErpData);
  }, [filterErpData]);

  const handleCancel = () => {
    navigate("/");
  };

  const getListHeadquarter = async () => {
    try {
      await axios.get(`${config.apiBase}/dashboard/regions`).then((res) => {
        setListHeadquarter(res.data?.data);
      });
    } catch (error) {
      console.error("ERR:", error);
    }
  };

  const getCompanyRole = async () => {
    try {
      await axios
        .get(`${config.apiBase}/dashboard/company-roles`)
        .then((res) => {
          setCompanyRole(res.data?.data);
        });
    } catch (error) {
      console.error("ERR:", error);
    }
  };
  const getLisCities = async (id) => {
    try {
      await axios.get(`${config.apiBase}/dashboard/cities/${id}`).then((res) => {
        setCities(res.data?.data);
      });
    } catch (error) {
      console.log("ERR:", error);
    }
  };

  const setStateDropDownData = (val) => {
    getLisCities(val);
    setValue('headquarter',val);
    setValue('city','');
    const name = listHeadquarter.filter(item => item.id === val)[0].name;
    setSelectedStateName(name);
    val == '' ? setIsHasValue(false) : setIsHasValue(true);
  }

  const setCityDropDownData = (val) => {
    const name = cities.filter(item => item.id === val)[0].name;
    setValue('city',val);
    if (val) {
      clearErrors('city');
    } else {
      setError('city', { type: "focus", message: `City is required` }, { shouldFocus: true });
    }
    setStateCityName(name+ ', '+stateName);
    setValue('Headquarterlocation',name+ ', '+stateName);
  }

  const postContactUs = async (data) => {
    setLoading(true);
    try {
      await axios
        .post(`${config.apiBase}/dashboard/create-lead`, {
          fullName: data.fullName || "",
          email: data.email || "",
          message: data.message || "",
          phoneNumber: data.phoneNumber || "",
          organization: data.companyName || "",
          website: data.website || "",
          leadTypeId: 2,
          organizationRoleId: data.companyRole || "",
          headquarterLocationId: data.headquarter || "",
          erpName: data.erpUse || "",
          partnerId: partnerLeadID,
        })
        .then((res) => {
          setLoading(false);
          if (res.data.error) {
            setApiError(res.data.message);
          } else {
            adobeUtag();
            navigate("/thank-you");
          }
        });
    } catch (error) {
      setLoading(false);
      console.error("ERR:", error);
    }
  };

  const onSubmit = (data) => {
    //console.log(data);
    postContactUs(data);
    // dispatch(createLead(data));
    reset();
  };


  return loading ? (
    <Loader />
  ) : (
    <Container maxWidth="lg">
      <CustomBreadcrumbs currentLink="Contact us" />
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h3" component="h1" color="primary.darker" mb={2}>
            Contact us
          </Typography>
          <Typography color="textPrimary" gutterBottom>
            Complete form to learn about U.S. Bank connectivity
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={6} lg={5}>
        { apiError && (
                <Grid item xs={12}>
                  <Alert variant="filled" severity="error">{apiError}</Alert>
                </Grid>  
              )}
          <Box noValidate component="form" mt={{xs:0, lg:4}} onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              spacing={2}
              alignItems={{ xs: "normal", lg: "center" }}
            >
              <Grid item xs={12}>
                <TextFields
                  errors={errors}
                  control={control}
                  name="fullName"
                  label="Full name *"
                  inputProps={{ maxLength: 60 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFields
                  errors={errors}
                  control={control}
                  name="email"
                  label="Email address *"
                  inputProps={{ maxLength: 60 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFields
                  errors={errors}
                  control={control}
                  name="companyName"
                  label="Company name *"
                  inputProps={{ maxLength: 60 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFields
                  errors={errors}
                  control={control}
                  name="phoneNumber"
                  label="Phone number *"
                  inputProps={{ maxLength: 20 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFields
                  errors={errors}
                  control={control}
                  name="website"
                  label="Website (Optional)"
                  inputProps={{ maxLength: 40 }}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectFields
                  errors={errors}
                  control={control}
                  name="companyRole"
                  label="Company role (Optional)"
                  list={companyRoleList}
                />{" "}
              </Grid>
              <Grid item xs={12}>
                  <CustomSelectFields
                    errors={errors}
                    control={control}
                    name="headquarter"
                    label="State (Optional)"
                    list={headquarterList} 
                    onSelect={(vals) => setStateDropDownData(vals)}
                    />
                </Grid>
              {isHasValue && 
                <Grid item xs={12}>
                  <CustomSelectFields
                    errors={errors}
                    control={control}
                    name="city"
                    label="City *"
                    list={listItemCities}
                    onSelect={(val) => setCityDropDownData(val)}
                  />
                </Grid>
              }

                <Grid item xs={12} mb="24px">
                  <TextField
                    name="Headquarterlocation"
                    label="Headquarter location"
                    value={stateCityName || ""}
                    variant="standard"
                    fullWidth
                    inputProps={
                      {shrink: true }
                    }
                    disabled
                  />
                </Grid>
              {/* <Grid item xs={12}>
                <SelectFields
                  errors={errors}
                  control={control}
                  name="headquarter"
                  label="Headquarter location (Optional)"
                  list={headquarterList}
                />
              </Grid> */}

              <Grid item xs={12}>
                {/* <SelectFields
                  errors={errors}
                  control={control}
                  name="erpUse"
                  label="What ERP do you use? (Optional)"
                  list={filterErpData}
                /> */}

<FormControl fullWidth sx={{ mb: '1rem' }}>
      <Controller
        name="erpUse"
        control={control}
        render={({ field }) => (
          <TextField {...addErrorIntoField(errors["erpUse"])} {...field}  
          select label="What ERP do you use? (Optional)"
          variant="standard">
            {filterErpData && filterErpData.map(item => (
              <MenuItem key={nanoid(3)} value={item.name}>{item.name}</MenuItem>
            ))}
          </TextField>
        )}
      />
      {errors["erpUse"] ? <ErrorMessage message={errors["erpUse"].message} /> : null}

    </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextFields
                  errors={errors}
                  control={control}
                  name="message"
                  label="Message (Optional)"
                  multiline={true}
                  variant="outlined"
                  inputProps={{ maxLength: 500 }}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <CheckboxFields
                  errors={errors}
                  control={control}
                  name="privacy"
                  label="By checking this box, you agree to receive marketing communications about US Bank business products and services."
                />
              </Grid> */}
            </Grid>
            <Box py={4} display="flex">
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item xs={5} md={4}>
                  <Button variant="outlined" fullWidth onClick={handleCancel}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={5} md={4}>
                  <Button variant="contained" type="submit" fullWidth>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
export default ConnectWithUs;
