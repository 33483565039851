import FromStepOne from "View/FromStepOne";
import { useState, useEffect } from "react";
import axios from "axios";
import { Grid,Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { storeLogoUrl } from "redux/reducers/partners/partnersSlice";
import {
  getPartner,
} from "redux/actions/onboarding/onboardingAction";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "Components/Common/Loader";
import _ from "lodash";
import { getAccessToken } from "helper/user";
import OnboardMenu from "Components/OnboardMenu";
import config from "config";
import {logout} from "redux/actions/auth/authAction"
import jwtDecode from "jwt-decode";

const initialState = {
  companyName: "",
  productName: "",
  website: "",
  briefDescription: "",
  apisAvailable: [],
  connectivityChannels: [],
  industryserviced: [],
  businessServiced: [],
  appCategories: [],
  primaryAppCategory: [],
  erpsSupported: [],
  leadId: null,
};
const StepOne = () => {
  const navigate = useNavigate();
  const params = useParams();
  console.log(params)
  const partnerListingFetch = useSelector(
    (state) => state.partnerListing?.data?.lstAdmGetAllParnerResponses
  );
  const localData = localStorage.getItem("userToken");
  const tokenDecode = jwtDecode(localData)
  const partnerLeadIdLoc = tokenDecode?.sub?.leadId
  const partnerLeadIDSess = JSON.parse(sessionStorage.getItem('leadId'));
  const partnerDataSess = JSON.parse(sessionStorage.getItem('partnerData'));
  console.log(partnerLeadIDSess,partnerLeadIdLoc)
  const partnerLeadID = partnerListingFetch?.filter((partner)=> partner.partnername == params.leadId)[0]?.id || partnerLeadIDSess || partnerLeadIdLoc
  const dispatch = useDispatch();
  const [dData, setDData] = useState(initialState);
  const { userData, isLoggedIn } = useSelector((state) => state.auth);
  const { partnerData } = useSelector((state) => state.onboarding);
  const [primaryCategory, setPrimaryCategory] = useState([]);
  const [pageLoader, setPageLoader] = useState(true);
  const [filterData, setFilterData] = useState(null);

  const getPartnerData = async () => {
    if(userData?.sub?.userTypeId) {
      await dispatch(getPartner(partnerLeadID));
    }
    // if(userData?.sub?.userTypeId && userData?.sub?.userTypeId === 3){
    //   navigate('/');
    // }
    // else {
    //   dispatch(logout());
    // }
  }

  useEffect(() => { 
    if((partnerData && ![6,11].includes(partnerData?.currentStageId) || partnerDataSess && ![6,11].includes(partnerDataSess?.sub?.currentStageId)) && userData?.sub?.userTypeId !== 3) {
      navigate('/details/'+params.leadId)
    }
  }, [partnerData])

  const getFilerData = async () => {
    try {
      const accessToken = await getAccessToken();
        const headers = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
                Pragma: 'no-cache'
            },
        }
      await axios.get(`${config.apiBase}/filter/getallfilters`, headers).then((res) => {
      
        setFilterData(res?.data?.data);
        
      });
    } catch (error) {
      setPageLoader(false)
      console.log(error.message);
    }
  };

 
  const apisAvailableList = filterData?.apisAvailable
    ?.map((item) => item)
    .sort();

  const appCatList = filterData?.applicationCategories
    ?.map((item) => item)
    .sort();
  const businessSizeList = filterData?.businessSize?.map((item) => item).sort();
  const connectivityList = filterData?.connectivityChannels
    ?.map((item) => item)
    .sort();
  const erpSupportedList = filterData?.erpSupported?.map((item) => item).sort();
  const industryTypeList = filterData?.industryType?.map((item) => item).sort();

  useEffect(() => { 
    getFilerData();
    getPartnerData();
      if(partnerData && Object.keys(partnerData).length > 0) {
        const serviceDesc = partnerData.serviceDetails;
        setDData((values) => ({ ...values, ['apisAvailable']: serviceDesc.api.map((item) => item.name)//
         }));
        setDData((values) => ({ ...values, ['appCategories']: serviceDesc.application.map((item) => item.name)}));
        setDData((values) => ({ ...values, ['businessServiced']: serviceDesc.businessSize.map((item) => item.name)//
        }));
        setDData((values) => ({ ...values, ['connectivityChannels']: serviceDesc.connectivityChannel.map((item) => item.name)//
        }));
        setDData((values) => ({ ...values, ['erpsSupported']: serviceDesc.erpSupported.map((item) => item.name)//
        }));
        setDData((values) => ({ ...values, ['industryserviced']: serviceDesc.industry.map((item) => item.name) //
        }));
        // setDData((values) => ({ ...values, ['primaryAppCategory']: serviceDesc.solution.map((item) => item.name)
        // }));
        //setPrimaryCategory(serviceDesc.application);
        for (const key in partnerData.overview) {
          setDData((values) => ({ ...values, [key]: partnerData.overview[key] }));
        } 
        setDData((values) => ({ ...values, ['primaryApplication']: [partnerData.overview['primaryApplication']] }));
      }
      // setTimeout(()=>{
      //   setPageLoader(false);
      // },1000)
      
  }, []);

  return (
    <>
      {( (partnerData && [4,5,6,7,8,9,11].includes(partnerData?.currentStageId) || userData?.sub?.userTypeId === 3) && filterData) ? (
      <Grid container>
       <OnboardMenu/>
        <Grid item xs={12} md={9}>
            <FromStepOne 
            apisAvailableList={apisAvailableList}
            appCatList={appCatList}
            businessSizeList={businessSizeList}
            connectivityList={connectivityList}
            erpSupportedList={erpSupportedList}
            industryTypeList={industryTypeList}
            priCategories={primaryCategory}
            formData={dData}
            userLeadId={userData?.sub?.leadId || partnerLeadID}/>
          </Grid>
        
      </Grid>
      ) : (
        <Loader></Loader>
      )}
    </>
  );
};

export default StepOne;
