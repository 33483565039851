import {Grid, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useLocation } from "react-router-dom";
import stepOneBg from 'Assets/Images/step1-bg.png'
import stepTwoBg from 'Assets/Images/step2-bg.png'

const OnboardMenu = () => {
  const location = useLocation();
  return (
   
        <Grid item xs={3} xl={2} 
        sx={{ display: { xs: 'none', lg: 'block' } }}
        style={{
          background: location.pathname.includes('/step-one') ? 
        `url(${stepOneBg}) no-repeat bottom center #F0F3FF`:
        location.pathname.includes('/step-two') ? `url(${stepTwoBg}) no-repeat bottom center #F0F3FF`: '',
        backgroundSize:'contain',
        }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item lg={10}>
              <Typography variant="h6" mt={3}>
                {" "}
                <CheckCircleIcon fontSize="small" color={location.pathname.includes('/step-two') ? 'success': "disabled"} /> Partner
                Information
              </Typography>
            </Grid>
            <Grid item lg={10} ml={7}>
              <Typography color="textSecondary"> Logo</Typography>
              <Typography color="textSecondary" mt={2}>
                {" "}
                Partner Information
              </Typography>
              <Typography color="textSecondary" mt={2}>
                {" "}
                Type of Service
              </Typography>
            </Grid>
            <Grid item lg={10}>
              <Typography variant="h6" mt={6}>
                {" "}
                <CheckCircleIcon fontSize="small" color="disabled" /> Service
                Description
              </Typography>
            </Grid>
            <Grid item lg={10} ml={7}>
              <Typography color="textSecondary"> Description</Typography>
              <Typography color="textSecondary" mt={2}>
                {" "}
                Keypoints
              </Typography>
              <Typography color="textSecondary" mt={2}>
                {" "}
                Application screenshots
              </Typography>
            </Grid>
          </Grid>
        </Grid>
     );
};

export default OnboardMenu;
