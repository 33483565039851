
import {
  Box,
  Grid,
  Typography,
  Button,
  CardMedia,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { nanoid } from "nanoid";

export const PartnersCard = ({
  loading,
  partnerListCard,

}) => {
 


  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignContent="center" alignItems="center" 
      width={1} style={{position:'relative', top:'200px'}}>
        {" "}
        <CircularProgress />{" "}
      </Box>
    );
  }

  return partnerListCard?.partners?.map((list) => {
    return (
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={nanoid(10)}>
        <Link
          to={`details/${list?.companyName}`}
          style={{
            textDecoration: "none",
            color: "inherit",
            paddingBottom: "16px",
            boxSizing: "border-box",
            display: "flex",
            height: "100%",
          }}
        >
          <Box
            width="100%"
            border="1px solid #CDCDD3"
            p={2}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            borderRadius={1.5}
          >
            <Box>
              <Box textAlign="center">
                <CardMedia
                  component="img"
                  image={list?.logoUri}
                  alt={list?.logoDescription}
                  sx={{ height: 'auto', width: "200px", objectFit:'contain', mx: "auto", pb: 2, pt: 1 }}
                />
              </Box>
              <Typography
                color="textPrimary"
                textTransform="uppercase"
                gutterBottom
              >
                {list?.application}
              </Typography>
              {/* <Typography variant="h4" component="p" fontSize={24} mb={2}>
                {list?.productName}
              </Typography> */}
              <Typography color="#555">{list?.shortDescription}</Typography>
            </Box>
            <Box mt={2} color="primary" textAlign="left">
              <Button
                variant="text"
                aria-label="Overview"
                endIcon={<KeyboardArrowRightIcon />}
               style={{padding:'12px 0px'}}
              >
                Overview
              </Button>
            </Box>
          </Box>
        </Link>
      </Grid>
    );
  });
};
