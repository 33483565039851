import { useDispatch } from 'react-redux';
import { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Link,
} from "@mui/material";
import { Outlet, useNavigate, Navigate } from 'react-router-dom';
import Cookies from "universal-cookie";
import { logout } from 'redux/actions/auth/authAction';

const ProtectedRoute = ({isAdmin}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  let cookies = new Cookies(window.document.cookie);
  const accessToken = cookies.get("@clientAccessToken");
  if (accessToken === undefined) {
    navigate('/');
  }

  if(isAdmin && isAdmin !== 3) {
    <Navigate to="/admin/partner-listings" />
  }

  return accessToken 
    ? <Outlet />
    : <Navigate to="/" />;
}

export default ProtectedRoute;