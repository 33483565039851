import { FormControl, TextField, Typography } from "@mui/material"
import { Controller } from "react-hook-form";
import { addErrorIntoField } from "utils";
import ErrorMessage from "Components/ErrorMessage";
import { useEffect, useState } from 'react';

const TextFieldAuto = ({ label, inputProps, control, name, errors, multiline, variant, rows, type, value, defaultValue, onChange, disable, MAX_LIMIT = 100 }) => {
  const [data, setData] = useState();
  const [characterCount, setCharacterCount] = useState(defaultValue ? defaultValue?.length : 0);
  const { maxLength } = inputProps;

  const handleTextFieldChange = (e) => {
    onChange && onChange(e)
    setData(data);
    setCharacterCount(e.target.value.length);
  }


  return (
    <FormControl fullWidth sx={{ mb: '1rem' }}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField {...field} {...addErrorIntoField(errors[name])} 
          disabled={disable}
          onChange={handleTextFieldChange}
          value={data}
          defaultValue={defaultValue}
          type={type}
          label={label}
          multiline={multiline || false}
          rows={rows || 5}
          variant={variant || "standard"}
          inputProps={inputProps} />
        )}
      />
      {maxLength ? 
      <Typography color="textSecondary" variant="body2" align="right">
        {characterCount}/{maxLength || MAX_LIMIT}
      </Typography> : <></>
      }
      {errors[name] ? <ErrorMessage message={errors[name].message} /> : null}

    </FormControl>
  )
}
export default TextFieldAuto