import { Checkbox, FormControlLabel } from '@mui/material'
import { Controller } from 'react-hook-form'
import ErrorMessage from "Components/ErrorMessage";
import { Typography} from "@mui/material";
const CheckboxFields = ({ name, errors, control, label }) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControlLabel control={<Checkbox size='small' {...field} required />} label={ 
          <Typography
            color="textSecondary"
            sx={{ fontSize: 14, paddingTop: 2 }}
          >{label}  </Typography> || ""} />
        )}
      />
      {errors[name] ? <ErrorMessage message={errors[name].message} /> : null}
    </>
  )
}

export default CheckboxFields