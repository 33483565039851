import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Tabs,
  Tab,
  Container,
} from "@mui/material";
import DashboardLeftMenu from "Components/DashboardLeftMenu";
import CustomBreadcrumbs from "Components/Common/CustomBreadcrumbs";
import { TabPanel, a11yProps } from "Components/Common/CustomTabs";
import { useParams } from "react-router-dom";
import ClientContactInformation from './ContactInformation';
import ClientListingNotes from './Notes';
import { useDispatch, useSelector } from "react-redux";
import { getClientById } from "redux/actions/clientListing";
import { getNotes } from "redux/actions/notes";

const ClientDetails = () => {
  const { data } = useSelector((state) => state.client);

  const allClients =  data?.data?.lstAdmGetAllClientResponses;
  const params = useParams();
  const dispatch = useDispatch();
  const clientId = JSON.parse(sessionStorage.getItem('clientId'));
  const viewId = allClients?.filter((partner)=> partner.clientname == params.viewId)[0]?.id || clientId;
  const [tabValue, setTabValue] = useState(0);
  sessionStorage.setItem("clientId", JSON.stringify(viewId))

  useEffect(() => {
    dispatch(getClientById(viewId));
    dispatch(getNotes(viewId));
  // sessionStorage.setItem("allClients", JSON.stringify(allClients))
  }, []);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Container maxWidth='lg'> 
    <Grid container>
      <Grid
        item
        xs={3}
        borderRight="2px solid #F2F5FA"
        minHeight="90vh"
        py={2}
      >
        <DashboardLeftMenu pageId={viewId}/>
      </Grid>
      <Grid item xs={9}>
        <Grid container direction="row">
          <Grid item xs={12} px={2}>
            <Grid container justifyContent="space-between" alignItems="center">
              <CustomBreadcrumbs
                isHomeTrue={true}
                previousLink="Leads"
                prevlink='/admin/leads'
                currentLink={
                  tabValue === 0
                    ? "Contact Information"
                    : tabValue === 1
                    ? "Notes"
                    : ""
                }
              />
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h3" component="h1" color="primary.darker">
              Leads
              </Typography>
            </Grid>
            <Tabs value={tabValue} onChange={handleChange} aria-label="reports">
              <Tab label="Contact information" {...a11yProps(0)} />
              <Tab label="Notes" {...a11yProps(1)} />
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={tabValue} index={0}>
                <ClientContactInformation />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <ClientListingNotes />
            </TabPanel>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </Container>
  );
};

export default ClientDetails;
