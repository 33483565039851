import { useDispatch } from 'react-redux';
import Cookies from "universal-cookie";
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import axios from "axios";
import jwtDecode from 'jwt-decode';
import config from "../config";
import { logout } from '../redux/actions/auth/authAction';
import moment from 'moment';
const cookies = new Cookies();
let refreshingFunc = undefined;

axios.interceptors.request.use(
  request => {  
    return request;   
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use( async response => {
  // Do something with response data
  if(response.status==401){
      cookies.remove("@clientAccessToken", { path: `${config.baseName}/` });
      cookies.remove("@clientRefreshToken", { path: `${config.baseName}/` });
      localStorage.removeItem("userToken");
      localStorage.removeItem("persist:root");
      // window.location.href = `${config.baseName}/login`;
      window.location.href = window.location.href.includes('admin') ? `${config.baseName}/admin/login` : `${config.baseName}/login`;
   }
  return response;
}, async (error) => {
    const originalConfig = error.config;
    // if(error.response.status == 403) {
    //   window.location.href = `${config.baseName}/`;
    // }
  // Do something with response error
      if(error.response.status == 401) {

        if(error.response.request.responseURL.includes('login') || error.response.request.responseURL.includes('logout')) {
          return error.response;
        }

        try {
              // the trick here, that `refreshingFunc` is global, e.g. 2 expired requests will get the same function pointer and await same function.
              if (!refreshingFunc)
                  refreshingFunc = renewToken(error);
                  
              const accessToken = await refreshingFunc;
              originalConfig.headers.Authorization = `Bearer ${accessToken}`;
              if(!accessToken) {
                cookies.remove('@clientAccessToken', { path: `${config.baseName}/` });
                cookies.remove('@clientRefreshToken', { path: `${config.baseName}/` });
                localStorage.removeItem("persist:root");
                localStorage.removeItem("userToken");

                window.location.href = error.response.request.responseURL.includes('admin') ? `${config.baseName}/admin/login` : `${config.baseName}/login`;
              }
              // retry original request
              try {
                  return await axios.request(originalConfig);
              } catch(innerError) {
                  // if original req failed with 401 again - it means server returned not valid token for refresh request
                  //if (isUnauthorizedError(innerError)) {
                      throw innerError;
                  //}                  
              }

          } catch (err) {
              cookies.remove('@clientAccessToken', { path: `${config.baseName}/` });
              cookies.remove('@clientRefreshToken', { path: `${config.baseName}/` });
              // localStorage.removeItem("persist:root");
              // localStorage.removeItem("userToken");
              localStorage.clear();
              sessionStorage.clear();
              window.location.href = error.response.request.responseURL.includes('admin') ? `${config.baseName}/admin/login` : `${config.baseName}/login`;

          } finally {
              refreshingFunc = undefined;
          }        
      }
      return error.response;
});

export const renewToken = async (error) => {
  const cookies = new Cookies(window.document.cookie);
  const refreshToken = cookies.get("@clientRefreshToken");
  const accessToken = cookies.get("@clientAccessToken");
  const tokenData = jwtDecode(accessToken);
  console.log(tokenData.exp - moment().unix(), tokenData.iat, tokenData.exp, moment().unix());
  if (tokenData.exp && tokenData.exp - moment().unix() < 6) { 
    return await getRefreshToken(refreshToken);
  }
  else {
    cookies.remove('@clientAccessToken', { path: `${config.baseName}/` });
    cookies.remove('@clientRefreshToken', { path: `${config.baseName}/` });
    localStorage.removeItem("persist:root");
    localStorage.removeItem("userToken");

    window.location.href = error.response.request.responseURL.includes('admin') ? `${config.baseName}/admin/login` : `${config.baseName}/login`;
  }

  if (accessToken) {
    return accessToken;
  }
};

export const getAccessToken = async () => {
  const cookies = new Cookies(window.document.cookie);
  return cookies.get("@clientAccessToken");
};

export const getRefreshToken = async (refreshToken) => {
  try {
      const response = await  axios.post(
          `${config.apiBase}/profile/token`,
          { token: refreshToken }
        )
    const responseBody = await response.data;
    if (responseBody.data) {
      cookies.set("@clientRefreshToken", responseBody.data.refreshToken, {
        path: `${config.baseName}/`,
      });
      cookies.set("@clientAccessToken", responseBody.data.accessToken, {
        path: `${config.baseName}/`,
      });
      localStorage.setItem("userToken", responseBody.data.accessToken);
      return responseBody.data.accessToken;
    }
    return null;
  } catch (error) {
      cookies.remove('@clientAccessToken', { path: `${config.baseName}/` });
      cookies.remove('@clientRefreshToken', { path: `${config.baseName}/` });
      localStorage.removeItem("persist:root");
      localStorage.removeItem("userToken");

      window.location.href = error.response.request.responseURL.includes('admin') ? `${config.baseName}/admin/login` : `${config.baseName}/login`;
    // return {
    //     message:
    //         (error.response && error.response.data.message),
    //     data: {},
    //     error: true,
    // };
}
}