import { useEffect, useState } from "react";
import { useDispatch, useSelector,} from "react-redux";
import { useNavigate } from "react-router-dom";
import { Grid, Container } from "@mui/material";
import DashboardLeftMenu from "Components/DashboardLeftMenu";
import PartnerListing from "View/AdminConsole/PartnerListings";

const AdminConsole = () => {
  const navigate = useNavigate();
  const { userInfo, userData, error, isLoggedIn } = useSelector(
    (state) => state.auth
  );
  localStorage.removeItem("videoUrl")
  useEffect(() => {
    if (
      isLoggedIn &&
      userInfo &&
      Object.keys(userInfo).length > 0 &&
      userData?.sub?.userTypeId
    ) {
      userData.sub.userTypeId === 3 ? navigate("/admin/partner-listings") 
      : navigate('/');
    }
  }, [userInfo]);
  return (
  <Container maxWidth='lg'> 
    <Grid container>
      <Grid
        item
        xs={3}
        borderRight="2px solid #F2F5FA"
        minHeight="90vh"
        py={2}
      >
        <DashboardLeftMenu />
      </Grid>
      <Grid item xs={9} md={10} lg={9}>
        <Grid container direction="row">
          <Grid item xs={12} px={2} mb={2}>
            <PartnerListing />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </Container>
  );
};

export default AdminConsole;
