import { useState, useEffect } from "react";
import {
  Typography,
  List,
  ListItem,
  IconButton,
  Button,
  Box,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Loader from "Components/Common/Loader";
import editIcon from "Assets/Icons/edit-icon.png";
import deleteIcon from "Assets/Icons/delete-icon.png";

import {
  getManageFilter,
  addManageFilter,
  checkIsManageFilterEditable,
  editManageFilter,
  deleteManageFilter,
} from "redux/actions/homeFilter";

import AlertDialog from "Components/Common/AlertDialog";

const NavList = () => {
  const dispatch = useDispatch();
  const [isDelete, setIsDelete] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [addName, setAddtName] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [editItems, setEditItems] = useState({
    id: "",
    name: "",
    filterTypeId: "",
  });

  const [deleteItem, setDeleteItem] = useState({
    id: "",
    name: "",
    filterTypeId: "",
  });
  const [isAddNewAPIsavailable, setIsAddNewAPIsavailable] = useState(false);
  const [isAddNewCategory, setIsAddNewCategory] = useState(false);
  const [isAddNewIndustry, setIsAddNewIndustry] = useState(false);
  const [isAddNewBusinessSize, setIsAddNewBusinessSize] = useState(false);
  const [isAddNewErpsSupported, setIsAddNewErpsSupported] = useState(false);

  const [isEditNewAPIsavailable, setIsEditNewAPIsavailable] = useState(false);
  const [isEditNewCategory, setIsEditNewCategory] = useState(false);
  const [isEditNewIndustry, setIsEditNewIndustry] = useState(false);
  const [isEditNewBusinessSize, setIsEditNewBusinessSize] = useState(false);
  const [isEditNewErpsSupported, setIsEditNewErpsSupported] = useState(false);

  const [filterType, setFilterType] = useState();
  const [isEditable, setIsEditable] = useState(false);

  const [industryLoader, setIndustryLoader] = useState(false);
  const [applicationLoader, setApplicationLoader] = useState(false);
  const [businessSizeLoader, setBusinessSizeLoader] = useState(false);
  const [erpSupportedLoader, setErpSupportedLoader] = useState(false);
  const [connectivityLoader, setConnectivityLoader] = useState(false);
  const [apisLoader, setApisLoader] = useState(false);
  
  const industryType = useSelector(
    (state) => state.homeFilter?.data?.data?.industryType
  );
  const applicationCategories = useSelector(
    (state) => state.homeFilter?.data?.data?.applicationCategories
  );
  const businessSize = useSelector(
    (state) => state.homeFilter?.data?.data?.businessSize
  );
  const erpSupported = useSelector(
    (state) => state.homeFilter?.data?.data?.erpSupported
  );

  const connectivityChannels = useSelector(
    (state) => state.homeFilter?.data?.data?.connectivityChannels
  );
  const apisAvailable = useSelector(
    (state) => state.homeFilter?.data?.data?.apisAvailable
  );

  useEffect(() => {
    dispatch(getManageFilter());
  }, [getManageFilter]);

  const handleEditManageFilter = async (id, name, filterTypeId) => {
    const payload = { id, filterTypeId };
    //dispatch(isEditManageFilter(payload));
    setUiLoader(true, filterTypeId)
    const result = await checkIsManageFilterEditable(payload);

    setEditItems({ id, name, filterTypeId });
    setFilterType(filterTypeId);

    if (result.data) {
      setIsEditable(true);
      setUiLoader(false, filterTypeId)
    } else {
      handleEditFilter(filterTypeId);
    }
  };

  useEffect(() => {
    if (confirmDelete) {
      setUiLoader(true, deleteItem.filterTypeId)
      dispatch(
        deleteManageFilter({
          id: deleteItem.id,
          filterTypeId: deleteItem.filterTypeId,
        })
      ).then((res) => {
        dispatch(getManageFilter()).then((res) => {
          setUiLoader(false, deleteItem.filterTypeId)
        });
      });
    }
  }, [confirmDelete]);

  const setUiLoader = (flag, filterTypeId) => {
    if (filterTypeId === 1) {
      setIndustryLoader(flag);
    }
    if (filterTypeId === 2) {
      setApplicationLoader(flag);
    }
    if (filterTypeId === 3) {
      setBusinessSizeLoader(flag);
    }
    if (filterTypeId === 4) {
      setErpSupportedLoader(flag);
    }
    if (filterTypeId === 5) {
      setApisLoader(flag);
    }
  }

  const handleDeleteManageFilter = async (id, name, filterTypeId) => {
    setDeleteName(name);
    const payload = { id, filterTypeId };

    setUiLoader(true, filterTypeId)

    const result = await checkIsManageFilterEditable(payload);
    setConfirmDelete(false);
    setDeleteItem({ id, name, filterTypeId });

    setIsEditNewAPIsavailable(false)
    setIsEditNewCategory(false);
    setIsEditNewIndustry(false);
    setIsEditNewBusinessSize(false);
    setIsEditNewErpsSupported(false);
    setIsEditable(false);

    if (result.data) {
      setIsDelete(true);
      setShowDeleteConfirmModal(false);
      setUiLoader(false, filterTypeId)
    } else {
      setIsDelete(false);
      setShowDeleteConfirmModal(true);
      setUiLoader(false, filterTypeId)
    }
  };

  const editAPIsavailable = (filterTypeId) => {
    setUiLoader(true, filterTypeId)
    editManageFilterApi(editItems)
    setIsEditNewAPIsavailable(false);
  };

  const editCategory = (filterTypeId) => {
    setUiLoader(true, filterTypeId)
    editManageFilterApi(editItems)
    setIsEditNewCategory(false);
  };

  const editIndustry = (filterTypeId) => {
    setUiLoader(true, filterTypeId)
    editManageFilterApi(editItems)
    setIsEditNewIndustry(false);
  };

  const editBusinessSize = (filterTypeId) => {
    setUiLoader(true, filterTypeId)
    editManageFilterApi(editItems)
    setIsEditNewBusinessSize(false);
  };

  const editErpsSupported = (filterTypeId) => {
    setUiLoader(true, filterTypeId)
    editManageFilterApi(editItems)
    setIsEditNewErpsSupported(false);
  };

  const editManageFilterApi = (payload) => {
    dispatch(editManageFilter(payload)).then((res) => {
      dispatch(getManageFilter()).then((res) => {
        setUiLoader(false, payload.filterTypeId)
      });
    });
  }

  const saveManageFilter = (payload) => {
    dispatch(addManageFilter(payload)).then((res) => {
      dispatch(getManageFilter()).then((res) => {
        setUiLoader(false, payload.filterTypeId)
      });
    });
  }

    /** Save Name APIs available   */
    const saveNameAPIsavailable = (filterTypeId) => {
      const payload = { addName, filterTypeId };
      setUiLoader(true, filterTypeId)
      saveManageFilter(payload);
      setIsAddNewAPIsavailable(false);
      setIsEditNewAPIsavailable(false);
    };

  /** Save Name Category   */
  const saveNameCategory = (filterTypeId) => {
    const payload = { addName, filterTypeId };
    setUiLoader(true, filterTypeId)
    saveManageFilter(payload);
    setIsAddNewCategory(false);
    setIsEditNewCategory(false);
  };

  /** Save Name Industry */
  const saveNameIndustry = (filterTypeId) => {
    const payload = { addName, filterTypeId };
    setUiLoader(true, filterTypeId)
    saveManageFilter(payload);
    setIsAddNewIndustry(false);
    setIsEditNewIndustry(false);
  };

  /** Save Name BusinessSize */
  const saveNameBusinessSize = (filterTypeId) => {
    const payload = { addName, filterTypeId };
    setUiLoader(true, filterTypeId)
    saveManageFilter(payload);
    setIsAddNewBusinessSize(false);
    setIsEditNewBusinessSize(false);
  };

  /** Save Name ErpsSupported */
  const saveNameErpsSupported = (filterTypeId) => {
    const payload = { addName, filterTypeId };
    setUiLoader(true, filterTypeId)
    saveManageFilter(payload);
    setIsAddNewErpsSupported(false);
    setIsEditNewErpsSupported(false);
  };

  const getNameOnChange = (e) => {
    setAddtName(e.target.value);
  };

  const handleEditName = (e) => {
    setEditItems({ ...editItems, name: e.target.value });
  };

  const handleConfirmEditFilter = (e) => {
    e.preventDefault();
    setIsEditable(false);
    setIsAddNewAPIsavailable(false);
    setIsAddNewIndustry(false);
    setIsAddNewCategory(false);
    setIsAddNewBusinessSize(false);
    setIsAddNewErpsSupported(false);

    if (filterType === 1) {
      setIsEditNewIndustry(true);
      setIsEditNewCategory(false);
      setIsEditNewBusinessSize(false);
      setIsEditNewErpsSupported(false);
      setIsEditNewAPIsavailable(false)
    }
    if (filterType === 2) {
      setIsEditNewIndustry(false);
      setIsEditNewCategory(true);
      setIsEditNewBusinessSize(false);
      setIsEditNewErpsSupported(false);
      setIsEditNewAPIsavailable(false)
    }
    if (filterType === 3) {
      setIsEditNewIndustry(false);
      setIsEditNewCategory(false);
      setIsEditNewBusinessSize(true);
      setIsEditNewErpsSupported(false);
      setIsEditNewAPIsavailable(false)
    }
    if (filterType === 4) {
      setIsEditNewIndustry(false);
      setIsEditNewCategory(false);
      setIsEditNewBusinessSize(false);
      setIsEditNewErpsSupported(true);
      setIsEditNewAPIsavailable(false)
    }
    if (filterType === 5) {
      setIsEditNewIndustry(false);
      setIsEditNewCategory(false);
      setIsEditNewBusinessSize(false);
      setIsEditNewErpsSupported(false);
      setIsEditNewAPIsavailable(true)
    }
  };

  const handleAddFilter = (filterType) => {
    setAddtName("");
    if (filterType === 1) {
      setIsAddNewAPIsavailable(false)
      setIsAddNewIndustry(true);
      setIsAddNewCategory(false);
      setIsAddNewBusinessSize(false);
      setIsAddNewErpsSupported(false);

      setIsEditNewAPIsavailable(false)
      setIsEditNewIndustry(false);
      setIsEditNewCategory(false);
      setIsEditNewBusinessSize(false);
      setIsEditNewErpsSupported(false);
    }
    if (filterType === 2) {
      setIsAddNewAPIsavailable(false)
      setIsAddNewIndustry(false);
      setIsAddNewCategory(true);
      setIsAddNewBusinessSize(false);
      setIsAddNewErpsSupported(false);

      setIsEditNewAPIsavailable(false)
      setIsEditNewIndustry(false);
      setIsEditNewCategory(false);
      setIsEditNewBusinessSize(false);
      setIsEditNewErpsSupported(false);
    }
    if (filterType === 3) {
      setIsAddNewAPIsavailable(false)
      setIsAddNewIndustry(false);
      setIsAddNewCategory(false);
      setIsAddNewBusinessSize(true);
      setIsAddNewErpsSupported(false);

      setIsEditNewAPIsavailable(false)
      setIsEditNewIndustry(false);
      setIsEditNewCategory(false);
      setIsEditNewBusinessSize(false);
      setIsEditNewErpsSupported(false);
    }
    if (filterType === 4) {
      setIsAddNewAPIsavailable(false)
      setIsAddNewIndustry(false);
      setIsAddNewCategory(false);
      setIsAddNewBusinessSize(false);
      setIsAddNewErpsSupported(true);

      setIsEditNewAPIsavailable(false)
      setIsEditNewIndustry(false);
      setIsEditNewCategory(false);
      setIsEditNewBusinessSize(false);
      setIsEditNewErpsSupported(false);
    }

    if (filterType === 5) {
      setIsAddNewAPIsavailable(true)
      setIsAddNewIndustry(false);
      setIsAddNewCategory(false);
      setIsAddNewBusinessSize(false);
      setIsAddNewErpsSupported(false);

      setIsEditNewAPIsavailable(false)
      setIsEditNewIndustry(false);
      setIsEditNewCategory(false);
      setIsEditNewBusinessSize(false);
      setIsEditNewErpsSupported(false);
    }
  };

  const handleEditFilter = (filterType) => {
    if (filterType === 1) {
      setIsAddNewAPIsavailable(false);
      setIsAddNewIndustry(false);
      setIsAddNewCategory(false);
      setIsAddNewBusinessSize(false);
      setIsAddNewErpsSupported(false);

      setIsEditNewAPIsavailable(false)
      setIsEditNewIndustry(true);
      setIsEditNewCategory(false);
      setIsEditNewBusinessSize(false);
      setIsEditNewErpsSupported(false);
      
      setIndustryLoader(false);
    }
    if (filterType === 2) {
      setIsAddNewAPIsavailable(false);
      setIsAddNewIndustry(false);
      setIsAddNewCategory(false);
      setIsAddNewBusinessSize(false);
      setIsAddNewErpsSupported(false);

      setIsEditNewAPIsavailable(false);
      setIsEditNewIndustry(false);
      setIsEditNewCategory(true);
      setIsEditNewBusinessSize(false);
      setIsEditNewErpsSupported(false);

      setApplicationLoader(false);
    }
    if (filterType === 3) {
      setIsAddNewAPIsavailable(false);
      setIsAddNewIndustry(false);
      setIsAddNewCategory(false);
      setIsAddNewBusinessSize(false);
      setIsAddNewErpsSupported(false);

      setIsEditNewAPIsavailable(false);
      setIsEditNewIndustry(false);
      setIsEditNewCategory(false);
      setIsEditNewBusinessSize(true);
      setIsEditNewErpsSupported(false);

      setBusinessSizeLoader(false);
    }
    if (filterType === 4) {
      setIsAddNewAPIsavailable(false);
      setIsAddNewIndustry(false);
      setIsAddNewCategory(false);
      setIsAddNewBusinessSize(false);
      setIsAddNewErpsSupported(false);

      setIsEditNewAPIsavailable(false);
      setIsEditNewIndustry(false);
      setIsEditNewCategory(false);
      setIsEditNewBusinessSize(false);
      setIsEditNewErpsSupported(true);

      setErpSupportedLoader(false);
    }

    if (filterType === 5) {
      setIsAddNewAPIsavailable(false);
      setIsAddNewIndustry(false);
      setIsAddNewCategory(false);
      setIsAddNewBusinessSize(false);
      setIsAddNewErpsSupported(false);

      setIsEditNewAPIsavailable(true);
      setIsEditNewIndustry(false);
      setIsEditNewCategory(false);
      setIsEditNewBusinessSize(false);
      setIsEditNewErpsSupported(false);

      setApisLoader(false);
    }
  };

  return (
    <>
      {/*******start APIs available ***********/}
      <Typography
        fontSize={20}
        px={2}
        pt={2}
        gutterBottom
        color="textSecondary"
      >
        APIs available
      </Typography>
      <List dense>
        { !apisAvailable || apisLoader ? (
          <Loader />
        ) : (
          <>
          {apisAvailable?.map((item, index) => (
            <ListItem
              sx={{ justifyContent: "space-between" }}
              key={`appId_${index}`}
            >
              <Typography gutterBottom title={item.name} noWrap="true">
                {item.name}
              </Typography>

              <Box pr={1} display="flex">
                <IconButton
                  disableRipple
                  onClick={() => handleEditManageFilter(item.id, item.name, 5)}
                >
                  <img src={editIcon} alt="edit icon" title="Edit" width="16" />
                </IconButton>
                <IconButton
                  disableRipple
                  onClick={() =>
                    handleDeleteManageFilter(item.id, item.name, 5)
                  }
                >
                  <img
                    src={deleteIcon}
                    alt="delete icon"
                    title="Delete"
                    width="16"
                  />
                </IconButton>
              </Box>
            </ListItem>
          ))}
          </>
        )}
        <Box px={2}>
          <Button
            variant="outlined"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => handleAddFilter(5)}
          >
            Add new APIs available
          </Button>
          {isEditable === false && isAddNewAPIsavailable && (
            <Box pt={2}>
              <TextField
                fullWidth
                variant="standard"
                label=""
                value={addName}
                onChange={getNameOnChange}
              />
              <Box display="flex" gap={2} pt={2}>          
                <Button variant="contained" onClick={() => saveNameAPIsavailable(5)}>
                  Save
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setIsAddNewAPIsavailable(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          )}
          {isEditable === false && isEditNewAPIsavailable && (
            <Box pt={2}>
              <TextField
                fullWidth
                variant="standard"
                label=""
                value={editItems.name}
                onChange={handleEditName}
              />
              <Box display="flex" gap={2} pt={2}>
                <Button variant="contained" onClick={() => editAPIsavailable(5)}>
                  Save
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setIsEditNewAPIsavailable(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </List>
      {/*******end APIs available ***********/}
      <Typography
        fontSize={20}
        px={2}
        pt={2}
        gutterBottom
        color="textSecondary"
      >
        {" "}
        Application categories{" "}
      </Typography>
      <List dense>
      { !applicationCategories || applicationLoader ? (
          <Loader />
        ) : (
          <>
        { applicationCategories?.map((item, index) => (
            <ListItem
              sx={{ justifyContent: "space-between" }}
              key={`appId_${index}`}
            >
              <Typography gutterBottom title={item.name} noWrap="true">
                {item.name}
              </Typography>

              <Box pr={1} display="flex">
                <IconButton
                  disableRipple
                  onClick={() => handleEditManageFilter(item.id, item.name, 2)}
                >
                  <img src={editIcon} alt="edit icon" title="Edit" width="16" />
                </IconButton>
                <IconButton
                  disableRipple
                  onClick={() =>
                    handleDeleteManageFilter(item.id, item.name, 2)
                  }
                >
                  <img
                    src={deleteIcon}
                    alt="delete icon"
                    title="Delete"
                    width="16"
                  />
                </IconButton>
              </Box>
            </ListItem>
          ))}
          </>
        )}
        <Box px={2}>
          <Button
            variant="outlined"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => handleAddFilter(2)}
          >
            Add new Application categories
          </Button>
          {isEditable === false && isAddNewCategory && (
            <Box pt={2}>
              <TextField
                fullWidth
                variant="standard"
                label=""
                value={addName}
                onChange={getNameOnChange}
              />
              <Box display="flex" gap={2} pt={2}>
                <Button variant="contained" onClick={() => saveNameCategory(2)}>
                  Save
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setIsAddNewCategory(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          )}
          {isEditable === false && isEditNewCategory && (
            <Box pt={2}>
              <TextField
                fullWidth
                variant="standard"
                label=""
                value={editItems.name}
                onChange={handleEditName}
              />
              <Box display="flex" gap={2} pt={2}>
                <Button variant="contained" onClick={() => editCategory(2)}>
                  Save
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setIsEditNewCategory(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </List>
      {/******* Industry***********/}
      <Typography
        fontSize={20}
        px={2}
        pt={2}
        gutterBottom
        color="textSecondary"
      >
        Industry type
      </Typography>
      <List dense>
      { !industryType || industryLoader ? (
          <Loader />
        ) : (
          <>
        { industryType?.map((item, index) => (
            <ListItem
              sx={{ justifyContent: "space-between", width: "100%" }}
              key={`itemId_${index}`}
            >
              <Typography gutterBottom title={item.name} noWrap="true">
                {item.name}
              </Typography>

              <Box pr={1} display="flex">
                <IconButton
                  disableRipple
                  onClick={() => handleEditManageFilter(item.id, item.name, 1)}
                >
                  <img src={editIcon} alt="edit icon" title="Edit" width="16" />
                </IconButton>
                <IconButton
                  disableRipple
                  onClick={() =>
                    handleDeleteManageFilter(item.id, item.name, 1)
                  }
                >
                  <img
                    src={deleteIcon}
                    alt="delete icon"
                    title="Delete"
                    width="16"
                  />
                </IconButton>
              </Box>
            </ListItem>
          ))}
          </>
          )}
        <Box px={2}>
          <Button
            variant="outlined"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => {
              handleAddFilter(1);
            }}
          >
            Add new Industry type
          </Button>
          {isEditable === false && isAddNewIndustry && (
            <Box pt={2}>
              <TextField
                fullWidth
                variant="standard"
                label=""
                value={addName}
                onChange={getNameOnChange}
              />
              <Box display="flex" gap={2} pt={2}>
                <Button variant="contained" onClick={() => saveNameIndustry(1)}>
                  Save
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setIsAddNewIndustry(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          )}
          {isEditable === false && isEditNewIndustry && (
            <Box pt={2}>
              <TextField
                fullWidth
                variant="standard"
                label=""
                value={editItems.name}
                onChange={handleEditName}
              />
              <Box display="flex" gap={2} pt={2}>
                <Button variant="contained" onClick={() => editIndustry(1)}>
                  Save
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setIsEditNewIndustry(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </List>

      {/******* Business Size***********/}
      <Typography
        fontSize={20}
        px={2}
        pt={2}
        gutterBottom
        color="textSecondary"
      >
        Business size
      </Typography>
      <List dense>
      { !businessSize || businessSizeLoader ? (
          <Loader />
        ) : (
          <>
        { businessSize?.map((item, index) => (
            <ListItem
              sx={{ justifyContent: "space-between" }}
              key={`itemId_${index}`}
            >
              <Typography gutterBottom title={item.name} noWrap="true">
                {item.name}
              </Typography>

              <Box pr={1} display="flex">
                <IconButton
                  disableRipple
                  onClick={() => handleEditManageFilter(item.id, item.name, 3)}
                >
                  <img src={editIcon} alt="edit icon" title="Edit" width="16" />
                </IconButton>
                <IconButton
                  disableRipple
                  onClick={() =>
                    handleDeleteManageFilter(item.id, item.name, 3)
                  }
                >
                  <img
                    src={deleteIcon}
                    alt="delete icon"
                    title="Delete"
                    width="16"
                  />
                </IconButton>
              </Box>
            </ListItem>
          ))}
          </>
        )}
        <Box px={2}>
          <Button
            variant="outlined"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => {
              handleAddFilter(3);
            }}
          >
            Add new Business type
          </Button>
          {isEditable === false && isAddNewBusinessSize && (
            <Box pt={2}>
              <TextField
                fullWidth
                variant="standard"
                label=""
                value={addName}
                onChange={getNameOnChange}
              />
              <Box display="flex" gap={2} pt={2}>
                <Button
                  variant="contained"
                  onClick={() => saveNameBusinessSize(3)}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setIsAddNewBusinessSize(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          )}
          {isEditable === false && isEditNewBusinessSize && (
            <Box pt={2}>
              <TextField
                fullWidth
                variant="standard"
                label=""
                value={editItems.name}
                onChange={handleEditName}
              />
              <Box display="flex" gap={2} pt={2}>
                <Button variant="contained" onClick={() => editBusinessSize(3)}>
                  Save
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setIsEditNewBusinessSize(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </List>

      {/******* ERPs Supported***********/}
      <Typography
        fontSize={20}
        px={2}
        pt={2}
        gutterBottom
        color="textSecondary"
      >
        ERPs supported
      </Typography>
      <List dense>
      { !erpSupported || erpSupportedLoader ? (
          <Loader />
        ) : (
          <>
        { erpSupported?.map((item, index) => (
            <ListItem
              sx={{ justifyContent: "space-between" }}
              key={`itemId_${index}`}
            >
              <Typography gutterBottom title={item.name} noWrap="true">
                {item.name}
              </Typography>

              <Box pr={1} display="flex">
                <IconButton
                  disableRipple
                  onClick={() => handleEditManageFilter(item.id, item.name, 4)}
                >
                  <img src={editIcon} alt="edit icon" title="Edit" width="16" />
                </IconButton>
                <IconButton
                  disableRipple
                  onClick={() =>
                    handleDeleteManageFilter(item.id, item.name, 4)
                  }
                >
                  <img
                    src={deleteIcon}
                    alt="delete icon"
                    title="Delete"
                    width="16"
                  />
                </IconButton>
              </Box>
            </ListItem>
          ))}
          </>
        )}
        <Box px={2}>
          <Button
            variant="outlined"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => {
              handleAddFilter(4);
            }}
          >
            Add new ERP type
          </Button>
          {isEditable === false && isAddNewErpsSupported && (
            <Box pt={2}>
              <TextField
                fullWidth
                variant="standard"
                label=""
                value={addName}
                onChange={getNameOnChange}
              />
              <Box display="flex" gap={2} pt={2}>
                <Button
                  variant="contained"
                  onClick={() => saveNameErpsSupported(4)}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setIsAddNewErpsSupported(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          )}
          {isEditable === false && isEditNewErpsSupported && (
            <Box pt={2}>
              <TextField
                fullWidth
                variant="standard"
                label=""
                value={editItems.name}
                onChange={handleEditName}
              />
              <Box display="flex" gap={2} pt={2}>
                <Button
                  variant="contained"
                  onClick={() => editErpsSupported(4)}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setIsEditNewErpsSupported(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </List>

      {isEditable ? (
        <AlertDialog
          message={`"${editItems.name}" is currently in use. Do you still want to rename?`}
          open={isEditable}
          onClose={() => {
            setIsEditNewAPIsavailable(false)
            setIsEditNewCategory(false);
            setIsEditNewIndustry(false);
            setIsEditNewBusinessSize(false);
            setIsEditNewErpsSupported(false);
            setIsEditable(false);
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirmEditFilter}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setIsEditNewAPIsavailable(false)
              setIsEditNewCategory(false);
              setIsEditNewIndustry(false);
              setIsEditNewBusinessSize(false);
              setIsEditNewErpsSupported(false);
              setIsEditable(false);
            }}
          >
            Cancel
          </Button>
        </AlertDialog>
      ) : isDelete ? (
        <AlertDialog
          message={`"${deleteName}" cannot be deleted because it is currently in use.`}
          open={true}
          onClose={() => setIsDelete(false)}
        >
          {/* <Button
            variant="contained"
            color="primary"
            onClick={() => setIsDelete(false)}
          >
            Remove
          </Button> */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsDelete(false)}
          >
            Cancel
          </Button>
        </AlertDialog>
      ) : (
        ""
      )}

      {showDeleteConfirmModal && (
        <AlertDialog
          message={`Are you sure you want to delete "${deleteName}"?`}
          open={true}
          onClose={() => {
            setShowDeleteConfirmModal(false);
            setConfirmDelete(false);
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setShowDeleteConfirmModal(false);
              setConfirmDelete(true);
            }}
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setShowDeleteConfirmModal(false);
              setConfirmDelete(false);
            }}
          >
            Cancel
          </Button>
        </AlertDialog>
      )}
    </>
  );
};

export default NavList;