import { Grid, Typography, TextField, Button, Box} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import FileUpload from "Components/FileUpload";
import axios from "axios";
import TextFieldAuto from "Components/Common/TextFieldAuto";
import Textarea from "Components/Common/Textarea";
import { stepTwoSubmit } from "redux/actions/onboarding/onboardingAction";
import { storeLogoUrl } from "redux/reducers/partners/partnersSlice";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HtmlEdior from "Components/Common/HtmlEditor";
import AlertDialog from "Components/Common/AlertDialog";
import { useEffect, useState, useRef } from "react";
import config from "config";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ErrorMessage from "Components/ErrorMessage";
import Loader from "Components/Common/Loader";
import {
  getPartner,
} from "redux/actions/onboarding/onboardingAction";
import jwtDecode from 'jwt-decode';

// const validationSchema = yup.object().shape({
//   imageTitle_0: yup.string().required("Image Title is required"),
// });

// const formOptions = {
//   defaultValues: {
//     imageTitle_0: "",
//   },
//   resolver: yupResolver(validationSchema),
// };

const FromStepTwo = ({partnerData}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const partnerListingFetch = useSelector(
    (state) => state.partnerListing?.data?.lstAdmGetAllParnerResponses
  );
  const localData = localStorage.getItem("userToken");
  const tokenDecode = jwtDecode(localData)
  const partnerLeadIdLoc = tokenDecode?.sub?.leadId
  const partnerLeadIDSess = JSON.parse(sessionStorage.getItem('leadId'));
  const partnerLeadID = partnerListingFetch?.filter((partner)=> partner.partnername == params.leadId)[0]?.id || partnerLeadIdLoc || partnerLeadIDSess
  
  const [loader, setLoader] = useState(false);
  //const { loading, stepOne , isSubmitted, error, stepTwo, partnerData, partnerLoader } = useSelector((state) => state.onboarding);
  const storageFormData = JSON.parse(sessionStorage.getItem("formData"));
  const [formData, setFormData] = useState(storageFormData);
  const [serviceHtmlString, setServiceHtmlString] = useState(formData ? formData?.serviceDescription : partnerData?.overview?.serviceDescription);
  const [serviceHtmlStringErr, setServiceHtmlStringErr] = useState("")
  const [featureHtmlString, setFeatureHtmlString] = useState(formData ? formData?.serviceKeyPoint : partnerData?.features);
  const [featureHtmlStringErr, setFeatureHtmlStringErr] = useState("")
  const [screenShots, setScreenShots] = useState(formData ? formData?.overview?.screenshots : partnerData?.overview?.screenshots);

  const { userData } = useSelector((state) => state.auth);
  
  const { register, handleSubmit, formState, control, reset, setValues, setError, setValue} = useForm();
  const { errors, isSubmitting } = formState;
  const [popupMsg, setPopupMsg] = useState("");
  const [partnerVideoUrl, setPartnerVideoUrl] = useState(partnerData?.overview?.videoUrl || storageFormData?.videoUrl)
  const [apiValError, setValError] = useState(null);
  const pholder = "Give out the salient feature and capabilities of the service";
  const [placeholder, setPlaceholder] = useState(pholder);
  const [disable, setDisable] = useState(false);
  const match = location.pathname.match('/admin');
  const partnerName = sessionStorage.getItem('partnerName')
  let navigate = useNavigate();

  useEffect(() => {
    if(partnerName != params.leadId){
    navigate(`/admin/step-one/${params.leadId}`);
    }
  }, [params.leadId, navigate]);
 
  const errorDiv = apiValError 
        ? <div className="error">
            <i className="material-icons error-icon"></i>
            {apiValError}
          </div> 
        : '';
  const submitSecondForm = (data) => {
    let flag = false;
    // Check if service description and feature fields are empty
    if (!serviceHtmlString || serviceHtmlString == null ) {
      setServiceHtmlStringErr("Service description is required");
      scrollUpside();
      flag = true;
    }
    if (!featureHtmlString || featureHtmlString == null) {
      setFeatureHtmlStringErr("Features & benefits are required");
      scrollUpside();
      flag = true;
    }
  
    if(screenShots[0].uri != ''){
            if(screenShots[0].title == ''){
              setValError('Please enter title for screenshots.'); 
              flag = true;;
            }else if(screenShots[0].description == ''){
              setValError('Please enter description for screenshots.'); 
              flag = true;;
            }
          }else{
                setValError('Please upload screenshots.');
                flag = true;;
              }
  
    // If all validations pass, proceed with form submission
    if(flag){
      return;
    }
    postStepTwo();
  };
  
//   const submitSecondForm = (data) => {
//     if(screenShots[0].uri != ''){
//       if(screenShots[0].title == ''){
//         setValError('Please enter title for screenshots.'); 
//         return;
//       }else if(screenShots[0].description == ''){
//         setValError('Please enter description for screenshots.'); 
//         return;
//       }
//       postStepTwo();
//     }else{
//       setValError('Please upload screenshots.');
//       return;
//     }
    
//  };

  useEffect(() => {
    if(partnerData?.overview) {
      if(params?.leadId && !formData) {
        const data= {
          leadId: partnerData?.overview.leadsId,
          serviceDescription: partnerData?.overview.serviceDescription,
          serviceKeyPoint: partnerData.features,
          screenshots: partnerData.overview.screenshots.length ? partnerData.overview.screenshots : [{ uri: "", title: "", description: "" }],
        };
      
        sessionStorage.setItem('formData', JSON.stringify(data));
        setFormData(data);
      }
      else {
          setServiceHtmlString(formData && formData?.serviceDescription? formData.serviceDescription : partnerData.overview.serviceDescription)
          setFeatureHtmlString(formData && formData?.serviceKeyPoint? formData.serviceKeyPoint : partnerData.features)
          if(formData?.screenshots?.length){
            setScreenShots(formData.screenshots)
          }
      }
    }
    
    if(partnerData && partnerData?.currentStageId === 10 && userData?.sub?.userTypeId !== 3){
      setDisable(true)
    }
  },[partnerData])

  useEffect(() => {
    if(formData){
      if(formData?.screenshots?.length){
        setScreenShots(formData.screenshots)
        formData.screenshots.map((item,index) => {
          setValue(`imageTitle_${index}`, item.title)
        })
      }
    }
  },[formData])

  const postStepTwo = async (data) => {
    localStorage.removeItem("videoUrl")
    try {
      const leadId =  userData?.sub?.leadId || partnerLeadID;
      setPartnerVideoUrl('')
      let screenShotsCopy = screenShots.map(item => {
        return {
          description: item.description,
          title: item.title,
          uri: item.uri
        }
      });
      const payload = {
        leadId: leadId,
        serviceDescription: serviceHtmlString,
        serviceKeyPoint: featureHtmlString,
        screenshots: screenShotsCopy,
        videoUrl: partnerVideoUrl,
      };
      setLoader(true);
      dispatch(stepTwoSubmit(payload)).then((res) => {
        setLoader(false);
        if (res.payload.error) {
          if(res.payload.message.includes('uri')){
            setValError('Upload atleast one screenshot')
          } else if(res.payload.message.includes('title')){
            setValError('Screenshot title is not allowed to be empty')
          } else if(res.payload.message.includes('description')){
            setValError('Screenshot description is not allowed to be empty')
          } else{
          setValError(res.payload.message);
          }
        } else {
          sessionStorage.removeItem('formData');
          userData?.sub?.userTypeId !== 3 ? navigate('/thank-you') : navigate('/admin/partner-listings/partner-details/'+params.leadId);
          dispatch(storeLogoUrl(""))
        }
      })
    } catch (error) {
      setLoader(false);
      console.error("ERR:", error);
    }
  };

  const storeInStorage = async () => {
    
    const data= {
      leadId: userData?.sub?.leadId || partnerLeadID,
      serviceDescription: serviceHtmlString,
      serviceKeyPoint: featureHtmlString,
      screenshots: screenShots,
      videoUrl: partnerVideoUrl
    };
    await sessionStorage.setItem('formData', JSON.stringify(data));
    sessionStorage.setItem('partnerName', params?.leadId)
    const purl = match ? `/admin/details${params?.leadId ? '/'+params?.leadId : '/'+userData?.sub?.partnerName}` : `/details${params?.leadId ? '/'+params?.leadId : '/'+userData?.sub?.partnerName}`;
    navigate(purl)
  }

  const storOnPrev = () => {
    const data= {
      leadId: partnerLeadID,
      serviceDescription: serviceHtmlString,
      serviceKeyPoint: featureHtmlString,
      screenshots: screenShots,
    };
    sessionStorage.setItem('formData', JSON.stringify(data));
    const purl = match ? `/admin/step-one${params?.leadId ? '/'+params.leadId : '/'+userData?.sub?.partnerName}` : `/step-one${params?.leadId ? '/'+params.leadId : '/'+userData?.sub?.partnerName}`
    navigate(purl)
  }

  const handleServiceDescription = (val) => {
    if(val){
      setServiceHtmlStringErr("")
    }
    else {
      setServiceHtmlStringErr("Service description is required");
    }
    let storageData = JSON.parse(sessionStorage.getItem('formData'));
    if(storageData) {
      storageData.serviceDescription = val;
    }
    else {
      storageData = {'serviceDescription': val, 'serviceKeyPoint': featureHtmlString};
    }
    
    sessionStorage.setItem('formData', JSON.stringify(storageData));
    
    setServiceHtmlString(val);
  }

  const handleFeature = (val) => {
    let storageData = JSON.parse(sessionStorage.getItem('formData'));
    if(val){
      setFeatureHtmlStringErr("")
    }
    else {
      setFeatureHtmlStringErr("Features & benefits are required");
    }
    if(storageData) {
      storageData.serviceKeyPoint = val;
    }
    else {
      storageData = {'serviceDescription': serviceHtmlString, 'serviceKeyPoint': val};
    }
    
    sessionStorage.setItem('formData', JSON.stringify(storageData));

    setFeatureHtmlString(val)
  }

  const handleFileUpload = (uri, index) => {
    let storageData = JSON.parse(sessionStorage.getItem('formData'));
    if(storageData && storageData.screenshots) {
      storageData.screenshots[index].uri = uri;
    }
    else {
      storageData = {...{screenshots : {uri: uri, title: '', description: ''}}}
    }
    
    sessionStorage.setItem('formData', JSON.stringify(storageData));

    // const screenShotsCopy = [...JSON.parse(JSON.stringify(screenShots))];
    // screenShotsCopy[index].uri = uri;
    
    setScreenShots(storageData.screenshots);
  };

  const handleTitle = (e, index) => {
    let storageData = JSON.parse(sessionStorage.getItem('formData'));
    if(storageData && storageData.screenshots) {
      storageData.screenshots[index].title = e.target.value
    }
    else {
      storageData = {...{screenshots : {uri:'', title: e.target.value, description: ''}}}
    }
    
    sessionStorage.setItem('formData', JSON.stringify(storageData));

    const screenShotsCopy = [...JSON.parse(JSON.stringify(screenShots))];
    screenShotsCopy[index].title = e.target.value;

    setScreenShots([...screenShotsCopy]);
    setValue('imageTitle_'+index, e.target.value)
  };

  const handleDescription = (val, index) => {
    let storageData = JSON.parse(sessionStorage.getItem('formData'));
    if(storageData && storageData.screenshots) {
      storageData.screenshots[index].description = val
    }
    else {
      storageData = {...{screenshots : {uri:'', title: '', description: val}}}
    }
    sessionStorage.setItem('formData', JSON.stringify(storageData));

    const screenShotsCopy = [...JSON.parse(JSON.stringify(screenShots))];
    screenShotsCopy[index].description = val;
    return screenShotsCopy;
  };

  const handlePartnerVideoLink = (e) => {
    let tempFormData = JSON.parse(sessionStorage.getItem('formData'));
    let value = tempFormData?.videoUrl
    if(tempFormData?.videoUrl){
      setPartnerVideoUrl(value)
    }
      setPartnerVideoUrl(e.target.value)
  }
  const addItems = () => {
    let copyScreenShots = [...screenShots];
    copyScreenShots.push({ uri: "", title: "", description: "" });
    let tempFormData = JSON.parse(sessionStorage.getItem('formData'));
    if(tempFormData?.screenshots) {
      tempFormData.screenshots.push({ uri: "", title: "", description: "" });
      sessionStorage.setItem('formData', JSON.stringify(tempFormData));
      setFormData(tempFormData);
    }
    setScreenShots(copyScreenShots);
  };

  const delteItems = (index) => {
    let copyScreenShots = [...screenShots];
    if (index > 0) {
      copyScreenShots.splice(index, 1);
      let tempFormData = JSON.parse(localStorage.getItem('formData'));
      if(tempFormData?.screenshots) {
        tempFormData.screenshots.splice(index, 1);
        sessionStorage.setItem('formData', JSON.stringify(tempFormData));
        setFormData(tempFormData);
      }
    }
    setScreenShots(copyScreenShots);
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollUpside();
    }
  },[isSubmitting]);

  const scrollUpside = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  return (
    loader ? (
      <Loader/>
    ): (
    <Box noValidate component="form" onSubmit={handleSubmit(submitSecondForm)}>
      <Grid item xs={12} md={10} container spacing={4} justifyContent="center">
        <Grid item xs={11} md={10}>
          <Typography variant="h5" my={3} color="primary.darker">
            Fintech Partner Onboarding Form : Step 2
          </Typography>
          <Typography variant="h5" gutterBottom>
            Service description *
          </Typography>
          <HtmlEdior disable={disable} MAX_LENGTH={1000}
          defaultValue={formData?.serviceDescription || partnerData?.overview?.serviceDescription} placeholder={"Elaborate the service you are providing and the solutions relevant to it"} 
          onChnage={(val) => handleServiceDescription(val)} />
        {
          serviceHtmlStringErr && (
              <Typography color="error">
              {serviceHtmlStringErr}
              </Typography>
        )}
        </Grid>

        <Grid item xs={11} md={10}>
          <Typography variant="h5" gutterBottom>
            Features & benefits *
          </Typography>
          <HtmlEdior disable={disable} MAX_LENGTH={1000} placeholder={pholder}
          defaultValue={formData?.serviceKeyPoint || partnerData?.features} onChnage={(val) => handleFeature(val)} />
        {
          featureHtmlStringErr && (
              <Typography color="error">
              {featureHtmlStringErr}
              </Typography>
        )}
        </Grid>
        
        <Grid item xs={11} md={10}>
          <Typography mt={3} variant="h5">
            {" "}
            Partner Video Link (Optional)
          </Typography>

          <TextField
            errors={errors}
            control={control}
            name="partnerVideoLLink"
            variant="standard"
            value={partnerVideoUrl}
            fullWidth
            inputProps={{ maxLength: 5000 }}
            onChange={handlePartnerVideoLink}
          />
        </Grid>
        <Grid item xs={11} md={10}>
          <Typography mt={3} variant="h5">
            Upload Application screenshots *
          </Typography>

          <Typography color="#555" variant="body2" align="right">
            Maximum limit to upload screenshot is 5
          </Typography>
        </Grid>

        {formData?.screenshots && formData?.screenshots.length && formData.screenshots.map((screen, index) => {
          return (
            <Grid container item xs={10} spacing={3} key={index}>
              <Grid item xs={12}>
                <FileUpload
                  disable={disable}
                  imageType={2}
                  uploadedfile={
                    screen
                      ? [
                          {
                            preview: screen.uri,
                            name: screen.title,
                          },
                        ]
                      : []
                  }
                  error={apiValError}
                  leadId={userData?.sub?.leadId || partnerLeadID}
                  onFileUploaded={(file) => handleFileUpload(file, index)}
                />
                {/* <ErrorMessage message={errors[name].message} />apiValError */}
              </Grid>
              <Grid item xs={12}>
              <TextFieldAuto
                  disable={disable}
                  errors={errors}
                  control={control}
                  defaultValue={screen.title}
                  name={"imageTitle_"+index}
                  label="Title of the image *"
                  variant="standard"
                  rows={4}
                  fullWidth
                  onChange={(e) => handleTitle(e, index)}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xs={12}>
              <Grid item xs={12}>
                <HtmlEdior disable={disable} MAX_LENGTH={350} defaultValue={screen.description} placeholder={"A short description about image and functionality of the image in layman terms"}
                onChnage={(val) => setScreenShots(handleDescription(val, index))} />
              </Grid>
              </Grid>
              {index > 0 && (
                <Grid item xs={12} align="right">
                  <Button
                    startIcon={<DeleteOutlinedIcon />}
                    onClick={(e) => delteItems(index)}
                  >
                    Remove
                  </Button>
                </Grid>
              )}
            </Grid>
          );
        })}   

        {!formData?.screenshots && partnerData?.overview?.screenshots.map((screen, index) => {
          return (
            <Grid container item xs={10} spacing={3} key={index}>
              <Grid item xs={12}>
                <FileUpload
                  disable={disable}
                  imageType={2}
                  uploadedfile={
                    screen
                      ? [
                          {
                            preview: screen.uri,
                            name: screen.title,
                          },
                        ]
                      : []
                  }
                  error={apiValError}
                  leadId={userData?.sub?.leadId || partnerLeadID}
                  onFileUploaded={(file) => handleFileUpload(file, index)}
                />
                {/* <ErrorMessage message={errors[name].message} />apiValError */}
              </Grid>
              <Grid item xs={12}>
              <TextFieldAuto
                  disable={disable}
                  errors={errors}
                  control={control}
                  defaultValue={screen.title}
                  name={"imageTitle_"+index}
                  label="Title of the image"
                  variant="standard"
                  rows={4}
                  fullWidth
                  onChange={(e) => handleTitle(e, index)}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xs={12}>
              <Grid item xs={12}>
                <HtmlEdior MAX_LENGTH={350} disable={disable} defaultValue={screen.description} placeholder={"A short description about image and functionality of the image in layman terms"}
                onChnage={(val) => setScreenShots(handleDescription(val, index))} />
              </Grid>
              </Grid>
              {index > 0 && (
                <Grid item xs={12} align="right">
                  <Button
                    disabled={disable}
                    startIcon={<DeleteOutlinedIcon />}
                    onClick={(e) => delteItems(index)}
                  >
                    Remove
                  </Button>
                </Grid>
              )}
            </Grid>
          );
        })}      

        { screenShots && screenShots?.length < 5 && 
          <Grid item xs={10} align="right">
            <Button disabled={disable} startIcon={<AddCircleOutlineIcon />} onClick={addItems}>
              Add new image 
            </Button>
          </Grid>
        }
          { apiValError && (
              <Grid item xs={10} align="left">
               <Typography color="error">
              {apiValError}
            </Typography>
            </Grid>
          //   <Box>
          //   <Typography color="error">
          //     {apiValError}
          //   </Typography>
          // </Box>
          )}

        <Grid item xs={11} md={10} container spacing={3} justifyContent="space-between">
          <Grid item lg={3} pb={3}>
            {/* <Button
              variant="outlined"
              component={Link}
              to={`/step-one`}
              color="primary"
              fullWidth
            >
              Previous
            </Button> */}
            <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={storOnPrev}
                >
                  Previous
            </Button>
          </Grid>

          <Grid item lg={5} pb={3}>
            <Grid container spacing={3}>
              <Grid item lg={6}>
                <Button
                  disabled={disable}
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={storeInStorage}
                >
                  Preview
                </Button>
              </Grid>
              <Grid item lg={6}>
                <Button
                  disabled={disable}
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
    )
  );
};

export default FromStepTwo;