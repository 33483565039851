import { Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const CustomBreadcrumbs = ({ currentLink, previousLink, prevlink, isHomeTrue }) => {
  const { isLoggedIn, userData } = useSelector((state) => state.auth);
  const url = userData && userData?.sub?.userTypeId === 3 ? "/admin/partner-listings" : '/';
 
  return (
    <Box aria-label="breadcrumb" py={2}>
      <Typography variant="caption" color="text.secondary" sx={{display:isHomeTrue ? 'none':''}}>
        <Link to={url} className="text-decoration">
          Home <Box component="span" px={1}> / </Box>
        </Link>
      </Typography>
      {previousLink && 
      <Typography variant="caption" color="text.primary" pl={0.3}>
       <Link to={prevlink} className="text-decoration">
        {previousLink || ""} <Box component="span" px={1}> / </Box>
        </Link>
      </Typography>
      }
      <Typography variant="caption" color="text.primary" pl={0.3}>
        {currentLink}
      </Typography>
    </Box>
  );
};
export default CustomBreadcrumbs;
