import { useEffect, useState } from "react";

import {
  Container,
  Grid,
  Typography,
  Button,
  TextField,
  Box,
  Alert,
} from "@mui/material";
import { useForm } from "react-hook-form";

import { registerUser } from "redux/actions/auth/authAction";
import { resetData } from "redux/reducers/auth/authSlice";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "Components/Common/Loader";
import AlertDialog from "Components/Common/AlertDialog";
import axios from "axios";
import config from "config";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { passwordRegExp } from "utils";
import TextFields from "Components/Common/TextFields";
import SelectFields from "Components/Common/SelectFields";
import {logout} from "../../redux/actions/auth/authAction"
import PageNotFound from "View/PageNotFound";
import { resetOnboarding } from "redux/reducers/onboarding/onboardingSlice";
import { resetPartnerListing }  from "redux/reducers/admin/partnerListingsSlice"

const passMessage = "Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special";
// create schema validation
const schema = yup.object({
  password: yup
    .string()
    .min(
      8,
      passMessage
    )
    .required("Password is required")
    .matches(/[0-9]/, passMessage)
    .matches(/[a-z]/, passMessage)
    .matches(/[A-Z]/, passMessage)
    .matches(/[^\w]/, passMessage),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must match"),
  questionId: yup.string().required("Security Question is required"),
  answer: yup.string().required("Security Answer is required").min(1),
});

const Signup = () => {
  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
      questionId: "",
      answer: "",
    },
    resolver: yupResolver(schema),
  });

  let params = useParams();
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const { userData } = useSelector((state) => state.auth)
  const [popupMsg, setPopupMsg] = useState("");
  const [secQues, setSecQues] = useState("");
  const [variryToken, setVariryToken] = useState(false);
  const [signupOpen, setSignupOpen] = useState(false);
  const [apiError, setApiError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  // const { isSignup, signupData } = useSelector((state) => state.auth);

 
  // useEffect(() => {
  //   if (signupData && signupData.error) {
  //     setApiError(signupData.message);
  //    } 
  //   setLoading(false);
  // }, [signupData]);

  const securityQues = async () => {
    try {
      await axios
        .get(`${config.apiBase}/profile/getAllSecurityQuestions`)
        .then((res) => {
          setSecQues(res.data?.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const verifySignUpToken = async () => {
    try {
      setIsError(false);
      setLoading(true);
      await axios
        .post(`${config.apiBase}/profile/verifyRegistrationToken`, {
          registrationToken: params.slugURL,
        })
        .then((res) => { 
          if(userData && userData?.sub?.userTypeId) {
            dispatch(logout());
            dispatch(resetOnboarding());
            dispatch(resetPartnerListing());
          }
          if (res?.data?.data[0]?.status === "Login") {
            navigate("/login");
          }
          if (res && res?.data?.data[0]?.status === "SignUp") {
            setVariryToken(res?.data?.data);
          }

          if (!res?.data?.error) {
            securityQues();
          }
          setPopupMsg(res?.data);
        });
    } catch (error) {
      setIsError(true);
      setPopupMsg(error);
      if (error.response?.data?.data[0]?.status === "Login") {
        if(userData && userData?.sub?.userTypeId) {
          dispatch(logout());
          dispatch(resetOnboarding());
          dispatch(resetPartnerListing());
        }
        navigate("/login");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    dispatch(resetData());
    verifySignUpToken();
  }, []);

  const onSubmit = (data) => {
    let leadId = variryToken?.[0]?.leadId;
    setApiError("");
    const allData = {
      leadId,
      ...data,
    };
    dispatch(registerUser(allData)).then(res => !res.payload.error ? setSignupOpen(true) : setApiError(res.payload.message) );
   // setSignupOpen(true)
    dispatch(resetData());
    reset()
  };

  return (
    <>
      
     { loading ? (
      <Loader />) : ( 
      <Box noValidate component="form" onSubmit={handleSubmit(onSubmit)}>
        {variryToken ? (
          <Container maxWidth="lg">
            <Grid container spacing={2} alignItems="center" py={4}>
              <Grid item xs={12} md={12}>
                <Typography variant="h3" component="h1" color="primary.darker">
                  Sign up
                </Typography>
              </Grid>
              {apiError && (
                <Grid item xs={12} md={8}>
                  <Alert variant="filled" severity="error">
                    {apiError}
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12} md={6} lg={6}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={8} md={10} >
                    <TextField
                      tabIndex="0" 
                      label="Fullname"
                      value={variryToken?.[0]?.fullname}
                      variant="standard"
                      fullWidth
                      InputProps={{ disabled: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={10}>
                    <TextField
                      tabIndex="1"
                      label="Email"
                      value={variryToken?.[0]?.email}
                      variant="standard"
                      fullWidth
                      InputProps={{ disabled: true }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={8} md={10}>
                    <TextFields
                      tabIndex="2"
                      type="password"
                      label="Password *"
                      name="password"
                      errors={errors}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={10}>
                    <TextFields
                      tabIndex="3"
                      type="password"
                      label="Confirm password *"
                      name="confirmPassword"
                      errors={errors}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={10}>
                    <SelectFields
                      tabIndex="4"
                      errors={errors}
                      control={control}
                      name="questionId"
                      label="Security Question *"
                      list={secQues}
                      keyName="question"
                    />
                  </Grid>

                  <Grid item xs={12} sm={8} md={10}>
                    <TextFields
                      tabIndex="5"
                      label="Security Question answer *"
                      name="answer"
                      errors={errors}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={10}>
                    <Grid container spacing={2} justifyContent="flex-end">
                      <Grid item xs={5} md={4}>
                        <Button tabIndex="6" variant="contained" type="submit" fullWidth>
                          Sign up
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        ) : (
          popupMsg?.error && <PageNotFound />
        )}

       
        {signupOpen && (
          <AlertDialog
            message="Your profile is created successfully."
            open={signupOpen}
            showCloseIcon={false}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          </AlertDialog>
        )}
      </Box>
      )}
    </>
  );
};
export default Signup;
