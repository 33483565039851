import { useState, useEffect } from "react";
import axios from "axios";
import { Grid, Typography, Box, Button } from "@mui/material";
import FileUpload from "Components/FileUpload";
import MultipleSelectCheckmarks from "Components/Common/MultipleSelectCheckmarks";
import TextFields from "Components/Common/TextFields";
import TextFieldAuto from "Components/Common/TextFieldAuto";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import config from "config";
import { useDispatch, useSelector } from "react-redux";
import { storeLogoUrl } from "redux/reducers/partners/partnersSlice";
import {
  stepOneSubmit,
  getPartner,
} from "redux/actions/onboarding/onboardingAction";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Loader from "Components/Common/Loader";
import _ from "lodash";
import { webRegExp} from "utils";
import jwtDecode from 'jwt-decode';


const validationSchema = yup.object().shape({
  companyName: yup.string().required("Company name is required"),
  productName: yup.string().required("Product name is required"),
  website: yup.string().nullable().notRequired()
    .test(
      'validWebsite',
      'Website is not valid.',
      (value)=> {
        return !value ? true : webRegExp.test(value)
      }
    ),
  briefDescription: yup.string().required("Brief description is required"),

  apisAvailable: yup.array().min(1, "Select at least one APIs available"),
  connectivityChannels: yup
    .array()
    .min(1, "Select at least one Connectivity channels"),
  industryserviced: yup.array().min(1, "Select at least one Industry serviced"),
  businessServiced: yup.array().min(1, "Select at least one Business serviced"),
  appCategories: yup.array().min(1, "Select at least one Application categories"),
  primaryAppCategory: yup
    .array()
    .min(1, "Select at least one Primary application category"),
  erpsSupported: yup.array().min(1, "Select at least one ERPs supported"),
});

const formOptions = {
  defaultValues: {
    companyName: "",
    productName: "",
    website: "",
    briefDescription: "",
    apisAvailable: [],
    connectivityChannels: [],
    industryserviced: [],
    businessServiced: [],
    appCategories: [],
    primaryAppCategory: [],
    erpsSupported: [],
    leadId: null,
  },
  resolver: yupResolver(validationSchema),
};

const FromStepOne = ({ formData, priCategories, apisAvailableList, appCatList, businessSizeList, connectivityList, erpSupportedList, industryTypeList, userLeadId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const partnerListingFetch = useSelector(
    (state) => state.partnerListing?.data?.lstAdmGetAllParnerResponses
  );
  const localData = localStorage.getItem("userToken");
  const tokenDecode = jwtDecode(localData)
  const partnerLeadIdLoc = tokenDecode?.sub?.leadId
  const partnerLeadIDSess = JSON.parse(sessionStorage.getItem('leadId'));
  const partnerName = sessionStorage.getItem('partnerName')
  const partnerLeadID = partnerListingFetch?.filter((partner)=> partner.partnername == params.leadId)[0]?.id || partnerLeadIdLoc || partnerLeadIDSess
  const partner = partnerListingFetch?.filter((partner)=> partner.partnername == params.leadId)[0]
  let dispatch = useDispatch();
  if((partner && partner?.partnername != params.leadId) || partnerName != params.leadId ){
    sessionStorage.removeItem("formData")
  }
  if(!partner && tokenDecode?.sub?.userTypeId == 3){
    navigate('/admin/partner-listings')
  }
  const { userData, isLoggedIn } = useSelector((state) => state.auth);
  const [primaryCategories, setPrimaryCategories] = useState([]);
  const { loading, stepOne, isSubmitted, error, partnerData, partnerLoader } =
    useSelector((state) => state.onboarding); 
  const [leadId, setLeadId] = useState(null);
  const [pageLoader, setPageLoader] = useState(true);
  const [disable, setDisable] = useState(false);
  const [briefLength, setBriefLength] = useState(0);
  const [logoUploaded, setLogoUploaded] = useState(false);
  const [dData, setDData] = useState({
    companyName: "",
    productName: "",
    website: "",
    briefDescription: "",
    apisAvailable: [],
    connectivityChannels: [],
    industryserviced: [],
    businessServiced: [],
    appCategories: [],
    primaryAppCategory: [],
    erpsSupported: [],
    leadId: null,
  });
  const match = location.pathname.match('/admin');
  const [primaryCategory, setPrimaryCategory] = useState(priCategories);
  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    setValue,
    setError,
    clearErrors,
    getValues,
  } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const [appCategory, setAppCategories] = useState([]);
  const [apiValError, setValError] = useState(null);
  const [logoErr, setLogoErr] = useState('')
  const getImageUri = useSelector(
    (state) => state.partner?.stepOneLogoUrl || ""
  );
  const handleLogoUpload = (file) => {
    dispatch(storeLogoUrl(file));
    if(file) {
      setLogoUploaded(true); 
    }
    else {
      setLogoUploaded(false); 
    }
    setLogoErr('');
  };

  useEffect(() => {
    if(partnerData?.overview?.logoUri){
      setLogoUploaded(true)
    }
    if(partnerData && Object.keys(partnerData).length > 0) {
        const serviceDesc = partnerData.serviceDetails;
        setDData((values) => ({ ...values, ['apisAvailable']: serviceDesc.api.map((item) => item.name)//
        }));
        setDData((values) => ({ ...values, ['appCategories']: serviceDesc.application.map((item) => item.name)}));
        setDData((values) => ({ ...values, ['businessServiced']: serviceDesc.businessSize.map((item) => item.name)//
        }));
        setDData((values) => ({ ...values, ['connectivityChannels']: serviceDesc.connectivityChannel.map((item) => item.name)//
        }));
        setDData((values) => ({ ...values, ['erpsSupported']: serviceDesc.erpSupported.map((item) => item.name)//
        }));
        setDData((values) => ({ ...values, ['industryserviced']: serviceDesc.industry.map((item) => item.name) //
        }));
        for (const key in partnerData.overview) {
          setDData((values) => ({ ...values, [key]: partnerData.overview[key] }));
        } 

        setValue("companyName", partnerData.overview.companyName);
        setValue("productName", partnerData.overview.productName);
        setValue("briefDescription", partnerData.overview.shortDescription);
        setValue("website", partnerData.overview.website);

        setValue("apisAvailable", serviceDesc.api.map((item) => item.name));
        setValue("appCategories", serviceDesc.application.map((item) => item.name));
        setValue("businessServiced", serviceDesc.businessSize.map((item) => item.name));
        setValue("connectivityChannels", serviceDesc.connectivityChannel.map((item) => item.name));
        setValue("erpsSupported", serviceDesc.erpSupported.map((item) => item.name));
        setValue("industryserviced", serviceDesc.industry.map((item) => item.name));

        setAppCategories([partnerData?.overview?.primaryApplication]);

        setValue("primaryAppCategory", (partnerData?.overview?.primaryApplication ? [partnerData?.overview?.primaryApplication] : []));
        setBriefLength(partnerData?.overview?.shortDescription?.length || 0); 
        if(partnerData && partnerData?.currentStageId === 10 && userData?.sub?.userTypeId !== 3){
          setDisable(true)
        }
    }
    setTimeout(()=>{
      setPageLoader(false);
    },1000)
  }, [partnerData]);

  useEffect(() => {
    if (leadId && stepOne && !stepOne.error) {
      const pUrl = match ? `/admin/step-two${params?.leadId ? '/'+params?.leadId : ''}` : `/step-two${params?.leadId ? '/'+params?.leadId : ''}`
      navigate(pUrl);
    }
  }, [stepOne]);

  const getPAId = (primaryCat) => {
    if(primaryCat && typeof primaryCat === 'string') {
      const row = appCatList.filter((item) => primaryCat === item.name);
      return row[0].id
    }
    return primaryCat;
  }

  const postStepOne = async (data) => {
    sessionStorage.setItem("partnerName", params.leadId)
    setPageLoader(true);
    const altLogo = `logo_${data.companyName}`;
  console.log(partnerLeadID)

    const leadId = partnerLeadID;
    const requestPayload = {
      logoURI: (getImageUri ? getImageUri : (partnerData?.overview?.logoUri || "")),
      primaryAppId: getPAId(data.primaryAppCategory[0]),
      logoDescription: altLogo || "",
      companyName: data.companyName?.trim(),
      productName: data.productName?.trim(),
      shortDescription: data.briefDescription,
      website: data.website || "",
      industries: data.industryserviced,
      applications: data.appCategories,
      businessSizes: data.businessServiced,
      connectivityChannels: data.connectivityChannels,
      apis: data.apisAvailable,
      erpsSupported: data.erpsSupported,
      leadId: leadId,
    };
    setLeadId(leadId);
    if(disable) {
      // const pUrl = `/step-two${userLeadId ? '/'+userLeadId : ''}`
      const pUrl = match ? `/admin/step-two${userLeadId ? '/'+userLeadId : ''}` : `/step-two${userLeadId ? '/'+userLeadId : ''}`
      navigate(pUrl);
    }
    else {
      dispatch(stepOneSubmit(requestPayload)).then((res) => {
        setPageLoader(false);
        if (res.payload.error) {
          setValError(res.payload.message);
        }
        else {
          setValError('');
        }
      })
    }
  };

  const setMultiselectData = (field, value, name) => {
    setValue(field, value);
    if (value.length) {
      clearErrors(field);
    } else {
      setError(field, { type: "focus", message: `Select at least one ${name}` }, { shouldFocus: true });
    }
  };

  const setPrimaryAppCategory = (value, name) => {
    setValue("appCategories", value);
    const indexArr = [];
    value.forEach((item) => {
      indexArr.push(appCatList.find((lst) => lst.id == item));
    });
    setPrimaryCategory(indexArr);
    if(!value.length || (typeof getValues('primaryAppCategory')[0] === 'number' && !value.includes(getValues('primaryAppCategory')[0]))) {
      setValue('primaryAppCategory',[]);
      setError("primaryAppCategory", { type: "focus", message: `Select at least one Primary ${name}`}, { shouldFocus: true });
      setError("appCategories", { type: "focus", message: `Select at least one Application categories`}, { shouldFocus: true });
      setAppCategories([]);
    }
    else if(value.length && typeof getValues('primaryAppCategory')[0] === 'string' && !indexArr.find(x => x.name === getValues('primaryAppCategory')[0])) {
      setValue('primaryAppCategory',[]);
      setError("primaryAppCategory", { type: "focus", message: `Select at least one Primary ${name}`}, { shouldFocus: true });
      setAppCategories([]);
    }
    else {
      clearErrors("appCategories");
      setValue('primaryAppCategory',getValues('primaryAppCategory'));
    }
  };

  const handleDescription = (e) => {
    e.preventDefault();
    setValue('briefDescription',e.target.value);
    setBriefLength(e.target.value.length);
  }

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  },[isSubmitting]);

  const submitForm = (data) => {
    if (!logoUploaded) {
      // If logo is not uploaded, display error message and prevent form submission
      setLogoErr('Please upload a logo.');
      return;
    }
    postStepOne(data);
  };

  return (
    pageLoader ? (
      <Loader />
    ) : (
    <Box noValidate component="form" onSubmit={handleSubmit(submitForm)}>
      <Grid
        item
        xs={12}
        lg={10}
        container
        rowSpacing={4}
        justifyContent="center"
      >
        <Grid item xs={11} md={10}>
          <Typography variant="h5" mt={3} color="primary.darker">
            Fintech Partner onboarding form : 1
          </Typography>
        </Grid>

        <Grid item xs={11} md={10}>
          <Typography variant="h5" gutterBottom>
            Upload Logo *
          </Typography>

          <FileUpload
            disable={disable}
            name="partnerFile"
            imageType={1}
            uploadedfile={
              partnerData
                ? [
                    {
                      preview: partnerData.overview.logoUri,
                      name: partnerData.overview.companyName,
                    },
                  ]
                : []
            }
            //file={[]}
            leadId={userData?.sub?.leadId || userLeadId}
            // onFileUploaded={(file) => dispatch(storeLogoUrl(file))}
            onFileUploaded={handleLogoUpload} 
          />
          {
            logoErr && <p style={{color:'red'}}>Please upload Logo</p>
          }
        </Grid>

        <Grid item xs={11} md={10}>
          <Typography mt={3} variant="h5">
            {" "}
            Partner Information
          </Typography>

          <TextFields
            disable={disable}
            errors={errors}
            control={control}
            defaultValue={dData.companyName}
            label="Company name *"
            name="companyName"
            variant="standard"
            fullWidth
          />
        </Grid>

        <Grid item xs={11} md={10}>
          <TextFields
            disable={disable}
            errors={errors}
            control={control}
            defaultValue={dData.productName}
            label="Product name *"
            name="productName"
            variant="standard"
            fullWidth
            inputProps={{ maxLength: 30 }}
          />
        </Grid>
        <Grid item xs={11} md={10}>
          <TextFields
            disable={disable}
            errors={errors}
            control={control}
            defaultValue={dData.website}
            label="Website (optional)"
            name="website"
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={11} md={10}>
          {/* <TextFields
            disable={disable}
            errors={errors}
            control={control}
            defaultValue={dData.shortDescription}
            label="Brief description"
            name="briefDescription"
            multiline={true}
            variant="outlined"
            rows={4}
            fullWidth
            inputProps={{ maxLength: 50 }}
          /> */}
          <TextFieldAuto
                  disable={disable}
                  errors={errors}
                  control={control}
                  defaultValue={dData.shortDescription}
                  label="Company description *"
                  name="briefDescription"
                  multiline={true}
                  variant="outlined"
                  rows={4}
                  fullWidth
                  MAX_LIMIT={150}
                  onChange={(e) => handleDescription(e)}
                  inputProps={{ maxLength: 150 }}
                />
                {/* <Typography color="textSecondary" variant="body2" align="right">
                  {briefLength}/{150}
                </Typography> */}
        </Grid>
        <Grid item xs={11} md={10}>
          <MultipleSelectCheckmarks
            disable={disable}
            selectedList={dData.apisAvailable}
            control={control}
            name="apisAvailable"
            label="APIs available *"
            list={apisAvailableList}
            error={errors}
            onSelect={(vals) => setMultiselectData("apisAvailable", vals, "APIs available")}
          />
        </Grid>

        <Grid item xs={11} md={10}>
          <MultipleSelectCheckmarks
            disable={disable}
            selectedList={dData.connectivityChannels}
            label="Connectivity channels *"
            name="connectivityChannels"
            list={connectivityList}
            error={errors}
            control={control}
            onSelect={(vals) =>
              setMultiselectData("connectivityChannels", vals, "Connectivity channels")
            }
          />
        </Grid>

        <Grid item xs={11} md={10}>
          <MultipleSelectCheckmarks
            disable={disable}
            selectedList={dData.industryserviced}
            label="Industry serviced *"
            name="industryserviced"
            error={errors}
            control={control}
            list={industryTypeList}
            onSelect={(vals) => setMultiselectData("industryserviced", vals, "Industry serviced")}
          />
        </Grid>
        <Grid item xs={11} md={10}>
          <MultipleSelectCheckmarks
            disable={disable}
            selectedList={dData.businessServiced}
            label="Business size serviced *"
            name="businessServiced"
            error={errors}
            control={control}
            list={businessSizeList}
            onSelect={(vals) => setMultiselectData("businessServiced", vals, "Business size serviced")}
          />
        </Grid>
        <Grid item xs={11} md={10}>
          <MultipleSelectCheckmarks
            disable={disable}
            selectedList={dData.appCategories}
            label="Application categories *"
            name="appCategories"
            error={errors}
            control={control}
            list={appCatList}
            onSelect={(vals) => setPrimaryAppCategory(vals, "application category")}
          />
        </Grid>
        <Grid item xs={11} md={10}>
          <MultipleSelectCheckmarks
            disable={disable}
            selectedList={appCategory}
            label="Primary application category *"
            name="primaryAppCategory"
            error={errors}
            control={control}
            list={primaryCategory}
            //list={appCatList}
            multiple={false}
            onSelect={(vals) => setMultiselectData("primaryAppCategory", vals, "Primary application category")}
          />
          <Typography variant="caption" color="textSecondary">
            The selected application category will be displayed on the directory listing.
          </Typography>
        </Grid>

        <Grid item xs={11} md={10}>
          <MultipleSelectCheckmarks
            disable={disable}
            selectedList={dData.erpsSupported}
            label="ERPs supported *"
            name="erpsSupported"
            error={errors}
            control={control}
            list={erpSupportedList}
            onSelect={(vals) => setMultiselectData("erpsSupported", vals, "ERPs supported")}
          />
        </Grid>

        { apiValError && (
            <Box>
            <Typography color="error">
              {apiValError}
            </Typography>
          </Box>
          )}

        <Grid item xs={11} md={10} container justifyContent="flex-end">
          <Grid item lg={2} my={1} pb={3}>
            <Button variant="contained" color="primary" fullWidth type="submit">
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  ));
};

export default FromStepOne;
