import { Typography, Paper, Box, Container, Grid, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import topBanner from "../../Assets/Images/api-marketplace-hero.jpg";

const Banner = () => {
    const navigate = useNavigate();

    const adobeUtag = () => {
      const reportingData = {
        currentPage : "usb:partner directory:contact us",
        siteSection : "form",
        subSiteSection : "partner directory",
        sitePlatform : "dotcom",
        language: "en-us",
        datasource : "pageView",
        transactionStatus : "form start",
        formName : "usbank partner directory contact us form"
      };
        
      if(window.publisherFW){
        console.log("ContactUs ===>", reportingData);
        window.publisherFW.publishEvent("pageView", reportingData); //call this function after the data layer object
      }
    }

  return (
    <Box
      height={{ xs: "250px", sm: "440px", lg: "560px" }}
      sx={{
        background: `url(${topBanner}) no-repeat center center`,
        backgroundSize: "cover",
        objectFit: "cover",
        display: "flex",
       
      }}
     
      pt={{xs:"220px", sm:"80px" }}
      mb={{xs:"280px", sm:"0px", md:"10px"}}
    >
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} sm={8} md={6} lg={5}>
            <Paper elevation={0} component={Box} p={2} square>
              <Typography
                component="h2"
                mb={2}
                sx={{ fontWeight: "500", fontSize:'28px' }}
              >
                Our partners are your partners.
              </Typography>
              <Typography mb={2} color="#555">
                Streamline your embedded payment and cash management experiences
                with integrated solutions from trusted U.S. Bank Global Treasury
                Management partners.
              </Typography>

              <Button
                variant="contained"
                // component={Link}
                // to="/connect-with-us"
                onClick={() => {
                  adobeUtag();
                  navigate(`/connect-with-us`)
                }}
              >
                Contact us{" "}
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default Banner;
