import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Typography } from "@mui/material";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import axios from "axios";
import config from "config";
import { nanoid } from "nanoid";

const FileUpload = ({
  onFileUploaded,
  imageType,
  leadId,
  uploadedfile,
  name,
  disable = false,
}) => {
  const [files, setFiles] = useState([]);
  const [fileAgain, setFileAgain] = useState(false);
  const [apiError, setApiError] = useState("");
  const imgSize = imageType === 1 ? '1MB' : '10MB';
  const uploadImageFileData = async (file) => {
    if (file) {
      try {
        const uploadedResponse = await axios.post(
          `${config.apiBase}/upload`,
          {
            file: file || "",
            imageType: imageType, // logo
            leadId: leadId,
          },
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        console.log(uploadedResponse);
        if (uploadedResponse.data.error) {
          setApiError(uploadedResponse.data.message);
        } else {
          onFileUploaded(uploadedResponse.data.data[0].file, false);
          setApiError("");
        }
        // After upload get the url from uploadedResponse
      } catch (error) {
        onFileUploaded('');
        setApiError("File Upload Error");
        console.log("File Upload Error: ", error);
      }
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    setFileAgain(true);
    //console.log("file update: ==>", acceptedFiles);

    // file to be uploaded...
    const fileToUpload = acceptedFiles && acceptedFiles[0];
    if (fileToUpload) {
      uploadImageFileData(fileToUpload);
    }
    else {
      rejectionOfImg();
    }
  }, []);

  const { getRootProps, getInputProps, fileRejections, isDragActive } =
    useDropzone({
      onDrop,
      maxSize: imageType === 1 ? 1048576 : 10485760,
      accept: {
        "image/jpeg": [],
        "image/png": [],
      },
    });
    const rejectionOfImg = () => {
      onFileUploaded('');
    }
  
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <Box key={file.path}>
      {errors.map((e) => (
        e.code === 'file-too-large' ? (
          <Typography color="error" key={e.code}>
            File is larger than {imgSize}.
          </Typography>
        ): (        
        <Typography color="error" key={e.code}>
          {e.message}
        </Typography>
        )
      ))}
    </Box>
  ));

  const thumbs =
    files &&
    files?.map((file) => (
      <div key={nanoid}>
        <img src={file.preview} alt={file.name} height="100" width="" />
      </div>
    ));
  useEffect(() => {
    if (
      !fileAgain &&
      uploadedfile &&
      uploadedfile.length > 0 &&
      uploadedfile[0].preview
    ) {
      setFiles(uploadedfile);
    }
  }, [uploadedfile]);
  //clean up
  // useEffect(() => {
  //     files && files.forEach((file) => URL.revokeObjectURL(file.preview));
  //   },[files]
  // );

  return (
    <>
      {!disable && (
        <Box
          p={3}
          bgcolor="#F8F9FF"
          borderRadius="4px"
          textAlign="center"
          border="1.5px dashed rgba(56, 78, 183, 0.3)"
          {...getRootProps()}
        >
          <input name={name} {...getInputProps()} />
          <BackupOutlinedIcon color="primary" fontSize="large" />
          {isDragActive ? (
            <Typography align="center">Drop the files here ...</Typography>
          ) : (
            <>
              <Typography align="center" gutterBottom>
                Drag & drop files or Browse
              </Typography>
              {imageType === 1 ? (
                <>
                  <Typography
                    color="primary"
                    variant="body2"
                    gutterBottom
                    align="center"
                  >
                    Supported formats:{" "}
                    <Box color="#555" component="span">
                      JPEG (or JPG), PNG and file weight less than 1MB.{" "}
                    </Box>
                  </Typography>
                  <Typography color="primary" variant="body2" align="center">
                    Recommended size:{" "}
                    <Box color="#555" component="span">
                      {" "}
                      width 200px & height 80px with transparent logo.{" "}
                    </Box>
                  </Typography>
                </>
              ) : imageType === 2 ? (
                <>
                  <Typography
                    color="primary"
                    variant="body2"
                    gutterBottom
                    align="center"
                  >
                    Supported formats:{" "}
                    <Box color="#555" component="span">
                      JPEG (or JPG), PNG and file weight less than 10mb.{" "}
                    </Box>
                  </Typography>
                  <Typography color="primary" variant="body2" align="center">
                    Recommended size:{" "}
                    <Box color="#555" component="span">
                      {" "}
                      width 600px{" "} & max height 1080px 
                    </Box>
                  </Typography>
                </>
              ) : null}

              <Box>{fileRejectionItems}</Box>
              {apiError && (
                <Box>
                  <Typography color="error">{apiError}</Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      )}

      <Box
        // p={3}
        // bgcolor="#F8F9FF"
        // borderRadius="4px"
        // textAlign="center"
        // border="1.5px dashed rgba(56, 78, 183, 0.3)"
        display="flex"
        justifyContent="center"
      >
        {thumbs}
      </Box>
    </>
  );
};

export default FileUpload;
