import { Typography, Box, Grid } from "@mui/material";
import step1Img from "../../Assets/Images/step1img.png";
import step2Img from "../../Assets/Images/step2img.png";
import step3Img from "../../Assets/Images/step3img.png";

const EasySteps = () => {
  return (
    <Box bgcolor="#F7F7FA" p={4}>
      <Grid container justifyContent="flex-start" direction="column">
        <Grid item xs={12} sm={6} md={4}>
          <Box pb={4} mb={2}>
            <img src={step1Img} alt="step1" width="32" height="auto" />
            <Typography mt={2}>
              Complete your partnership request online.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box pb={4} mb={2}>
            <img src={step2Img} alt="step2" width="32" height="auto" />
            <Typography mt={2}>
              U.S. Bank representatives will review your request and reach out
              with further information.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <img src={step3Img} alt="step3" width="32" height="auto" />
          <Typography mt={2}>
            Approved partnerships will have the opportunity to be listed in the
            directory.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
export default EasySteps;
