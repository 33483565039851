import { createTheme, responsiveFontSizes } from "@mui/material/styles";
let theme = createTheme({

})
 theme = createTheme({
  palette: {
    contrastThreshold: 4.5,
    primary: {
      darker: "#0c2074",
      main: "#0A41C5",
      light: "#447fc1",
      lighter: "#67b2e8",
      lightest: "#c7dff4",
      contrastText: "#fff",
    },
    secondary: {
      darker: "#9b132d",
      main: "#de162b",
      light: "#e06b70",
      contrastText: "#fff",
    },
    info: {
      main: "#0A87F8",
      light: "#e06b70",
      contrastText: "#fff",
    },

    warning: {
      main: "#E55A00",
      light: "#e06b70",
      contrastText: "#fff",
    },
    
    link: {
      main: "#0A41C5",
      '&:hover':{
        textDecoration:'none'
      }
   },
  },
  typography: {
    fontFamily:['"HelveticaNeue-USB"', 
   '"Helvetica Neue"','"Helvetica"',
    'Arial',
    'sans-serif',
    '-apple-system',].join(','),
    h1:{
      fontSize: 48,
      fontWeight: 700,
      marginBottom: 24
    },
    h2:{
      fontSize: 40,
      fontWeight: 700,
      marginBottom: 20
    },
    h3:{
      fontSize: 32,
      fontWeight: 700,
      marginBottom: 16
    },
    h4:{
      fontSize: 28,
      fontWeight: 500,
      marginBottom: 16
    },
    h5:{
      fontSize: 24,
      // [theme.breakpoints.down("md")]:{
      //   fontSize: 20,
      // },
      fontWeight: 500,
      marginBottom: 12
    },
    h6:{
      fontSize: 20,
      fontWeight: 500,
      marginBottom: 12
    },
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontWeight: 400,
    },
    
    button: {
      textTransform: "none",
      fontWeight: 600,
      
    },
  
   
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          minHeight: "auto",
          boxShadow: "none",
          transition: "none",
          borderRadius: "4px",
          fontSize:16,
          padding:'8px 20px',
          "& svg": {
            fontSize: 16,
            marginLeft: -2,
          },
        },
        label: {
          textTransform: "none",
          fontWeight: 600,
        },
        containedPrimary: {
          color: "#fff",
          backgroundColor: "#0A41C5",
          outline: "transparent solid 0.125rem",
          outlineOffset: "0.25rem",
          "&:hover": {
            backgroundColor: "#0C2074",
            backgroundImage: "linear-gradient(-180deg,#0A41C5,#0C2074 90%)",
            boxShadow: "none",
            outline: "transparent solid 0.125rem",
            outlineOffset: "0.25rem",
          },
          "&:active": {
            backgroundColor: "#0C2074",
            boxShadow: "none",
            backgroundImage: "linear-gradient(-180deg,#0C2074,#0C2074 90%)",
            outlineOffset: "0.25rem",
            color: " rgba(255, 255, 255, 0.78)",
            outline: "#0a41cf solid .125rem",
          },
          "&:focus": {
            backgroundColor: "#0A41C5",
            color: "#fff",
            outlineOffset: "0.25rem",
            outline: "#0a41cf solid .125rem",
          },
        },

        outlinedPrimary: {
          outline: "transparent solid 0.125rem",
          outlineOffset: "0.25rem",
          "&:hover": {
            backgroundColor: "#0C2074",
            color:'#fff',
            backgroundImage: "linear-gradient(-180deg,#0A41C5,#0C2074 90%)",
            boxShadow: "none",
            outline: "transparent solid 0.125rem",
            outlineOffset: "0.25rem",
          },
          "&:active": {
            background: "#0C2074",
            boxShadow: "none",
            backgroundImage: "linear-gradient(-180deg,#0C2074,#0C2074 90%)",
            outlineOffset: "0.25rem",
            color: " rgba(255, 255, 255, 0.78)",
            outline: "#0a41cf solid .125rem",
          },
          "&:focus": {
            background: "#0A41C5",
            color: "#fff",
            outlineOffset: "0.25rem",
            outline: "#0a41cf solid .125rem",
          },
        },
        textPrimary: {
          "&:hover": {
            background: "none",
            textDecoration:'underline'
          },
        },
        
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          minHeight: "auto",
          boxShadow: "none",
          transition: "none",
          backgroundColor:'#EFF3FC',
          "& svg": {
            fontSize: 16,
            marginLeft: -2,
          },
        },
        label: {
          textTransform: "none",
          fontWeight: 600,
        },
   

        outlinedPrimary: {
          outline: "transparent solid 0.1rem",
          outlineOffset: "0.25rem",
          "&:hover": {
            backgroundColor: "#0C2074",
            color:'#fff',
            backgroundImage: "linear-gradient(-180deg,#0A41C5,#0C2074 90%)",
            boxShadow: "none",
            outline: "transparent solid 0.125rem",
            outlineOffset: "0.25rem",
          },
          "&:active": {
            backgroundColor: "#0C2074",
            boxShadow: "none",
            backgroundImage: "linear-gradient(-180deg,#0C2074,#0C2074 90%)",
            outlineOffset: "0.25rem",
            color: " rgba(255, 255, 255, 0.78)",
          },
          "&:focus": {
            backgroundColor: "#EFF3FC",
            outlineOffset: "0.25rem",
            outline: "#0a41cf solid .1rem",
          },
        },
      },
    }
  },
});

theme = responsiveFontSizes(theme);

export default theme;
