import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/material/Icon';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth:'1170px',
  maxHeight:{xs:'70vh', md:'85vh', lg:'90vh'},
  overflowY:'auto',
  overflowX:'hidden',
  '&:focus-visible':{
    outline:'none',
   },
  width: '95%',
  bgcolor: '#fff',
  border: '1px solid #fff',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ImagePopup = ({img, status, openStatus}) => {
  const [open, setOpen] = React.useState(status);
 
  const handleClose = () => {
    setOpen(false);
    openStatus(false)
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ ...style }}>
        <IconButton style={{float: "right", position: "absolute", right: "1px", top: "-1px"}} aria-label="close" onClick={handleClose}>
          x
        </IconButton>
          {/* <h2 id="parent-modal-title">Text in a modal</h2> */}
          <img src={img} alt="screenshot" width="100%" height="auto" style={{maxWidth:'95%', height:'auto'}} />
        </Box>
      </Modal>
    </>
  );
}
export default ImagePopup;
