import { Box } from "@mui/material";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import Home from "View/Home";
import Details from "View/PartnerDetails";
import ConnectWithUs from "View/ConnectWithUs";
import PartnerWithUs from "View/PartnerWithUs";
import Login from "View/Login";
import ForgotPassword from "View/ForgotPassword";
import ChangePassword from "View/ChangePassword";
import Signup from "View/Signup";
import Footer from "Components/Footer";
import Header from "Components/Header";
import StepOne from "View/Onboard/StepOne";
import StepTwo from "View/Onboard/StepTwo";
import ScrollToTop from "Components/ScrollToTop";
import PageNotFound from "View/PageNotFound";
import AdminConsole from "View/AdminConsole";
import PartnerDetails from "View/AdminConsole/PartnerListings/PartnerDetails";
import ThankYou from "View/ThankYou";
import ClientDetails from "View/AdminConsole/ClientListing/Details";
import ClientListing from "View/AdminConsole/ClientListing";
import ProtectedRoute from "./routing/protectedRoute";
import { cleanStorage } from "./redux/reducers/auth/authSlice";
import jwtDecode from "jwt-decode";
import AdminLogin from "./View/AdminConsole/Login";
import AdminForgotPassword from "./View/AdminConsole/ForgotPassword";
import AdminResetPassword from "./View/AdminConsole/ResetPassword";
import AdminChangePassword from "./View/AdminConsole/ChangePassword";
import { fetchPartners } from "redux/reducers/partners/partnersSlice";

function App() {
  let dispatch = useDispatch();
  const { userData } = useSelector((state) => state.auth)
  const [auth, setAuth] = useState(false);
  const cookies = new Cookies(window.document.cookie);
  const token = cookies.get("@clientAccessToken");
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const match = location.pathname.match('/admin');
    // dispatch(fetchPartners())
    if (token) {
      const userDetail = jwtDecode(token);
      setAuth(userDetail.sub.userTypeId);
      if(userDetail.sub.userTypeId == 3 && (location.pathname === '/' || !match)) {
        navigate('/admin/partner-listings');
      }
      
      if(userDetail.sub.userTypeId != 3 && match){
        navigate('/');
      }

    } else {
      dispatch(cleanStorage());
      setAuth(false);
      if(match) {
        navigate('/admin/login');
      }
    }
  }, [userData]);

  // Function to determine if the footer should be hidden
  const shouldHideFooter = location.pathname.includes('admin/details') || /\/details\//.test(location.pathname);

  return (
    <>
      <ScrollToTop />
      <Header />
      <Box minHeight="calc(100vh - 100px)">
        <Routes>
          <Route index element={<Home />} />
          <Route path="details/:detailsId" element={<Details />} />
          <Route path="connect-with-us" element={<ConnectWithUs />} />
          <Route path="connect-with-us/:pId" element={<ConnectWithUs />} />
          <Route path="partner-with-us" element={<PartnerWithUs />} />
          {/* <Route path="partner-listings" element={<PartnerListings />} /> */}

          {/* <Route
            exact
            path="login"
            element={cookies.get("@clientAccessToken") ? <Navigate replace to={"/step-one"} /> : <Login />}
          /> */}
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="forgot-password/:id" element={<ChangePassword />} />
          <Route path="partner-register/:slugURL" element={<Signup />} />
          <Route path="thank-you" element={<ThankYou />} />
          <Route path="admin/login" element={<AdminLogin />} />
          <Route path="admin/forgot-password" element={<AdminForgotPassword />}/>
          <Route path="admin/change-password/:id" element={<AdminChangePassword />}/>
          <Route path="admin/step-one/:leadId" element={<StepOne />} />
          <Route path="admin/step-two/:leadId" element={<StepTwo />} />
          <Route path="admin/details/:detailsId" element={<Details />} />
          <Route element={<ProtectedRoute isAdmin={auth} />}>
            <Route path="step-one" element={<StepOne />} />
            <Route path="step-two" element={<StepTwo />} />
            <Route path="admin/reset-password" element={<AdminResetPassword />}/>
            <Route path="step-one/:leadId" element={<StepOne />} />
            <Route path="step-two/:leadId" element={<StepTwo />} />
            <Route path="admin/partner-listings" element={<AdminConsole />} />
            <Route path="admin/partner-listings/partner-details/:viewId" element={<PartnerDetails />} />
            <Route path="admin/leads" element={<ClientListing />} />
            <Route path="admin/leads/lead-details/:viewId" element={<ClientDetails />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Box>
      {/* Conditionally rendering the Footer based on the current route */}
      {!shouldHideFooter && <Footer />}
    </>
  );
}

export default App;
