import axios from "axios";
import config from "config";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAccessToken } from "helper/user";

export const getHomeFilter = createAsyncThunk(
  "home/getDashboardFilter",
  async (_, { rejectWithValue }) => {
    try {
      //const accessToken = await getAccessToken();
      const header = {
        headers: {
          "Content-Type": "application/json",
          //Authorization: `Bearer ${accessToken}`,
          pragma: "no-cache",
        },
      };
      const response = await axios.get(
        `${config.apiBase}/filter/getalldashbordfilters`, header
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//Dashboard Filter Get
export const getManageFilter = createAsyncThunk(
  "admin/getMngFilter",
  async (_, { rejectWithValue }) => {
    try {
      
      const accessToken = await getAccessToken();
      const response = await axios.get(
        `${config.apiBase}/filter/getallfilters`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            pragma: "no-cache",
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//Dashboard Add name to list Filter
export const addManageFilter = createAsyncThunk(
  "admin/addMngFilter",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const accessToken = await getAccessToken();
      const response = await axios.post(
        `${config.apiBase}/filter/add-filter`,
        {
          name: payload.addName,
          filterTypeId: payload.filterTypeId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            pragma: "no-cache",
          },
        }
      );

      //dispatch(getManageFilter());
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//Dashboard Filter Edit
export const editManageFilter = createAsyncThunk(
  "admin/editMngFilter",
  async ({ id, name, filterTypeId }, { rejectWithValue, dispatch }) => {
   try {
    const accessToken = await getAccessToken();
      const response = await axios.post(
        `${config.apiBase}/filter/edit-filter`,
        {
          id,
          name,
          filterTypeId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            pragma: "no-cache",
          },
        }
      );
      //dispatch(getManageFilter());
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//Dashboard Filter Delete
export const deleteManageFilter = createAsyncThunk(
  "admin/deleteMngFilter",
  async ({ id, filterTypeId }, { rejectWithValue, dispatch }) => {
    try {
      const accessToken = await getAccessToken();
      const response = await axios.post(
        `${config.apiBase}/filter/deleteFilter`,
        {
          id,
          filterTypeId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            pragma: "no-cache",
          },
        }
      );
      //dispatch(getManageFilter());
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//is isEdit Filter InUse
export const checkIsManageFilterEditable = async ({ id, filterTypeId }) =>  {
    try {
      const accessToken = await getAccessToken();
      const response = await axios.post(
        `${config.apiBase}/filter/isEditFilterInUse`,
        { id, filterTypeId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            pragma: "no-cache",
          },
        }
      );
      // console.log(({isEditFilterInUse: response.data}))
      return response.data;
    } catch (error) {
      console.log({error})
    }
  };

