import { useEffect, useState } from "react";
import { Typography, TextField, Container, Grid, Box, Button, Alert } from "@mui/material";
import CustomBreadcrumbs from "Components/Common/CustomBreadcrumbs";
import Loader from "Components/Common/Loader";
import axios from "axios";
import config from "config";
import { useForm } from "react-hook-form";
import EasySteps from "Components/EasySteps";
import TextFields from "Components/Common/TextFields";
import CheckboxFields from "Components/CheckboxFields";
import CustomSelectFields from "Components/Common/CustomSelectFields";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { phoneRegExp, emailRegExp, webRegExp, alphabetExp, alphaNumericExp, alphabetsString } from "utils";


// create schema validation
const schema = yup.object({
  fullName: yup.string().required("Full Name is required")
    .matches(alphabetsString, 'Full name is not valid.'),
  email: yup
    .string()
    .required("Email address is required")
    .matches(emailRegExp, "Email address is not valid")
    .min(3, "must be at least 3 characters long"),
  companyName: yup.string().required("Company name is required"),
    // .matches(alphabetExp, 'Company name is not valid.'),

  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Phone number must be at least 10 characters")
    .max(20, "Phone number must be at least 10 characters"),
  website: yup.string().nullable().notRequired()
    .test(
      'validWebsite',
      'Website is not valid.',
      (value) => {
        return !value ? true : webRegExp.test(value)
      }
    ),
  companyRole: yup.string().nullable().notRequired(),
  headquarter: yup.string().nullable().notRequired(),
  Headquarterlocation: yup.string(),
  city: yup.string().nullable().notRequired().when("headquarter", (headquarter, schema) => {
    if(headquarter && headquarter[0])
      return schema.required("City is required")
    return schema
  }),
  message: yup.string()
    .matches(alphaNumericExp, 'Message is not valid.'),

  // password: yup.string().required('Password is required').matches(pawdRegExp, 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'),
  // confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Password must match'),
  privacy: yup.bool().oneOf([true], "Field must be checked"),
});

const PartnerWithUs = () => {
  const {
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
    control,
  } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
      companyName: "",
      phoneNumber: "",
      website: "",
      companyRole: "",
      Headquarterlocation: "",
      city: "",
      headquarter: "",
      message: "",
      privacy: false,
    },
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [listHeadquarter, setListHeadquarter] = useState([]);
  const [isHasValue, setIsHasValue] = useState(false);
  const [cities, setCities] = useState(null);
  const [companyRole, setCompanyRole] = useState([]);
  const [stateCityName, setStateCityName] =useState(null);
  const [stateName, setSelectedStateName] =useState(null);
  const headquarterList = listHeadquarter?.map((item) => item).sort();
  const companyRoleList = companyRole?.map((item) => item).sort();
  const listItemCities = cities?.map((item) => item).sort();

  // const stateListData = watch('headquarter', false);
  // console.log('stateListData', stateListData);

  const adobeUtag = (flag) => {
    const reportingData = {
      currentPage :  flag ? "usb:partner directory:partner with us:thank you" :"usb:partner directory:partner with us",
      siteSection : "form",
      subSiteSection : "partner directory",
      sitePlatform : "dotcom",
      language: "en-us",
      datasource : "pageView",
      transactionStatus : flag ? "form submit" :"form start",
      formName : "usbank partner directory partner with us form"
    };
    if(window.publisherFW){
      console.log(reportingData.currentPage +" ===>", reportingData);
      window.publisherFW.publishEvent("pageView", reportingData); //call this function after the data layer object
    }
  }

  useEffect(() => {
    adobeUtag(false);
    getCompanyRole();
    getListHeadquarter();

  }, []);
  useEffect(() => {
    const validateFullName = async () => {
      const fullNameValue = watch("fullName");
      if (fullNameValue && !alphabetsString.test(fullNameValue)) {
        setError("fullName", {
          type: "manual",
          message: "Full name is not valid. Only alphabetic characters are allowed.",
        });
      } else {
        clearErrors("fullName");
      }
    };
  
    const handleBlurFullName = () => {
      validateFullName();
    };
  
    // Listen for onBlur event on the fullName field
    setValue('fullName', watch('fullName'));
    return () => {
      watch('fullName', handleBlurFullName);
    };
  }, [watch, clearErrors, setError]);
  
  const handleCancel = () => {
    navigate("/");
  };

  const getListHeadquarter = async () => {
    try {
      await axios.get(`${config.apiBase}/dashboard/regions`).then((res) => {
        setListHeadquarter(res.data?.data);
      });
    } catch (error) {
      console.error("ERR:", error);
    }
  };

  const getLisCities = async (id) => {
    try {
      await axios.get(`${config.apiBase}/dashboard/cities/${id}`).then((res) => {
        setCities(res.data?.data);
      });
    } catch (error) {
      console.log("ERR:", error);
    }
  };

  const getCompanyRole = async () => {
    try {
      await axios
        .get(`${config.apiBase}/dashboard/company-roles`)
        .then((res) => {
          setCompanyRole(res.data?.data);
        });
    } catch (error) {
      console.error("ERR:", error);
    }
  };

  const createParntner = async (data) => {

    setLoading(true);
    try {
      await axios
        .post(`${config.apiBase}/dashboard/create-lead`, {
          fullName: data.fullName || "",
          email: data.email || "",
          message: data.message || "",
          phoneNumber: data.phoneNumber || "",
          organization: data.companyName || "",
          website: data.website || "",
          cityId: data.city || "",
          leadTypeId: 1, // 1 partner  & 2 Admin
          organizationRoleId: data.companyRole || "",
          headquarterLocationId: data.headquarter || ""
        })
        .then((res) => {
          if (res?.data.error) {
            setLoading(false);
            setMessage(res?.data.message);
          }
          else {
            setMessage('');
            setLoading(false);
            adobeUtag(true);
            navigate("/thank-you");
          }
        });
    } catch (error) {
      console.error("ERR:", error);
    }
  };

  if (Object.keys(errors).length > 0) {
    // Scroll to the top of the form if there are errors
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  const onSubmit = (data) => {
    createParntner(data);
    setValue('privacy', false);
   //dispatch(createLead(data));
    //reset();
  };

  const setStateDropDownData = (val) => {
    getLisCities(val);
    setValue('headquarter',val);
    setValue('city','');
    const name = listHeadquarter.filter(item => item.id === val)[0].name;
    setSelectedStateName(name);
    val == '' ? setIsHasValue(false) : setIsHasValue(true);
  }

  const setCityDropDownData = (val) => {
    const name = cities.filter(item => item.id === val)[0].name;
    setValue('city',val);
    if (val) {
      clearErrors('city');
    } else {
      setError('city', { type: "focus", message: `City is required` }, { shouldFocus: true });
    }
    setStateCityName(name+ ', '+stateName);
    setValue('Headquarterlocation',name+ ', '+stateName);
  }

  return loading ? (
    <Loader />
  ) : (
    <Container maxWidth="lg">
      <CustomBreadcrumbs currentLink="Partner with us" />
      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={9} md={7} lg={6} mb={4}>
          <Typography variant="h3" component="h1" color="primary.darker" mb={2}>
            Partner with us
          </Typography>
          
          <Typography color="textPrimary" gutterBottom>
            Complete the form below to partner with us.
          </Typography>

          <Grid item xs={12} md={11}>
            {message && (
              <Grid item xs={12}>
                <Alert variant="filled" severity="error">{message}</Alert>
              </Grid>
            )}
            <Box noValidate component="form" mt={{ xs: 0, md: 3 }} onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                spacing={2}
                alignItems={{ xs: "normal", lg: "center" }}
              >
                <Grid item xs={12} mt={{ xs: 0, md: 3 }}>
                  <TextFields
                    errors={errors}
                    control={control}
                    name="fullName"
                    label="Full name *"
                    inputProps={{ maxLength: 60 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFields
                    errors={errors}
                    control={control}
                    name="email"
                    label="Email address *"
                    inputProps={{ maxLength: 60 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFields
                    errors={errors}
                    control={control}
                    name="companyName"
                    label="Company name *"
                    inputProps={{ maxLength: 60 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFields
                    errors={errors}
                    control={control}
                    name="phoneNumber"
                    label="Phone number *"
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFields
                    errors={errors}
                    control={control}
                    name="website"
                    label="Website (Optional)"
                    inputProps={{ maxLength: 40 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomSelectFields
                    errors={errors}
                    control={control}
                    name="companyRole"
                    label="Company role (Optional)"
                    list={companyRoleList}
                  />{" "}
                </Grid>

                <Grid item xs={12}>
                  <CustomSelectFields
                    errors={errors}
                    control={control}
                    name="headquarter"
                    label="State (Optional)"
                    list={headquarterList} 
                    onSelect={(vals) => setStateDropDownData(vals)}
                    />
                </Grid>
              {isHasValue && 
                <Grid item xs={12}>
                  <CustomSelectFields
                    errors={errors}
                    control={control}
                    name="city"
                    label="City *"
                    list={listItemCities}
                    onSelect={(val) => setCityDropDownData(val)}
                  />
                </Grid>
              }

                <Grid item xs={12} mb="24px">
                  <TextField
                    name="Headquarterlocation"
                    label="Headquarter location"
                    value={stateCityName || ""}
                    variant="standard"
                    fullWidth
                    inputProps={
                      {shrink: true }
                    }
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFields
                    errors={errors}
                    control={control}
                    name="message"
                    label="Message (Optional)"
                    multiline={true}
                    variant="outlined"
                    inputProps={{ maxLength: 500 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckboxFields
                    errors={errors}
                    control={control}
                    name="privacy"
                    label="By checking this box, you agree to receive marketing communications about U.S. Bank business products and services."
                  />
                </Grid>
              </Grid>
              <Box py={4} display="flex">
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item xs={5} sm={3} lg={4}>
                    <Button variant="outlined" fullWidth onClick={handleCancel}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={5} sm={3} lg={4}>
                    <Button variant="contained" type="submit" fullWidth>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>

            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12} md={7} lg={6} sx={{ display: { xs: 'none', lg: 'block' } }}>
          <Typography variant="h4" component="h2" mb={4}>
            Become a partner in 3 simple steps.
          </Typography>
          <EasySteps />
        </Grid>
      </Grid>

    </Container>
  );
};
export default PartnerWithUs;
